import React, { useEffect,useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Layout from "./layout";
import AppRoutes from "./Routes";
import i18n from "./app/i18n"
import { I18nextProvider } from 'react-i18next';
import store from "./app/store";

import { isIE } from 'react-device-detect';

let persistor = persistStore(store);
// const API_BASE_URL = process.env.REACT_APP_TRANSLATION;
const API_BASE_URL = "";

function App() {

// Disable all console.log statements mk
//console.log = function() {};

  const [jsonUpdated, setJsonUpdated] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API_BASE_URL}/tranlation_updation_api.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const getTraslationCurrentVersion = localStorage.getItem('currentTranslationVersion');

        if (getTraslationCurrentVersion !== data.current_version) {
          localStorage.clear();
          localStorage.setItem('currentTranslationVersion', data.current_version);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, [])



  if(isIE){
    return (
      <>
      <div> IE is not supported. Download Chrome/Opera/Firefox/Edge </div>
      </>
    )
  }
  else{

  return (
    <BrowserRouter >
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Layout>
              <AppRoutes />
            </Layout>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
  }
}

export default App;
