import React,{useEffect, useState} from 'react'
import { useSelector,useDispatch } from "react-redux";
import AxiosWithAuth from "../../../app/auth/jwtMiddleware";
import axios from "axios";
import { getLanguageFromLocalStorage } from "../../../app/utilities/localization"
import {
  getProfileData,
} from "../../../app/actions/Profile";
import { showPopup, hidePopup }   from "../../../app/actions/MainPopup";

import { showAlert } from "../../showAlerts";

const url = process.env.REACT_APP_PUBLIC_URL;

const EmailVerification = ({profileData}) => {

  const [loading,setLoading] = useState(false);
 const [showPopup,setShowPopup] = useState(true);
  const isPopupVisible = useSelector((state) => state.main_popup.isPopupVisible);
  // const [showPopup,setShowPopup] =  useState(isPopupVisible);
  console.log("EmailVerification components loading & showPopup : "+showPopup);
  const [verifyEmailWindow,setVrifyEmailWindow] = useState(true);
  const [updateEmailWindow,setupdateEmailWindow] = useState(false);
  const [email, setEmail] = useState(profileData?.main?.email);
 
  // console.log("profileData?.main?.email EmailVerification "+ profileData?.main?.email);
  // console.log("profileData?.main?.email EmailVerification showPopup "+showPopup);

  const dispatch = useDispatch();
    const language = getLanguageFromLocalStorage() || "en";

  function closePopups(e) {
    e.preventDefault();
    setShowPopup(false);
  }

  const  handleEmailScreen= (e) => {
    e.preventDefault();
   setupdateEmailWindow(true);
   setVrifyEmailWindow(false);
  };

  const  handleVerifyScreen= (e) => {
   e.preventDefault();
  setupdateEmailWindow(false);
  setVrifyEmailWindow(true);
 };

 const handleEmailUpdate = (e) => {
  e.preventDefault();
  setLoading(true);
  let data = {
    user_id: profileData?.main?.id,
    email: email,
  };
  try {
    AxiosWithAuth()
      .post(`${url}/account/update-email`, {...data, language})
      .then(({ data }) => {
        var formData = new FormData();
        formData.append("slug", profileData?.profile?.slug);
        formData.append("language", language);
        dispatch(getProfileData(formData));
        setLoading(false);
        setupdateEmailWindow(false);
        setVrifyEmailWindow(true);
      }).catch(error=>{
        showAlert(error?.response?.data?.data);
        setLoading(false);
      });
  } catch (error) {
    showAlert(error?.response?.data?.data);
    setLoading(false);
  }
 }

 function handleVerifyUsingEmail(e) {
  e.preventDefault();
  // console.log("resend click: "+profileData?.main?.email);
  // setEmail(profileData?.main?.email);
  sendEmailVerification();
}



function sendEmailVerification() {
  // $('.formwrap').removeClass('active');
  // $('#email-verify').addClass('active');

  var data = {
    email: email,
    language: language,
  };
console.log("Data:", JSON.stringify(data, null, 2));
  setLoading(true);
  try {
    axios
      .post(`${url}/users/create-email-verification`, data)
      .then((response) => {
        setLoading(false);
        // console.log(response);
      })
      .catch((error) => {
        setLoading(false);
         showAlert(error?.response?.data?.data)
        // console.log(error);
      });
  } catch (error) {
    setLoading(false);
    // console.log(error);
  }
} 

useEffect(() => {
  if(isPopupVisible){
    sendEmailVerification();
    console.log("Popup shown, preparing to hide.");
    setTimeout(() => {
      dispatch(hidePopup());
      console.log("Popup hidden after delay.");
    }, 1000);  // Delay to ensure previous actions complete
  }
}, [dispatch,isPopupVisible]); 

  return (
    <div className={`phone-number-verification-popup popup-main ${showPopup && 'active'}` }>
{loading && (<div className="loader"></div>) }
      <div className={`phone-number-verification-wrap` }>
      <div className='close-area'>
        <button className='close-button-main'
         onClick={(e) => closePopups(e)}
        >X</button>
      </div>
      <div className={`enter-email-windows verification-popup-window ${updateEmailWindow ? 'active' : 'inactive'}`}>
      <h3>Enter Your Email Address</h3>
      <input className="form-control  false" type="email" placeholder="Email ID" name="email" 
             value={email}
             onChange={(e) => setEmail(e.target.value)}
      ></input>
      <div className='buttons-area'>
          <div className='resend-button'>
            <button className="nav-bt float-start red-outline"
             onClick={(e) => handleVerifyScreen(e)}
            >Back</button>
          </div>
          <div className='verify-button'>
            <button 
             onClick={(e) => handleEmailUpdate(e)}
            className='nav-bt red float-end'>Save</button>
            </div>
        </div>
        </div>
        <div className={`verify-email-window verification-popup-window ${verifyEmailWindow ? 'active' : 'inactive'}`}>
      <h3>Verify Your Email Address</h3>
      <p>We have sent you a verification code via email on {profileData?.main?.email}.</p>
      <p>Open the email we have sent you and click on the link to complete the verification process.</p>

        <div className='its-not-my-email'>
            <p>If this is not your email, <span 
                onClick={(e) => handleEmailScreen(e)}
            className='click-button-popup'>click here</span> to update.</p>
            </div>
            <p className='note-area-popup'> Note: Please check your spam or junk mail folder as well. If you haven't received the email, click <span
     className='email-resend-button'
     onClick={(e)=>handleVerifyUsingEmail(e)}
     >Resend</span>.</p>
        </div>


    </div>
    </div>
  )
}

export default EmailVerification