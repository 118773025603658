import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux";
import "./settings.scss";
import { getSettings } from "../../app/actions/Settings";
import { Link } from "react-router-dom";
import {Trans, useTranslation } from 'react-i18next';
import { getLanguageFromLocalStorage,savePageTranslateLocalStorage,initializeI18n } from "../../app/utilities/localization"

import imagePlacholder from "../../assets/images/Main/user-placeholder.jpg";
import axiosWithAuth from "../../app/auth/jwtMiddleware";
import FormatedText from "./FormatedText";
import { showAlert } from "../../components/showAlerts";
import axios from "axios";
import {
  getProfileData,
} from "../../app/actions/Profile";


const url = process.env.REACT_APP_PUBLIC_URL;

export default function Settings() {
  
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const active_privacy = query.get('privacy')
  const [loading, setLoading] = useState(true);
  const [activePrivacyOnline, setActivePrivacyOnline] = useState(false);
  const [activePrivacyMain, setActivePrivacyMain] = useState(false);
  const [activePrivacyTerms, setActivePrivacyTerms] = useState(false); 

  useEffect(() => {

   if(active_privacy==='your-safety-online') {
    setActivePrivacyOnline(true);
    setActivePrivacyMain(false);
    setActivePrivacyTerms(false);
   } else if(active_privacy==='privacy_policy') {
    setActivePrivacyOnline(false);
    setActivePrivacyMain(true);
    setActivePrivacyTerms(false);
   } else if(active_privacy==='terms') {
    setActivePrivacyOnline(false);
    setActivePrivacyMain(false);
    setActivePrivacyTerms(true);
   } else {
    setActivePrivacyOnline(false);
    setActivePrivacyMain(false);
    setActivePrivacyTerms(false);
   }
  }, [active_privacy]);
  
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const currentLanguage = getLanguageFromLocalStorage() || "en";
  

  const { profileData } = useSelector((state) => state.profile);
  const { settingsData } = useSelector((state) => state.setting);
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState(profileData?.profile?.first_name);
  const [lastname, setLastName] = useState(profileData?.profile?.last_name);

  const [yourname, setYourname] = useState('')
  const [partner, setPartner] = useState('null')
  const [date, setDate] = useState('')
  const [textarea, setTextarea] = useState(null)
  
  const [othetext, setOtherText] = useState('')

  const email = profileData?.main?.email;
  const [language, setLanguage] = useState(
    settingsData?.preferences?.language?.value
  );
  const [summaryemails, setSummaryEmails] = useState(
    settingsData?.notifications?.summary_emails?.value
  );
  const [promotions, setPromotions] = useState(
    settingsData?.notifications?.promotions?.value
  );
  const [announcements, setAnnouncements] = useState(
    settingsData?.notifications?.announcements?.value
  );
  const [tips, setTips] = useState(settingsData?.notifications?.tips?.value);
  const [incognito, setIncognito] = useState(
    settingsData?.visibility?.incognito?.value
  );
  const [lastseen, setLastSeen] = useState(
    settingsData?.visibility?.last_seen?.value
  );
  const [read, setRead] = useState(settingsData?.visibility?.read_receipts?.value);
const [draws, setDraws]= useState(settingsData?.visibility?.remove_from_draws?.value);


  const handleImageError = (e) => {
    // Replace the failed image with a placeholder image
    e.target.src = imagePlacholder; // Replace with the path to your placeholder image
  };

  function handleName(e) {
    e.preventDefault();
    setName(e.target.value);
  }

  function handleLastName(e) {
    e.preventDefault();
    setLastName(e.target.value);
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 300);

    const fetchData = async () => {
      await savePageTranslateLocalStorage("premium");
      initializeI18n(['religion','language','education','countries','settings']);
    };
       
    fetchData();
 
    }, []);

  useEffect(() => {
    if (profileData?.main?.id) {
      var user = {
        user: profileData?.main?.id,
      };
      dispatch(getSettings(user));
    }
  }, []);

  //

  useEffect(() => {
    var menuItems = document.querySelectorAll("#settings-menu a");
    var nodes = Array.prototype.slice.call(
      document.getElementById("settings-menu").children
    );
    var containers = Array.prototype.slice.call(
      document.getElementById("main-contents").children
    );

    var div_array = [...nodes]; // converts NodeList to Array
    // console.log(div_array)

    div_array.forEach((li) => {
      li.children[0].addEventListener("click", function (e) {
        e.preventDefault();
        hideContents();
        deactivateEditMode()

        e.target.classList.add("active");
        document
          .querySelector("#main-content.full #settings-page .menu")
          .classList.remove("active");
        containers[nodes.indexOf(li)].classList.add("active");

        if (li.classList.contains("has-child")) {
          li.children[1].children[0].children[0].classList.add("active");
        }
      });
    });

    var childLinks = Array.prototype.slice.call(
      document.querySelectorAll("#settings-menu li ul li a")
    );

    var div_array = [...childLinks]; // converts NodeList to Array

    div_array.forEach((a) => {
      a.addEventListener("click", function (e) {
        e.preventDefault();
        hideContents();
        deactivateEditMode()
        e.target.closest(".has-child").children[0].click();

        var children = Array.prototype.slice.call(
          e.target.closest("ul").children
        );
        var childWraps = document.querySelectorAll(
          ".content-wrap.active .child-wrap"
        );

        childWraps.forEach((ch) => {
          ch.classList.remove("active");
        });

        childWraps[children.indexOf(e.target.parentElement)].classList.add(
          "active"
        );

        children.forEach((chi) => {
          chi.children[0].classList.remove("active");
        });

        e.target.classList.add("active");
      });
    });

    var filterLabels = document.querySelectorAll('.mobile-nav');
    var div_array = [...filterLabels]; // converts NodeList to Array


    div_array.forEach(div => {
        div.addEventListener("click", function(e){
            e.preventDefault();
            document.querySelector('#settings-page .menu').classList.add('active');
            hideContents();
        });
    });
  }, []);



  useEffect(() => {

    if (window.innerWidth < 780) {

      var menu = document.querySelector('#settings-page .menu')
      var nodes = Array.prototype.slice.call(
        document.getElementById("settings-menu").children
      );

      if(menu){
        menu.classList.add('active')
      }
      var wrappedContent = document.querySelectorAll("#main-contents .content-wrap")
      if(wrappedContent){
        wrappedContent.forEach(element => {
          // console.log(element)
          element.classList.remove('active')
        });
        // 
      }
      

        

      // console.log(window.innerHeight);
    } else {

    }
    // Function to update the state based on screen size
    const checkScreenSize = () => {

      if (window.innerWidth < 780) {


        // console.log(window.innerHeight);
      } else {
 
      }

    };

    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };

  
  }, []);



  //

  function hideContents() {
    var filterLabels = document.querySelectorAll(".content-wrap");
    var div_array = [...filterLabels]; // converts NodeList to Array
    div_array.forEach((div) => {
      div.classList.remove("active");
    });
    filterLabels = document.querySelectorAll(
      "#main-content.full #settings-menu a"
    );
    div_array = [...filterLabels]; // converts NodeList to Array
    div_array.forEach((div) => {
      div.classList.remove("active");
    });
  }

  function showCloseAccountPopup(e) {
    e.preventDefault();
    document.getElementById("account-closing-popup").classList.add("active");
    // document.getElementById("account-closing-popup").classList.add("active");
    document.body.classList.add('noscroll')
  }

  function showSuccessForm() {
    document.getElementById("shareSuccessForm").classList.add("active");
    document.getElementById("accountCloseSection").classList.remove("active");
  }

  function handlesuccessStoryForm(e) {
    e.preventDefault();
    var formData = new FormData();
    formData.append("user_id", profileData?.main?.id);



    if(yourname){
        formData.append("name", yourname);
    }    if(partner){
        formData.append("partner_name", partner);
    }    if(date){
        formData.append("wedding", date);
    }    if(textarea){
        formData.append("message", textarea);
    }
    if(selectedFile){
        formData.append("photo", selectedFile);
    }
    else{
        formData.append("photo", '');
    }

   
    try {
      axiosWithAuth()
        .post(`${url}/account/submit-success-story`, formData)
        .then((data) => {
          // console.log(data);
          showStoryShareSuccess();

          localStorage.removeItem("authtoken");
        })
        .catch((error) => {
        //   console.log(error);
        });
    } catch (e) {
    //   console.log(e);
    }
  }
  function handleCancle(e){
    setLoading(true);
    e.preventDefault();
    var data = {
      user_id: profileData?.main?.id
    };

    try {
      axiosWithAuth()
        .post(`${url}/trial-payments/cancel-trial`, data)
        .then((response) => {

          // setPaymentLoading(false);
          if (response?.data?.status === 200) {
            console.log(response.data);
              if(response?.data?.success)
              {
                showAlert('Your subscription has been canceled');
              }
              setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          // setPaymentLoading(false);
        });
    } catch (e) {
      setLoading(false);
      // setPaymentLoading(false);
    }
  }
  function handleAccountForm(e){
    e.preventDefault()
    var formData = new FormData(e.target)
    formData.append('user_id',profileData?.main?.id);
    if(othetext){
    formData.append('message',othetext);
    }
    // console.log()

    var  existingAlert = e.target.querySelector('.alert');

    if(existingAlert)
        existingAlert.remove()
    try {
        axiosWithAuth()
        .post(`${url}/account/close-account`,formData)
        .then(res=>{
            // console.log(res)
            localStorage.removeItem("authtoken");
            showCloseSuccess();
            showAlert('saved')
        })
        .catch(error=>{
          showAlert(error?.response?.data?.message)
            //  showErrorMessageInContainer(e.target,error?.response?.data?.message)
        })
        
    } catch (error) {
        
    }
  }


  function showStoryShareSuccess(){
    // document.getElementById('shareSuccess').classList.add('active');
    document.getElementById('shareSuccessForm').classList.remove('active');
    document.getElementById('confirmCloseAccount').classList.remove('active');
}

  function showCloseSuccess() {
    // document.getElementById("closeSuccess").classList.add("active");
    document.getElementById("shareSuccessForm").classList.remove("active");
    document.getElementById("confirmCloseAccount").classList.remove("active");
  }

  function closeModals(e) {
    e.preventDefault();

    document.getElementById("account-closing-popup").classList.remove("active");
    document.getElementById("accountCloseSection").classList.add("active");
    document.getElementById("shareSuccessForm").classList.remove("active");
    document.getElementById("confirmCloseAccount").classList.remove("active");
    document.body.classList.remove('noscroll')
  }

  //   const

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };


  const handleFixedCancel = (e, data) =>{
    e.preventDefault()
    setOtherText(data)
    document.getElementById("confirmCloseAccount").classList.add("active");
    document.getElementById("accountCloseSection").classList.remove("active");
  }
  //
  function showConfirmPassword(e) {
    e.preventDefault();
    // console.log("here");
    document.getElementById("confirmCloseAccount").classList.add("active");
    document.getElementById("accountCloseSection").classList.remove("active");
  }

  //
  function activateEditMode(e) {
    e.preventDefault();
    document.getElementById("main-contents").classList.add("editmode");
  }

  function deactivateEditMode() {
    document.getElementById("main-contents").classList.remove("editmode");
  }

  function handleProfileEdit(e) {
    e.preventDefault();
    var form = e.target;

    // console.log('lksdlksldskdl')

    if (form.querySelector(".alert")) {form.querySelector(".alert").remove();}

    // console.log('past')

    var formData = new FormData(form);
        // console.log()



    var serializedFormData = new URLSearchParams(formData).toString();
    serializedFormData += "&user_id=" + profileData?.main?.id;
    //  console.log(serializedFormData)
    try {
      axios
        .post(`${url}/account/update-details`, serializedFormData)
        .then((res) => {
          if (res?.status === 200) {
            // console.log(form);
            // form.innerHTML +=
            //   '<div className="alert alert-success mt-20">' +
            //   res?.data?.message +
            //   "</div>";
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);

            formData.append("language", language);

            dispatch(getProfileData(formData));
            showAlert(res?.data?.message)
            deactivateEditMode();

          }
        })
        .catch((error) => {
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert(error?.message);
          }
        })
    } catch (error) {
      showAlert(error?.message);
    }
  }

  function toggleSettingChange(e) {
    // FOR SELECT
    e.preventDefault();
    var slug = e.target.name; 
    var value = e.target.value;
     //console.log(slug);
    // console.log(value);
    
    updateSetting(slug, value);
    i18n.changeLanguage(value);
    setLanguage(value);
  }

  function toggleSettingClick(e) {
    e.preventDefault();
    var slug = e.target.value;
    var value = e.target.checked;
    // console.log(slug);
    // console.log(value);
    updateSetting(slug, value);
  }

  function updateSetting(target, value) {
    var data = {
      user: profileData?.main?.id,
      setting: target,
      value: value,
    };

    try {
      axiosWithAuth()
        .post(`${url}/account/update-setting`, data)
        .then((res) => {
        //   console.log(res);
          if (target === "summary-emails") {
            setSummaryEmails(!summaryemails);
          } else if (target === "promotions") {
            setPromotions(!promotions);
          } else if (target === "announcements") {
            setAnnouncements(!announcements);
          } else if (target === "tips") {
            setTips(!tips);
          } else if (target === "incognito") {
            setIncognito(!incognito);
          } else if (target === "last-seen") {
            setLastSeen(!lastseen);
          } else if (target === "read-receipts") {
            setRead(!read);
          }
          else if(target === 'remove-from-draws'){
            setDraws(!draws)
          }

          // if(type===)
        })
        .catch((error) => {
        //   console.log(error);
        });
    } catch (error) {
    //   console.log(error);
    }
  }

  function unblockUser(e, userId) {
    e.preventDefault();
    // toggleLoader(true);
    // console.log(userId);

    var data = {
      user_id: profileData?.main?.id,
      blocked_user_id: userId,
    };

    try {
      axiosWithAuth()
        .post(`${url}/chats/block-chat`, data)
        .then((res) => {
        //   console.log(res);
          document.getElementById("blocked-user-" + userId).remove();
        })
        .catch((error) => {
        //   console.log(error);
        });
    } catch (error) {}
  }

  function showErrorMessageInContainer(target, message, targetChild = null){

   var  existingAlert = target.querySelector('.alert');

    if(existingAlert)
        existingAlert.remove();

    if(targetChild)
        target.querySelector(targetChild).innerHTML += '<div class="alert alert-danger">'+message+'</div>';
    else
        target.innerHTML += '<div class="alert alert-danger mt-20">'+message+'</div>';
        
    return true;
}

const handleSkip = (e)=>{
    e.preventDefault()
    setOtherText('I found my life partner!')
    document.getElementById("shareSuccessForm").classList.remove("active");
    document.getElementById("confirmCloseAccount").classList.add("active");
  
}

const formatDate = (inputDate) => {
  // Parse the input date string
  const date = new Date(inputDate);

  // Define options for formatting the date
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };

  // Format the date according to the options
  return date.toLocaleDateString(undefined, options);
};


  return (
    <>
      <div id="SettingsPage">
        <div id="settings-page" className={`${profileData?.is_premium ? 'user-is-premium user-is-incognito' : ' '} `}>
          <div id="account-closing-popup" className="popup-main report has-icon">
            <div id="accountCloseSection" className="wrap active popup-section">
              <button className="close" onClick={closeModals}></button>
              <div id="closeForm" className=" button-list">
                <span className="heading">
                 {t(['close-account-heading',''])}
                </span>
                <div className="field">
                  <div className="details">{t(['close-account-option-1',''])}</div>
                  <button
                    className="submit-report-bt"
                    onClick={(e) => showSuccessForm(e)}
                  ></button>
                </div>
                <div className="field">
                  <div className="details">{t(['close-account-option-2',''])}</div>
                  <button
                    className="submit-report-bt"
                    onClick ={(e)=> handleFixedCancel(e, 'I can’t find my match')}
                    // onClick={(e) => showConfirmPassword(e,othetext)}
                  ></button>
                </div>
                <div className="field">
                  <div className="details">{t(['close-account-option-3',''])}</div>
                  <textarea
                   value={othetext}
                    id="other-report"
                    placeholder={t(['','"Write something here..."'])}
                    onChange={(e)=>setOtherText(e.target.value)}
                  ></textarea>
                  <button
                    className="submit-report-bt"
                    onClick={(e) => showConfirmPassword(e)}
                  ></button>
                </div>
              </div>
            </div>

            <div id="shareSuccessForm" className="popup-section sparkle">
              <div className="wrap">
                <button className="close" onClick={closeModals}></button>
                <span className="heading">
                 {t(['story-heading',''])}
                </span>
                <span className="subheading mt-30">
                  {t(['story-details',''])}
                </span>

                <span className="heading mt-40">{t(['story-form-heading',''])}</span>

                <form id="successStoryForm" onSubmit={handlesuccessStoryForm}>
                  <div className="row">
                    <div className="col-md-6 mb-10">
                      {t(['story-form-label-1',''])}
                      <input 
                       onChange={(e)=>setYourname(e.target.value)}
                      type="text" name="name" placeholder="" required />
                    </div>
                    <div className="col-md-6 mb-10">
                     {t('story-form-label-2','')}
                      <input
                        type="text"
                        name="partner_name"
                        placeholder=""
                        onChange={(e)=>setPartner(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-10">
                      {t(['story-form-label-3',''])}
                      <input
                        value={date}
                        type="date"
                        name="wedding"
                        placeholder="Wedding date"
                        onChange={(e)=>setDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mb-10">
                      {t(['story-form-label-4',''])}
                      <div className="custom-upload-field">
                        <input
                          type="file"
                          name="photo"
                           onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-10">
                     {t(['story-form-label-5',''])}
                      <textarea   onChange={(e)=>setTextarea(e.target.value)} name="message" placeholder=""></textarea>
                    </div>
                    <div className="col-md-12 text-center">
                      <button className="main-bt mt-10">{t(['submit',''])}</button>
                    </div>
                    <div className="col-md-12 text-center mt-10">
                      <Link href="#" className="" onClick={handleSkip}>
                      {t(['skip',''])}
                      </Link>

                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div id="confirmCloseAccount" className="popup-section">
              <div className="wrap">
                <button className="close" onClick={closeModals}></button>
                <span className="heading">{t(['close-account-confirmation-heading',''])}</span>
                <span className="subheading mt-30">
                  {t(['close-account-confirmation-details',''])}
                </span>

                <form id="closeAccountForm" className="mt-20" onSubmit={handleAccountForm}>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="password"
                        placeholder={t(['','"Enter password"'])}
                      />
                    </div>
                    <div className="col-md-6 text-center">
                      <button className="main-bt" style={{ width: "100%" }}>
                        {t('submit','')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div id="shareSuccess" className="popup-section ">
              <div className="wrap">
                <button className="close" onClick={closeModals}></button>
                <span className="heading">{t(['story-thank-you-heading',''])}</span>
                <span className="subheading mt-30 treanlation-breakline">
                 {t(['story-thank-you-details',''])}
                </span>
              </div>
            </div>

            <div id="closeSuccess" className="popup-section check">
              <div className="wrap">
                <span className="heading">
                  {t(['you-have-successfully-message',''])}
                </span>
              </div>
            </div>
          </div>

          <div className="top">
            <div className="user-photo">
              <img
                src={profileData?.photo ? profileData?.photo : imagePlacholder}
                alt={"image-" + profileData?.profile?.first_name}
                onError={handleImageError}
                id="userphoto"
              />
            </div>
            <h1 className="heading">{t(['page-heading',''])}</h1>
          </div>

          <div className="main">
            <div className="menu">
              <ul id="settings-menu">
                <li>
                  <a href="#account" className={activePrivacyOnline || activePrivacyMain || activePrivacyTerms ? "account" : "account active"}>
                    {t(['link-1',''])}
                  </a>
                </li>
                <li>
                  <a href="#preferences" className="preferences ">
                  {t(['link-2',''])}
                  </a>
                </li>
                <li className="payment-disable">
                  <a href="#billing" className="billing">
                  {t(['link-3',''])}
                   
                  </a>
                </li>
                <li>
                  <a href="#notifications" className="notifications ">
                  {t(['link-4',''])}
                  </a>
                </li>
                <li className="payment-disable">
                  <a href="#visibility" className="visibility">
                  {t(['link-5',''])}
                  </a>
                </li>
                
                <li className="has-child">
                  <a href="#privacy" className={activePrivacyOnline || activePrivacyMain || activePrivacyTerms ? "privacy active" : "privacy"}>
                  {t(['link-6',''])}
                  </a>
                  <ul>
                    <li>
                      <a href="#" className={activePrivacyOnline ? "active" : ""}>{t(['link-6-sub-link-1',''])}</a>
                    </li>
                    <li>
                      <a href="#" className={activePrivacyMain ? "active" : ""}>{t(['link-6-sub-link-2',''])}</a>
                    </li>
                    <li>
                      <a href="#" className={activePrivacyTerms ? "active" : ""}>{t(['link-6-sub-link3',''])}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="content" id="main-contents">
              <div className={activePrivacyOnline || activePrivacyMain || activePrivacyTerms ? "content-wrap account" : "content-wrap account active"}>
                <a href="" className="mobile-nav">
                  <span className="account">{t(['link-1',''])}</span>
                </a>

                <h3 className="heading">{t(['section-1-heading',''])}</h3>

                <div className="non-edit">
                  <div className="row">
                    <div className="col-lg-5 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-1'])}</label>
                     
                        <span id="acoount_first_name">
                          {profileData?.profile?.first_name}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-5 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-2',''])}</label>
                        <span id="acoount_last_name">
                          {profileData?.profile?.last_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-10 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-3',''])}</label>
                        <span id="acoount_slug">{profileData?.profile?.slug}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-4',''])}</label>
                        <span id="acoount_email">
                          {profileData?.main?.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12 clm">
                      <div className="field-group">
                        <a href="#" onClick={activateEditMode}>
                          {t(['section-1-link-1',''])}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <form id="account-form" onSubmit={handleProfileEdit}>
                  <div className="row edit-field">
                    <div className="col-lg-12 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-4',''])}</label>
                        <input
                          type="email"
                          placeholder={profileData?.main?.email}
                          name="email"
                          value={email}
                          readOnly
                          id="account-email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row edit-field">
                    <div className="col-lg-6 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-1',''])}</label>
                        <input
                          type="text"
                          name="first_name"
                          value={name}
                          onChange={handleName}
                          id="account-first_name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-2',''])}</label>
                        <input
                          type="text"
                          placeholder={profileData?.profile?.last_name}
                          name="last_name"
                          value={lastname}
                          onChange={handleLastName}
                          id="account-last_name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row edit-field">
                    <div className="col-lg-6 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-5',''])}</label>
                        <input
                          type="password"
                          name="password"
                          placeholder={t(['section-1-label-5',''])}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 clm">
                      <div className="field-group">
                        <label>{t(['section-1-label-6',''])}</label>
                        <input
                          type="password"
                          name="password_repeat"
                          placeholder={t(['section-1-label-6',''])}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row edit-field mt-20">
                    <div className="col-lg-10 clm">
                      <input
                        type="submit"
                        value="Save changes"
                        className="main-bt"
                      />
                    </div>
                  </div>
                </form>

                <div className="divider"></div>

                <a href="/" onClick={(e) => showCloseAccountPopup(e)}>
                 {t(['section-1-link-2',''])}
                </a>
              </div>

              <div className="content-wrap preferences">
                <a href="" className="mobile-nav">
                  <span className="account">{t(['section-2-heading',''])}</span>
                </a>

                <h3 className="heading">{t(['section-2-heading',''])}</h3>

                <div className="row">
                  <div className="col-lg-5 clm">
                    <ul className="toggle-switches big">
                      <li>
                        <span>
                          <label className="subheading">{t(['section-2-label-1',''])}</label>
                          {/* <div className="tooltipy">
                            <div className="message">
                              weekly overview email including: number of profile
                              views and people who liked you
                            </div>
                          </div> */}
                        </span>
                      </li>
                    </ul>
                    <div className="field-group">
                      <select
                        value={language}
                        id="preferences-language"
                        onChange={toggleSettingChange}
                        name="language"
                      >
                        <option value="en"> {t(['english-name-lable',''])}</option>
                        <option value="in"> {t(['hindi-name-lable',''])}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-5 clm">
                    <ul className="toggle-switches big">
                      <li>
                        <span>
                          <label className="subheading mt-40">{t(['section-2-label-2',''])}</label>{" "}
                        </span>
                      </li>
                    </ul>
                    <div className="field-group">
                      <div id="blocked-users" className="user-feeds">
                        {settingsData?.blocked_users?.length > 0 ? (
                          <>
                            {Object.entries(settingsData?.blocked_users).map(
                              ([id, user]) => (
                                <div
                                  key={`blocked-user-${user?.id}`}
                                  className="user"
                                  id={`blocked-user-${user?.id}`}
                                >
                                  <div className="user-photo">
                                    <img
                                      src={user?.photo}
                                      alt={`blocked-user-${user?.id} `}
                                    />
                                  </div>
                                  <span>
                                    {user?.first_name} {user?.last_name}
                                  </span>
                                  <button
                                    className="main-bt small mt-20"
                                    onClick={(e) => unblockUser(e, user?.id)}
                                  >
                                    {t(['unblock',''])}
                                  </button>
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            <p> {t(['no-blocked-users-lable',''])}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="content-wrap billing">
                <a href="" className="mobile-nav">
                  <span className="account">{t(['link-3',''])}</span>
                </a>

                <h3 className="heading">{t(['link-3',''])}</h3>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="field-group plan">
                      <label className="subheading mb-20">
                       {t(['section-3-label-1',''])}
                      </label>
                      <div id="subscription-section"></div>
                    </div>
                    <div className="divider"></div>

                    <div className="row">
                      <div className="col-lg-6 clm">
                        <div className="field-group">
                          <label>{t(['section-3-label-5',''])}</label>

                           {profileData?.subscriptions? (<>
                            <span>{t(['start-lable','Start'])}: {formatDate(profileData?.subscriptions?.start)}     </span>  
                            <span> {t(['end-lable','End'])} : {formatDate(profileData?.subscriptions?.end)} </span>
                          <span>{profileData?.subscriptions?.plan?.name && profileData?.subscriptions?.plan?.slug 
                          ? t([profileData?.subscriptions?.plan?.slug,profileData?.subscriptions?.plan?.name]) : 
                          profileData?.subscriptions?.plan?.name}</span>
                           
                           </>):(<><span>{t(['',''])}</span></>)}

                          {/* <a href="/premium" className="mt-20">
                            {t(['manage',''])}
                          </a> */}
                        </div>

                      </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-lg-6 clm">
                            <div className="field-group">
                                <label>Primary payment method</label>
                                <span>Visa - 6908</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10 clm">
                            <a href="#" className="mt-20">Update</a>
                        </div>
                    </div> */}

                    <div className="divider"></div>
                    {/* {profileData?.trial && (        <button className="cancleTrial_btn"
                            onClick={handleCancle}
                            >
                            Cancel my free trial
                            </button>) } */}
                              <button className="cancleTrial_btn"
                            onClick={handleCancle}
                            >
                            Cancel Subscription
                            </button>
                    {/* <a href="#" onclick="cancelSubscription()">Cancel subscription</a> */}
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="field-group">
                      <label className="subheading mb-20">{t(['manage-add-ons',''])}</label>
    
                    </div>
                    <div className="divider"></div>

                    <div className="row">
                      <div className="col-lg-6 clm">
                        <div className="field-group">
                          <label>{t(['',''])}Add-on</label>
                          {profileData?.addons ? (<>

                     

                              {profileData?.addons?.length>0 ?(<>
                                {Object.entries(profileData?.addons).map(([id, addon]) => (
                                    <>
                                     <span>{t(['start-lable','Start'])}: {formatDate(addon?.start)} </span>  
                                     <span>{t(['end-lable','End'])}: {formatDate(addon?.end)} </span>
                                     <span>{addon?.plan?.name&&addon?.plan?.slug ? t([addon?.plan?.slug,addon?.plan?.name]) : addon?.plan?.name} </span>
                                    </>

                                ))}


                              </>):(<></>)}
                          </>) :(<> <span>{t(['no-incognito-lable',''])}</span></>)}

                          <a href="/premium" className="mt-20">
                          {t(['manage',''])}
                          </a>
                        </div>
                      </div>
                    </div> */}
                    {/* 
                    <div className="row">
                        <div className="col-lg-6 clm">
                            <div className="field-group">
                                <label>Primary payment method</label>
                                <span>Visa - 6908</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10 clm">
                            <a href="#" className="mt-20">Update</a>
                        </div>
                    </div> */}

                    {/* <div className="divider"></div> */}

                    {/* <a href="#" onclick="cancelSubscription()">Cancel subscription</a> */}
                  {/* </div> */}
                </div>
              </div>

              <div className="content-wrap notifications ">
                <a href="" className="mobile-nav">
                  <span className="account">{t(['section-4-heading',''])}</span>
                </a>

                <h3 className="heading">{t(['section-4-heading',''])}</h3>

                <span>{t(['section-4-label-1',''])}</span>

                <ul className="toggle-switches mt-30">
                  <li>
                    <span>
                    {t(['section-4-checkflield-1',''])}{" "}
                      <div className="tooltipy">
                        <div className="message">
                        {t(['section-4-checkflield-tip-1',''])}
                        </div>
                      </div>
                    </span>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={summaryemails}
                        key="notifications-summary-emails"
                        id="notifications-summary-emails"
                        value="summary-emails"
                        onClick={toggleSettingClick}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  {/* <li>
                    <span>
                    {t(['section-4-checkflield-2',''])}Promotions{" "}
                      <div className="tooltipy">
                        <div className="message">
                        {t(['section-4-checkflield-tip-2',''])}
                        </div>
                      </div>
                    </span>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={promotions}
                        key="notifications-promotions"
                        id="notifications-promotions"
                        value="promotions"
                        onClick={toggleSettingClick}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li> */}
                  <li>
                    <span>
                    {t(['section-4-checkflield-3',''])}{" "}
                      <div className="tooltipy">
                        <div className="message">
                        {t(['section-4-checkflield-tip-3',''])}
                        </div>
                      </div>
                    </span>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={announcements}
                        id="notifications-announcements"
                        value="announcements"
                        onClick={toggleSettingClick}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <span>
                    {t(['section-4-checkflield-4',''])}{" "}
                      <div className="tooltipy">
                        <div className="message">
                        {t(['section-4-checkflield-tip-4',''])}
                        </div>
                      </div>
                    </span>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={tips}
                        id="notifications-tips"
                        value="tips"
                        onClick={toggleSettingClick}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>

              <div className="content-wrap visibility ">
                <a href="" className="mobile-nav">
                  <span className="account">{t(['section-5-heading',''])}</span>
                </a>

                <h3 className="heading">{t(['section-5-heading',''])}</h3>

                <ul className="toggle-switches big">
                  <li>
                    <span>
                      <label className="subheading">{t(['section-5-checkflield-1',''])}</label>{" "}
                      {/* <div className="tooltipy">
                        <div className="message">
                        {t(['',''])}weekly overview email including: number of profile
                          views and people who liked you
                        </div>
                      </div> */}
                    </span>
                    <label className="toggle-switch incognito-content">
                      <input
                        type="checkbox"
                        checked={incognito}
                        id="visibility-incognito"
                        value="incognito"
                        onClick={toggleSettingClick}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                </ul>

                <div className="row">
                  <div className="col-lg-7">
                    <p className="treanlation-breakline">
                    {t(['section-5-checkflield-details-1',''])} 
                    {/* By enabling 'Incognito Mode,' your profile will be hidden
                      from other members' search results and will not appear in
                      any match suggestions. You can freely explore potential
                      matches while maintaining your privacy. */}
                    </p>
                    <div className="non-incognito-content">
                      {/* <p>
                      {t(['',''])} To activate incognito mode, you must be an Elite Veil
                        member.{" "}
                      </p> */}
                      <a href="/premium">
                        <button className="main-bt">{t(['upgrade-link',''])}</button>
                      </a>
                    </div>
                  </div>
                </div>

                <div className=" incognito-content">
                  <ul className="toggle-switches big mt-30">
                    <li>
                      <span>
                        <label className="subheading">{t(['remove-entry-label',''])}</label>{" "}
                        {/* <div className="tooltipy">
                          <div className="message">
                          {t(['',''])} weekly overview email including: number of profile
                            views and people who liked you
                          </div>
                        </div> */}
                      </span>
                      <label className="toggle-switch ">
                        <input
                          type="checkbox"
                          checked={draws}
                          id="visibility-remove-from-draws"
                          value="remove-from-draws"
                          onClick={toggleSettingClick}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>

                  <div className="row incognito-content">
                    <div className="col-lg-7">
                      <p>
                      {t(['remove-entry-label-content',''])}
                      </p>
                    </div>
                  </div>
                </div>

                <ul className="toggle-switches big mt-30">
                  <li>
                    <span>
                      <label className="subheading">{t(['section-5-checkflield-2',''])}</label>{" "}
                      {/* <div className="tooltipy">
                        <div className="message">
                        {t(['',''])} weekly overview email including: number of profile
                          views and people who liked you
                        </div>
                      </div> */}
                    </span>
                    <label className="toggle-switch premium-content">
                      <input
                        type="checkbox"
                        checked={lastseen}
                        id="visibility-last-seen"
                        value="last-seen"
                        onClick={toggleSettingClick}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                </ul>

                <div className="row">
                  <div className="col-lg-7">
                    <p className="treanlation-breakline">
                    {t(['section-5-checkflield-details-2',''])}
                    </p>
                    <div className="non-premium-content">
                      <a href="/premium">
                        <button className="main-bt">{t(['upgrade-link',''])}</button>
                      </a>
                    </div>
                  </div>
                </div>

                {/* <ul className="toggle-switches big mt-30">
                  <li>
                    <span>
                      <label className="subheading">Turn off read reciepts</label>{" "}
                      <div className="tooltipy">
                        <div className="message">
                          weekly overview email including: number of profile
                          views and people who liked you
                        </div>
                      </div>
                    </span>
                    <label className="toggle-switch ">
                      <input
                        type="checkbox"
                        checked={read}
                        id="visibility-read-receipts"
                        value="read-receipts"
                        onClick={toggleSettingClick}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                </ul>

                <div className="row">
                  <div className="col-lg-7">
                    <p>
                      By disabling the "Last Seen" feature, other users won't be
                      able to see when you were last active on the platform.
                      This setting allows you to maintain a level of privacy and
                      control over your online presence.
                    </p>
                  </div>
                </div> */}
              </div>

              <div  className={activePrivacyOnline || activePrivacyMain || activePrivacyTerms ? "content-wrap privacy active" : "content-wrap privacy"}>
                <a href="" className="mobile-nav">
                  <span className="account">{t(['link-6',''])}</span>
                </a>

                <h3 className="heading mb-20">{t(['link-6',''])}</h3>

                <div className={activePrivacyOnline ? "child-wrap active" : "child-wrap"} >
                  <div className="field-group mb-20">
                    <label className="subheading">{t(['section-6-sub-1-label-1','']) }</label>
                  </div>

                  <div className="row mb-30">
                    <div className="col-lg-7 offset-lg-1 ">
                    {/* */}
                  
                      <FormatedText text = {t(['section-6-sub-1-label-details-1',''])} separator='*' />
                    </div>
                  </div>
                </div>

                <div className={activePrivacyMain ? "child-wrap active" : "child-wrap"}>
                  <div className="field-group mb-20">
                    <label className="subheading">{t(['section-6-sub-2-heading',''])}</label>
                  </div>

                  <div className="row mb-30">
                    <div className="col-lg-7 offset-lg-1">
                     <FormatedText text = {t(['section-6-sub-2-label-details-1',''])} separator='*' /> 
                    </div>
                  </div>
                </div>

                <div className={activePrivacyTerms ? "child-wrap active" : "child-wrap"}>
                  <div className="field-group mb-20">
                    <label className="subheading">{t(['link-6-sub-link3',''])}</label>
                  </div>

                  <div className="row mb-30">
                    <div className="col-lg-7 offset-lg-1">
            <FormatedText text = {t(['section-6-sub-3-label-details-1',''])} separator='*' /> 
                      {/* <p>
                        Welcome to Mohabbatein! By accessing or using our
                        website, you agree to comply with and be bound by the
                        following Terms of Service. Please read these terms
                        carefully before using our services. If you do not agree
                        with any part of these terms, please refrain from
                        accessing or using our platform.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>Eligibility</span>
                        <br />
                      </p>

                      <p>
                        To use our services, you must be at least 18 years old
                        and have the legal capacity to enter into a matrimonial
                        alliance.
                      </p>

                      <p>
                        By registering on Mohabbatein, you confirm that the
                        information provided during registration is accurate and
                        complete.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>User Accounts</span>
                        <br />
                      </p>

                      <p>
                        You are responsible for maintaining the confidentiality
                        of your account credentials and ensuring their security.
                      </p>

                      <p>
                        Any activities performed through your account are your
                        sole responsibility.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Profile Content
                        </span>
                        <br />
                      </p>

                      <p>
                        You are solely responsible for the content posted on
                        your profile, including text, photos, and other media.
                      </p>

                      <p>
                        Content should not violate any intellectual property
                        rights, privacy rights, or community guidelines.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Prohibited Activities
                        </span>
                        <br />
                      </p>

                      <p>
                        Harassment, abusive language, hate speech, or offensive
                        behaviour towards other users is strictly prohibited.
                      </p>

                      <p>
                        Impersonation of any person, entity, or organisation is
                        not allowed.
                      </p>

                      <p>
                        Engaging in fraudulent or illegal activities on the
                        platform will result in account termination.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Matchmaking Services
                        </span>
                        <br />
                      </p>

                      <p>
                        Mohabbatein Matrimony provides a platform for users to
                        connect and explore potential matrimonial alliances.
                      </p>

                      <p>
                        We do not guarantee successful matches or marital
                        outcomes.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Privacy and Data
                        </span>
                        <br />
                      </p>

                      <p>
                        Your privacy is important to us. Please refer to our
                        Privacy Policy for details on how we collect, use, and
                        protect your data.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Payment and Subscriptions
                        </span>
                        <br />
                      </p>

                      <p>
                        We offer premium services through paid subscriptions. By
                        purchasing a subscription, you agree to the terms of the
                        chosen plan.
                      </p>

                      <p>
                        Payments are processed through secure payment gateways.
                        Refunds are subject to our Refund Policy.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Intellectual Property
                        </span>
                        <br />
                      </p>

                      <p>
                        All content and intellectual property on the website are
                        the property of Mohabbatein Matrimony.
                      </p>

                      <p>
                        You may not use, reproduce, or distribute any content
                        without our explicit consent.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Termination of Services
                        </span>
                        <br />
                      </p>

                      <p>
                        We reserve the right to suspend or terminate your
                        account if you violate these Terms of Service or engage
                        in harmful activities on the platform.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Disclaimer of Warranty
                        </span>
                        <br />
                      </p>

                      <p>
                        Mohabbatein provides its services on an "as-is" and
                        "as-available" basis. We do not guarantee the accuracy,
                        reliability, or availability of our services.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Limitation of Liability
                        </span>
                        <br />
                      </p>

                      <p>
                        Mohabbatein will not be liable for any direct, indirect,
                        incidental, or consequential damages arising from the
                        use or inability to use our services.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Indemnification
                        </span>
                        <br />
                      </p>

                      <p>
                        You agree to indemnify and hold Mohabbatein harmless
                        from any claims, liabilities, or expenses arising from
                        your use of the platform.
                      </p>

                      <p>
                        <span style={{ color: "#9D101E" }}>
                          Governing Law and Jurisdiction
                        </span>
                        <br />
                      </p>

                      <p>
                        These Terms of Service shall be governed by and
                        interpreted in accordance with the laws of [insert
                        country/state].
                      </p>

                      <p>
                        Any disputes arising under these terms will be subject
                        to the exclusive jurisdiction of the courts in [insert
                        location].
                      </p>

                      <p>
                        Thank you for using Mohabbatein Matrimony. We aim to
                        provide a secure and enjoyable experience for all our
                        users. If you have any questions or concerns about these
                        Terms of Service, please contact our support team.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
