import { createSlice } from "@reduxjs/toolkit";
import AxiosWithAuth from "../auth/jwtMiddleware";
import { getLanguageFromLocalStorage } from "../utilities/localization"

const url = process.env.REACT_APP_PUBLIC_URL;
 

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    countryData: [],
    isCountryLoading: false,
    errorMessageCountry: "",
    citiesData: [],
    isCitiesLoading: false,
    errorMessageCity: "",
    notificationToggle: false,
    settingsToggle: false,

    isEditDataLoading: false,
    isLoginLoading: false,
    userToken: [],
    responseData:[],
    isLoginError: false,
    userData:[],
    userLocation:[],
    userLocationCity:[],
    alertMessage:"",
    sidebarTogggle:false,
    currentLanguage: "",
    filterData: [],
    liveUserData:[],
    isLiveUsersLoading:false
  },
  reducers: {
    startCountryLoading: (state) => {
      state.isCountryLoading = true;
    },
    initializeCountry: (state, action) => {
      state.countryData = action.payload;
      state.isCountryLoading = false;
    },
    
    errorGettingCountry: (state) => {
      state.errorMessageCountry = "Could Not Get Data , Please Try Again Later";
    },
    startCityLoading: (state) => {
      state.isCitiesLoading = true;
    },
    initializeCity: (state, action) => {
      // console.log(action);
      state.citiesData = action.payload;
      state.isCitiesLoading = false;
    },
    updateLanguage: (state, action) => {
       //console.log(action);
      state.currentLanguage = action.payload;
    },
    errorMessageCities: (state) => {
      state.errorMessageCity = "Could Not Get Data , Please Try Again Later";
    },
    toggleNotifications: (state, action) => {
      state.notificationToggle = action.payload;
      state.settingsToggle = false;
    },
    toggleSettings: (state, action) => {
      state.settingsToggle = action.payload;
      state.notificationToggle = false;
    },
    editdataLoading: (state) => {
      state.isEditDataLoading = true;
    },
    editdataError:(state)=>{
      state.isEditDataLoading = false;
    }
    ,
    initializeEditdata: (state, action) => {
      state.isEditDataLoading = false;
      // state.editData = action.payload;
    },
    initialLogin: (state) => {
      state.isLoginLoading = true;
      state.isLoginError = false;
    },
    initializeUserData: (state, action) => {
      state.userData = action.payload;
    },
    initializeTokenData:(state, action)=>{
      state.userToken = action.payload;
    },
    responseData:(state, action)=>
    {
     state.responseData = action.payload
    },
    startLiverUserLoading:(state) =>{
      state.isLiveUsersLoading = true;
    },
    initializeLiverUsers :(state, action)=>
    {
      state.isLiveUsersLoading = false;
      state.liveUserData = action.payload
    },
    loginError: (state) => {
      state.userToken = [];
      state.userData =[];
      state.isLoginLoading = false;
      state.isLoginError = true;
    },
    clearuserdata:(state)=>{
      state.userToken = [];
      state.userData =[];
    },
    setUserLocation:(state, action)=>{
      state.userLocation = action.payload
    },
    setUserlocationCity:(state, action) => {
      state.userLocationCity = action.payload
    },
    updateAlertMessage : (state, action)=>{
      state.alertMessage = action.payload
    },
    updatesideBarToggle :(state, action)=>{
      state.sidebarTogggle = action.payload
    },
    initFilters:(state, action)=>{
      state.filterData= action.payload
    }
    ,clearGlobalData:(state) =>{
      state.countryData = [];
      state.isCountryLoading = false;
      state.errorMessageCountry =  '';
      state.isCitiesLoading = false;
      state.errorMessageCity = "";
      state.notificationToggle= false;
      state.settingsToggle = false;
      // state.editData= [];
      state.isEditDataLoading = false;
      state.isLoginLoading = false;
      state.userToken = [];
      state.responseData = [];
      state.isLoginError = false;
      state.userData = [];
      state.userLocation =[];
      state.userLocationCity = []
      state.alertMessage = ''
      state.sidebarTogggle = false
      state.filterData = []
      
    }


    // 

    //
  },
});

export const {
  startCountryLoading,
  initializeCountry,
  errorGettingCountry,
  startCityLoading,
  initializeCity,
  errorMessageCities,
  updateLanguage,
  toggleNotifications,
  toggleSettings,
  editdataLoading,
  initializeEditdata,
  initializeUserData,
  initialLogin,
  loginError,
  responseData,
  clearuserdata,
  initializeTokenData,
  setUserLocation,
  updateAlertMessage,
  updatesideBarToggle,
  setUserlocationCity,
  initFilters,
  clearGlobalData,
  editdataError,
  startLiverUserLoading,
  initializeLiverUsers
} = globalSlice.actions;
export default globalSlice.reducer;

export const getCities = (value) => async (dispatch) => {
  dispatch(startCityLoading());

  try {
    AxiosWithAuth()
      .get(`${url}/cities/get-by-country?country=${value}`)
      .then(({ data }) => {
        // console.log(data);
        dispatch(initializeCity(data));
      }).catch(error=>{
        // console.log(error)
      });
  } catch (error) {
    dispatch(errorMessageCities());
  }
};

export const setUpdatedLanguage = (value) => async (dispatch) => {
        dispatch(updateLanguage(value));
};

export const toggleNotification = (data) => async (dispatch) => {
  dispatch(toggleNotifications(data));
};

export const toggleSetting = (data) => async (dispatch) => {
  dispatch(toggleSettings(data));
};
//
// export const getEditData = () => async (dispatch) => {
//   dispatch(editdataLoading());
//   const language = getLanguageFromLocalStorage() || "en";
//   try {
//     await AxiosWithAuth()
//       .get(`${url}/profiles/get-edit-data`,{
//         params: { language }, 
//       })
//       .then(({ data }) => {
//         // console.log(data);
//         dispatch(initializeEditdata(data?.data));
//       })
//       .catch((error) => {
//         dispatch(editdataError())
//         // console.log(error);
//       });
//   } catch (error) {
//     dispatch(editdataError())

//     // console.log(error);
//   }
// };

//
export const initUserData =(data) => async (dispatch) =>{
  // console.log(data)
    dispatch(initializeUserData(data))
}
export const initTokenData =(data) => async (dispatch) =>{
  dispatch(initializeTokenData(data))
}
export const clearUserData = () => async (dispatch)=>{
   dispatch(clearuserdata())
}


export const updateUserlocation = (data) => async (dispatch) =>{
        dispatch(setUserLocation(data))
}
export const updateUserCityLocation = (data) => async (dispatch) =>{
dispatch(setUserlocationCity(data))
}

export const updateSidebarToggle =(data) =>async (dispatch) =>{
  // console.log('sidetoggle')
    dispatch(updatesideBarToggle(data))
}


export const setFilters = (data) => async (dispatch) =>{
  console.log(`consoledata : ${data}`);
  dispatch(initFilters(data))
}


export const setClearGlobalData = () => (dispatch) =>{
  dispatch(clearGlobalData())
}

export const getLiveUsers = () => async (dispatch) =>{
    dispatch(startLiverUserLoading())
    try{
      await AxiosWithAuth()
            .get(`${url}/get-online-count`,)
            .then(({ data }) => {
              // console.log(data);
              dispatch(initializeLiverUsers(data?.data));
            })
            .catch((error) => {
              // dispatch(editdataError())
              // console.log(error);
            });
    }
    catch (error){

    }
}


