import React, { useEffect,useState } from 'react';
import './support.scss';
import { useTranslation } from "react-i18next";
import { getLanguageFromLocalStorage,savePageTranslateLocalStorage,initializeI18n } from "../../app/utilities/localization"
const Support = () => {
  const { t } = useTranslation();
  const [current_languae,setCurrentLanguage] = useState("");
  const language = getLanguageFromLocalStorage();
  useEffect(() => {
    document.body.classList.add('no_scroll_class');
    document.body.classList.add('support_page_class');
    return () => {
      document.body.classList.remove('no_scroll_class');
      document.body.classList.remove('support_page_class');
    };
  }, []);
  useEffect(() => {
    if(language==="in"){
      setCurrentLanguage('/hi/');
    } else  {
      setCurrentLanguage('');
    }
  
  }, [language]);
  useEffect(() => {
    const fetchData = async () => {
      await savePageTranslateLocalStorage("home");
      initializeI18n(['premium']);
    };

    fetchData();
  }, []);
  return (
    <div id="supportPage">
          <iframe
        src={`https://mohabbatein.com/${current_languae}contact-us/?app_header=on`}
        width="100%"
        height="100%"
        frameBorder="0"
        id="support-page-iframe"
        title="Support"
      ></iframe>
    </div>
  );
};

export default Support;
