import React, { useState, useEffect } from "react";
import { AutocompleteSelect } from ".././../components";
import { useSelector, useDispatch } from "react-redux";
import {
  updatedProfileAttributes,
  setProfileAttributes,
} from "../../app/actions/Profile";
import FieldsLoader from "../../components/UI/FieldsLoader";

import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";



//
export default function ProfileAttrribute({ dataValue, onSelect, toggle }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const data = {
    data: dataValue,
  };

  // console.log(data?.data[2]);

  // console.log()

  // console.log(data)
 const [language , selectedLanguage] = useState([])
 const [languageselect, setlanguageSelect] = useState();

 const [careerselect, setCareerSelect] = useState()

 
  const [career, selectedCareer] = useState([]);
  const [religion, setReligion] = useState();
  const [caste, setCaste] = useState();
  const [languages, setLanguages] = useState([]);

  const [height, setHeight] = useState();
  const [careers, setCareers] = useState();
  const [education, setEducation] = useState();
  const [citizen, setCitizen] = useState();
  const [relocation, setRelocation] = useState();
  const [familytype, setFamilyType] = useState();
  const [familyvalue, setFamilyvalue] = useState();
  const [marital, setMarital] = useState();
  const [diet, setDiet] = useState();
  const [children, setChildren] = useState();
  const [school, setSchool] = useState();
  const [hobbies, setHobbies] = useState();
  const [defaulthobbies, setDefaultHobbies] = useState()

  /*  Selection of Items  */

  const [selectreligion, setSelectReligion] = useState();
  const [selectcaste, setSelectCaste] = useState();
  const [selectlanguage, setSelectLanguage] = useState();
  const [selectheight, setSelectHeight] = useState();
  const [selectcareer, setSelectCareer] = useState();
  const [selecteducation, setSelectEducation] = useState();
  const [selectCitizenship, setSelectCitizenship] = useState();
  const [selectfamilytype, setSelectFamilyType] = useState();
  const [selecctrelocation, setselectionRelocation] = useState();
  const [selectfamilyvalue, setselectFamilyValue] = useState();
  const [selectmaritalstatus, setselectmaritalstatus] = useState();
  const [selectdiet, setselectDiet] = useState();
  const [selectchildren, setselectchildren] = useState();
  const [selectschool, setselectschool] = useState();
  const [selecthobbies, setselecthobbies] = useState();
  const [selectedValue, setSelectedValue] = useState('');

  /* Form Data*/

  const [formdata, setFormData] = useState();
  const { profileCheck, isProfileLoading } = useSelector(
    (state) => state.profile
  );

  const [profilefilled, setProfilefilled] = useState(false);

  const handleReligion = (e) => {
    e.preventDefault();
    // console.log(e.target.value);

    setReligion(e.target.value);
    setSelectReligion(e.target.value);
  };

  const handleCaste = (data) => {
    setCaste(data);
    setSelectCaste(data);
  };

  const handleLanguageSelect = (data) =>{
    selectedLanguage(data)
    setlanguageSelect(data)
  }

  const handleCareerSelect = (data)=>{
    setCareerSelect(data)
    setSelectCareer(data)
  }

  const handleLanguage = (value) => {
    // console.log(value)
    // selectedLanguage(value.slug)
    // setSelectLanguage(value.slug)
    // selectedL
    // e.preventDefault();
     let objects = [];

    setLanguages();
   if (languages) {
      objects = languages;
      var val = (e) => e.slug === value.slug;

     if (objects.some(val)) {
    //     //
        let index = objects.findIndex(val);    
        //     // console.log(objects.findIndex(val))
        objects.splice(index, 1);
      } else {
       var dta = getLanguageBySlug(value.slug);
         objects.push(dta);
       }
    } else {
       var dta1 = getLanguageBySlug(value.slug);
       objects.push(dta1);
     }
     setTimeout(() => {
       if (objects.length > 0) {
         var slug = [];
         objects.forEach((e) => {
           slug.push(e.slug);
         });
         setSelectLanguage(slug);
       }
       setLanguages(objects);
     }, 5);
  };

  const handleSelectCareer = (value) => {
    // console.log("careers");
    setCareers([value]);
    selectedCareer(value?.slug);
    setSelectCareer(value?.slug);
  };

  //
  const getLanguageBySlug = (slug) => {
    var itms = data?.data[1]?.data?.languages;
    return itms.find((item) => item.slug === slug);
  };
  //

  const handleHeight = (data) => {
    setSelectHeight(data);
    setHeight(data);
    // console.log(data);
  };

  const handleEducation = (value) => {
    setEducation(value);
    setSelectEducation(value);
  };

  const handleSelectCitizenship = (value) => {
    setCitizen([value]);
    setSelectCitizenship(value?.slug);
  };
  //
  const handleRelocation = (value) => {
    setRelocation(value);
    setselectionRelocation(value);
    // console.log(value)
  };
  //
  const handleFamilyType = (value) => {
    // console.log(value);
    setFamilyType(value);
    setSelectFamilyType(value);
  };

  //

  const handleFamilyValue = (value) => {
    // console.log(value);
    setFamilyvalue(value);
    setselectFamilyValue(value);
  };

  //
  const handleMaritalStatus = (value) => {
    // console.log(value);
    setMarital(value);
    setselectmaritalstatus(value);
  };

  //
  const handleDiet = (value) => {
    // console.log(value);
    setselectDiet(value);
    setDiet(value);
  };

  //
  const handleChildren = (value) => {
    setChildren(value);
    setselectchildren(value);
  };

  //
  const handleSchool = (value) => {
    // console.log(value);
    setSchool([value]);
    setselectschool(value?.slug);
  };

  const handleHobbies = (data) => {
    // console.log(hobbies)

    let objects1 = [];
    setHobbies();
    if (hobbies) {
      objects1 = hobbies;
      var val = (e) => e.slug === data.slug;

      if (objects1.some(val)) {
        //
        let index = objects1.findIndex(val);
        // console.log(objects.findIndex(val))
        objects1.splice(index, 1);
      } else {
        var dta = getHobbiesBySlug(data);
        // console.log(dta);
        // console.log(getLanguageBySlug(data))
        // objects.push(dta.slug);
        var newobject = [...objects1, dta];
        objects1 = newobject;
      }
    } else {
      var dta1 = getHobbiesBySlug(data);
      objects1.push(dta1);
    }
    // console.log(objects);
    setTimeout(() => {
      if (objects1.length > 0) {
        var slug = [];
        objects1.forEach((e) => {
          // console.log(e)
          slug.push(e.slug);
        });
        setselecthobbies(slug);
      }
      setHobbies(objects1);
    }, 5);
  };

  const handleSelectEdHobbies = (value) => {
    // e.preventDefault()
    // console.log(value)
    setDefaultHobbies(value)
   
    // setSelectedValue(value);
  };

  const getHobbiesBySlug = (dta) => {
    var itms = data?.data[1]?.data?.hobbies;
    return itms.find((item) => item.slug === dta.slug);
  };
  //
  //
  const removeLanguage = (e, value) => {
    e.preventDefault();

    var sels = languages;
    setLanguages();
    sels.splice(value, 1);
    // console.log(sel);
    setTimeout(() => {
      if (sels.length > 0) {
        var slug = [];
        sels.forEach((e) => {
          // console.log(e)
          slug.push(e.slug);
        });
        setSelectLanguage(slug);
      }
      setLanguages(sels);
      //  setFetchCarriers(!fetchcarrier);
    }, 5);
  };

  const removeCareers = (e, value) => {
    e.preventDefault();
    setCareers([]);
    setSelectCareer([]);
  };

  const removeCitizen = (e, value) => {
    e.preventDefault();
    setCitizen();
  };

  const removeSchool = (e, value) => {
    e.preventDefault();
    setSchool();
    setselectschool([]);
  };

  // const removeItem =(value)=>{
  //    return
  // }

  const removeHobbies = (e, value) => {
    e.preventDefault();

    // console.log(value)
    // console.log(hobbies)
    var sels = hobbies;
    setHobbies();
    // console.log(sels);
    sels.splice(value, 1);
    // console.log(sel);
    setTimeout(() => {
      if (sels.length > 0) {
        var slug = [];
        sels.forEach((e) => {
          // console.log(e)
          slug.push(e.slug);
        });
        setselecthobbies(slug);
      }
      setHobbies(sels);
      //  setFetchCarriers(!fetchcarrier);
    }, 5);
  };

  useEffect(() => {
    if (data?.data?.length > 0) {
      if (data?.data[0]?.profile && data?.data[1]?.data) {
        if (profilefilled) return;

        // console.log(data?.data[0]?.profile);
        setReligion(data?.data[0]?.profile?.religion?.slug);
        // setSelectReligion(data?.)
        if (data?.data[0]?.profile?.religion?.slug) {
          setSelectReligion(data?.data[0]?.profile?.religion?.slug);
        }
        if (data?.data[0]?.profile?.caste?.slug) {
          setSelectCaste(data?.data[0]?.profile?.caste?.slug);
        }
        setCaste(data?.data[0]?.profile?.caste?.slug);
        if (data?.data[0]?.profile?.language) {
          // var dtaArry = Object.entries(data?.data[0]?.profile?.language);
          var dta = [];
          selectedLanguage(data?.data[0]?.profile?.language[0]?.slug)
          // data?.data[0]?.profile?.language.forEach((element) => {
          //   // console.log(element);
          //   dta.push(element);
          // });
          // // console.log(dta)
          setLanguages(dta);
          // setSelectLanguage(dta)
        } else {
          selectedLanguage('english')
          // setSelectLanguage([data?.data[1]?.data?.language[0]]);
        }

        if (data?.data[0]?.profile?.height?.slug) {
          setSelectHeight(data?.data[0]?.profile?.height?.slug);
        }
        setHeight(data?.data[0]?.profile?.height?.slug);
        if (data?.data[0]?.profile?.height?.slug) {
          setSelectHeight(data?.data[0]?.profile?.height?.slug);
        } else {
          setSelectHeight(data?.data[1]?.data?.height[0]?.slug);
        }

        //
        // console.log(data?.data[0]?.profile?.career)
        // console.log(data?.data[1]?.data?.career)
        if (data?.data[0]?.profile?.career) {
          setCareers([data?.data[0]?.profile?.career]);
          setCareerSelect([data?.data[0]?.profile?.career?.slug]);
          setSelectCareer(data?.data[0]?.profile?.career?.slug);

        } else {
          setCareerSelect(data?.data[1]?.data?.career[0]?.slug);
          setSelectCareer(data?.data[1]?.data?.career[0]?.slug);
          selectedCareer(data?.data[1]?.data?.career[0]?.slug)
      
        }

  

        setEducation(data?.data[0]?.profile?.education?.slug);
        if (data?.data[0]?.profile?.education?.slug) {
          setSelectEducation(data?.data[0]?.profile?.education?.slug);
        } else {
          setSelectEducation(data?.data[1]?.data?.education[0]?.slug);
        }

        // setCitizen([data?.data[0]?.profile?.citizenship]);
        // setRelocation(data?.data[0]?.profile?.relocate?.slug);
        setFamilyType(data?.data[0].profile?.family_type?.slug);
        if (data?.data[0].profile?.family_type?.slug) {
          setSelectFamilyType(data?.data[0].profile?.family_type?.slug);
        } else {
          setSelectFamilyType(data?.data[1].data?.family_type[0]?.slug);
        }
        if (data?.data[0].profile?.family_value?.slug) {
          setselectFamilyValue(data?.data[0].profile?.family_value?.slug);
        } else {
          setselectFamilyValue(data?.data[1].data?.family_value[0]?.slug);
        }
        setFamilyvalue(data?.data[0].profile?.family_value?.slug);
        if (data?.data[0].profile?.marital_status?.slug) {
          setselectmaritalstatus(data?.data[0]?.profile?.marital_status?.slug);
        } else {
          // console.log(data?.data[1]?.data?.marital_status?.slug);
          setselectmaritalstatus(data?.data[1]?.data?.marital_status[0]?.slug);
        }
        setMarital(data?.data[0].profile?.marital_status?.slug);
        //
        if (data?.data[0].profile?.diet?.slug) {
          setselectDiet(data?.data[0].profile?.diet?.slug);
        }
        //
        else {
          setselectDiet(data?.data[1]?.data?.diet[0]?.slug);
        }

        if (data?.data[0].profile?.children?.slug) {
          setselectchildren(data?.data[0]?.profile?.children?.slug);
                setChildren(data?.data[0]?.profile?.children?.slug);
        }
        else{
          setselectchildren('no-children')
          setChildren('no-children');
        }
        // else{
        //   if()

 
        // setSchool([data?.data[0].profile?.school]);

        if (data?.data[0].profile?.hobbies) {
          var dta1 = [];
          var dta2 = []
           data?.data[0].profile?.hobbies.forEach((element) => {
            dta1.push(element);
            dta2.push(element?.slug)
          });
          setHobbies(dta1);
          setDefaultHobbies(dta2);
        }

        setProfilefilled(true);
      }
    }

    // else{

    // }
    // setReligion()
  }, [data?.data]);

  useEffect(() => {
    var formData = [];

    if (selectreligion) {
      let form = {
        religion: selectreligion,
      };
      formData.push(form);
    }
    if (selectcaste) {
      let form = {
        caste: selectcaste,
      };
      formData.push(form);
      // console.log(selectcaste);
    }
    //
    // console.log(languages)
    // console.log(languages)
    if (languages) {
      languages.forEach((ele) => {
        // formData.append("language", ele);
        // console.log(ele)
        let form = {
          "languages[]": ele.slug,
        };
        formData.push(form);
        // console.log(element)
      });
      setSelectLanguage([languages]);
    } else {
      let form = {
        "languages[]": data?.data[1]?.data?.languages[0].slug,
      };
      formData.push(form);
      setSelectLanguage([data?.data[1]?.data?.languages[0].slug]);
    }

    // console.log(language)
    if(language){
      let form = {
        "languages[]": language,
      };
      formData.push(form);
    }
    //
    if (selectheight) {
      let form = {
        height: selectheight,
      };
      formData.push(form);
      // formData.append("height", selectheight);
      // console.log(selectheight);
    }
    // console.log(selectcareer)
    if (selectcareer) {

     
      let form = {
        career: selectcareer,
      };
      formData.push(form);
      // formData.append("career", selectcareer);
      // console.log(selectcareer);
    }
    if (selecteducation) {
      let form = {
        education: selecteducation,
      };
      formData.push(form);
      // formData.append("education", selecteducation);
      // console.log(selecteducation);
    }
    if (selectCitizenship) {
      let form = {
        citizenship: selectCitizenship,
      };
      formData.push(form);

      // formData.append("citizenship", selectCitizenship);
      // console.log(selectCitizenship);
    }
    if (selecctrelocation) {
      // relocate
      let form = {
        relocate: selecctrelocation,
      };
      formData.push(form);
      // formData.append("relocate", selecctrelocation);
      // console.log(selecctrelocation);
    }
    if (selectfamilytype) {
      // family_type
      let form = {
        family_type: selectfamilytype,
      };
      formData.push(form);
      // formData.append("family_type", selectfamilytype);
      // console.log(selectfamilytype);
    }
    if (selectfamilyvalue) {
      // family_value
      let form = {
        family_value: selectfamilyvalue,
      };
      formData.push(form);
      // formData.append("family_value", selectfamilyvalue);
      // console.log(selectfamilyvalue);
    }
    if (selectmaritalstatus) {
      // marital_status
      let form = {
        marital_status: selectmaritalstatus,
      };
      formData.push(form);
      // formData.append("marital_status", selectmaritalstatus);

      // console.log(selectmaritalstatus);
    }
    if (selectdiet) {
      // diet
      let form = {
        diet: selectdiet,
      };
      formData.push(form);
      // formData.append("diet", selectdiet);
      // console.log(selectdiet);
    }
    if (selectchildren) {
      // children
      // console.log(selectchildren);
      let form = {
        children: selectchildren,
      };
      formData.push(form);
      // formData.append("children", selectchildren);
    }
    if (selectschool) {
      // school
      let form = {
        school: selectschool,
      };
      formData.push(form);
    }
    if (selecthobbies) {
      selecthobbies.forEach((element) => {
        let form = {
          "hobbies[]": element,
        };
        formData.push(form);

        // formData.append("hobbies", element);
      });
    }
    // console.log(formData);
    dispatch(setProfileAttributes(formData));
    // setFormData()



    if (
      selectreligion &&
      selectcaste &&
      selectlanguage &&
      selectheight &&
      selectcareer &&
      selecteducation &&
      selectfamilytype &&
      selectfamilyvalue &&
      selectmaritalstatus &&
      selectdiet &&
      careerselect &&
      language &&
      defaulthobbies

      // &&      selectchildren
    ) {
      // console.log("completed");

      dispatch(updatedProfileAttributes(true));
    } else {
      // console.log("incompleted");
      // console.log('rofile')
      dispatch(updatedProfileAttributes(true));
    }

    // console.log(formData)
    // onSelect(formData);
  }, [
    selectreligion,
    selectcaste,
    // selectlanguage,
    selectheight,
    selectcareer,
    selecteducation,
    selectCitizenship,
    selectfamilytype,
    selecctrelocation,
    selectfamilyvalue,
    selectmaritalstatus,
    selectdiet,
    selectchildren,
    selectschool,
    selecthobbies,
    languages,
    language,
    careerselect
  ]);

  useEffect(() => {
    // console.log(profileCheck);
  }, [profileCheck]);


  
  const handleTouchStart = (e) => {
    e.preventDefault();
    // Your touch start logic
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    // Your touch end logic
  };


  // useEffect

  return (
    <>
      <div className="user-attributes" id="user-attributes">
        <span className="attribute religion">
          <Tooltip
            id="religion-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <label>
           { t(["religion", "Religion"])}

            
            {/* <div
              className="tooltipprofile"
              data-tooltip-id="religion-tooltip"
              data-tooltip-content={t(['','"Select Religion"'])}
            >
              !
            </div> */}
          </label>{" "}
          <div className="setdata" id="profile-religion">
            {/* {!isProfileLoading ? (
              data?.data?.length > 0 && data?.data[0]?.profile?.religion ? (
                <>{data?.data[0]?.profile?.religion?.name}</>
              ) : (
                <div className="none">{t('not-specified-label')}</div>
              )
            ) : (
             <FieldsLoader />
            )} */}
            {data?.data?.length > 0 && data?.data[0]?.profile?.religion ? (
              <>
                {data?.data[0]?.profile?.religion?.slug
                  ? t([data?.data[0]?.profile?.religion?.slug, ""])
                  : data?.data[0]?.profile?.religion?.name}
              </>
            ) : (
              <div className="none">{t(["not-specified-label", ""])}</div>
            )}
          </div>
          <div className={`edit-field`}>
            <div className="autocomplete">
              {/* <select id="edit-religion" type="text" name="religion"></select> */}
              <select
                id="edit-religion"
                name="religion"
                value={religion}
                onChange={(e) => handleReligion(e)}
              >
                {data?.data?.length > 0 ? (
                  // console.log(data?.data[1]?.data.religion),
                  <>
                    {data?.data[1]?.data?.religion ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.religion).map(
                          ([id, relig]) => (
                            <option key={"religion-" + id} value={relig.slug}>
                              {relig?.slug ? t([relig?.slug,'']) : relig?.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        <span className="attribute caste">
        <Tooltip
            id="community-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <label>{t(["community", "Community"])}
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="community-tooltip"
              data-tooltip-content={t(['','Select Community'])}
            >
              !
            </div> */}
          
          </label>{" "}
          <div className="setdata" id="profile-caste">
            {data?.data?.length > 0 && data?.data[0]?.profile?.caste ? (
              <>
                {data?.data[0]?.profile?.caste?.slug
                  ? t([data?.data[0]?.profile?.caste?.slug, ""])
                  : data?.data[0]?.profile?.caste?.name}
              </>
            ) : (
              <>
                <div className="none"> {t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              <select
                id="edit-caste"
                name="caste"
                onChange={(e) => handleCaste(e.target.value)}
                value={caste}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.caste ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.caste).map(
                          ([id, caste]) => (
                            <option key={"religion-" + id} value={caste.slug}>
                              {caste?.slug ? t([caste?.slug,'']) : caste?.slug}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        <span className="attribute language">
        <Tooltip
            id="language-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <div className="religion-attributes-div">
          <label>{t(["language", "Language"])}
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="language-tooltip"
              data-tooltip-content={t(['','Select Language'])}
            >
              !
            </div> */}
                 <span className="attributes-text-required">*Required</span>
          </label>

          </div>
          <div className="setdata" id="profile-language">
            {data?.data?.length > 0 && data?.data[0]?.profile?.language ? (
              <>
                {/* {data?.data?.profile?.language} */}
                {Object.entries(data?.data[0]?.profile?.language).map(
                  ([id, type]) => (
                    <span key={"profile-language-" + id} className="tag">

                      {type?.slug ? t([type?.slug,'']) : type?.name}

                    </span>
                  )
                )}
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className={`edit-field " ${profileCheck && career?.length === 0 ? "required" : ""}`}>
            <div className="autocomplete">
              {/*  */}


              <select
                id="edit-language"
                name="language"
                onChange={(e) => handleLanguageSelect(e.target.value)}
                value={language}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.languages ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.languages).map(
                          ([id, language]) => (
                            <option key={"la-" + id} value={language.slug}>
                              {language?.slug ? t([language?.slug,'']) : language?.slug}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>


                  {/* <AutocompleteSelect
                        options={data?.data[1]?.data?.languages}
                        onSelect={handleLanguage}
                        placeholder={t(["language", ""])}
                      /> */}
{/*  
              {languages ? (
                <>
                  {languages.map((value, key) => (
                    <> 

                      <span
                        key={"language-" + value?.slug}
                        value={value.slug}
                        className="autocompletefilter"
                        onClick={(e) => removeLanguage(e, key)}
                      >
                        {value?.slug && value?.name ? t([value?.slug, value?.name]) : value?.name}
                      </span>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )} */}

              {/* 
              languages
              */}
            </div>
          </div>
        </span>
        <span className="attribute height">
        <Tooltip
            id="height-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <div className="religion-attributes-div">
          <label>{t(["height", "Height"])}
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="height-tooltip"
              data-tooltip-content={t(['','Select Height'])}
            >
              !
            </div> */}
                <span className="attributes-text-required">*Required</span>
          </label>
      
          </div>
          <div className="setdata" id="profile-height">
            {data?.data?.length > 0 && data?.data[0]?.profile?.height ? (
              <> {data?.data[0]?.profile?.height?.name}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              {/* <select id="edit-height" type="text" name="height"></select> */}
              {/*  */}

              <select
                id="edit-height"
                name="height"
                onChange={(e) => handleHeight(e.target.value)}
                value={height}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.height ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.height).map(
                          ([id, height]) => (
                            <option key={"height-" + id} value={height.slug}>
                              {height.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        <span className="attribute career">
        <Tooltip
            id="carrer-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <div className="religion-attributes-div">
          <label>{t(["career", "Career"])}
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="carrer-tooltip"
              data-tooltip-content={t(['','Choose the best suited job'])}
            >
              !
            </div> */}                    <span className="attributes-text-required">*Required</span>
    
          </label>
   
          </div>
          <div className="setdata" id="profile-career">
            {data?.data?.length > 0 && data?.data[0]?.profile?.career ? (
              <>
                {data?.data[0]?.profile?.career?.slug
                  ? t([data?.data[0]?.profile?.career?.slug, ""])
                  : data?.data[0]?.profile?.career?.name}
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          {/* {console.log(career)} */}
          <div
            className={`edit-field ${
              profileCheck && career?.length === 0 ? "required" : ""
            }`}
          >

              <select
                id="edit-careerer"
                name="careeer"
                onChange={(e) => handleCareerSelect(e.target.value)}
                value={careerselect}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.career ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.career).map(
                          ([id, career]) => (
                            <option key={"careeerer-" + id} value={career.slug}>
                              {career?.slug ? t([career?.slug,'']) : career?.slug}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            {/* <div className="autocomplete">
              {data?.data?.length > 0 ? (
                <>
                  {data?.data[1]?.data?.career ? (
                    <>
                      <AutocompleteSelect
                        options={data?.data[1]?.data?.career}
                        onSelect={handleSelectCareer}
                        placeholder={t(["career-placeholder", ""])}
                        value={career}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

             
              {careers?.length > 0 ? (
                // console.log(careers),
                <>
                  {careers.map((value, key) => (
                    // console.log(key),
                    <>
                      <span
                        key={"language-" + value?.slug}
                        value={value?.slug}
                        className="autocompletefilter"
                        onClick={(e) => removeCareers(e, key)}
                      >
                        {value?.slug ? t([value?.slug, ""]) : value?.name}
                      </span>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div> */}
          </div>
        </span>
        <span className="attribute education">
        <Tooltip
            id="education-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <label>{t(["education", "Education"])}
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="education-tooltip"
              data-tooltip-content={t(['','Select Education'])}
            >
              !
            </div> */}
          </label>
          <div className="setdata" id="profile-education">
            {data?.data?.length > 0 && data?.data[0]?.profile?.education ? (
              <>
                {data?.data[0]?.profile?.education?.slug
                  ? t([data?.data[0]?.profile?.education?.slug, ""])
                  : data?.data[0]?.profile?.education?.name}
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              {/* <select id="edit-education" type="text" name="education"></select> */}
              <select
                id="edit-education"
                name="education"
                onChange={(e) => handleEducation(e.target.value)}
                value={education}
                // disabled={isProfileLoading}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.education ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.education)
                        .reverse()
                        .map(
                          ([id, education]) => (
                            <option
                              key={"education-" + id}
                              value={education.slug}
                            >
                              {education?.slug ? t([education?.slug,'']) : education?.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        {/* <span className="attribute citizenship">
          <label>Citizenship</label>
          <div className="setdata" id="profile-citizenship">
            {data?.data?.length > 0 && data?.data[0]?.profile?.citizenship ? (
              <>{data?.data[0]?.profile?.citizenship?.name}</>
            ) : (
              <>
                <div className="none">{t('not-specified-label')}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">


              {data?.data?.length > 0 ? (
                <>
                  {data?.data[1]?.data?.citizenship ? (
                    <>
                      <AutocompleteSelect
                        options={data?.data[1]?.data?.citizenship}
                        onSelect={handleSelectCitizenship}
                        placeholder={"Citizenship.."}
                        value={career}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {citizen?.length > 0 ? (
        
                <>
                  {citizen.map((value, key) => (
                    <>
                      <span
                        key={"citizen-" + value?.slug}
                        value={value?.slug}
                        className="autocompletefilter"
                        onClick={(e) => removeCitizen(e, key)}
                      >
                        {value?.name}
                      </span>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}

              <select
                id="edit-citizenship"
                name="citizenship"
                onChange={(e) => handleSelectCitizenship(e.target.value)}
                value={citizen}
              >
                {data?.data.length > 0 ? (
                  <>
                    {data?.data[1]?.data.citizenship ? (
                      <>
                        {Object.entries(data?.data[1]?.data.citizenship).map(
                          ([id, citizen]) => (
                            <option key={"citizenship-" + id} value={citizen.slug}>
                              {citizen.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span> */}

        {/* <span className="attribute relocate">
          <label>Open to relocate</label>
          <div className="setdata" id="profile-relocate">
            {data?.data?.length > 0 && data?.data[0]?.profile?.relocate ? (
              <> {data?.data[0]?.profile?.relocate?.name} </>
            ) : (
              <>
                <div className="none">{t('not-specified-label')}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              <select
                id="edit-relocate"
                name="relocate"
                onChange={(e) => handleRelocation(e.target.value)}
                value={relocation}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.relocate ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.relocate).map(
                          ([id, relocate]) => (
                            <option
                              key={"relocate-" + id}
                              value={relocate.slug}
                            >
                              {relocate.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span> */}
        <span className="attribute family_type">

        <Tooltip
            id="family-type-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <label>{t(["family-type", "Family Type"])}
          
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="family-type-tooltip"
              data-tooltip-content={t(['','Select Family Type'])}
            >
              !
            </div> */}

          </label>
          <div className="setdata" id="profile-family_type">
            {data?.data?.length > 0 && data?.data[0]?.profile?.family_type ? (
              <>{t([data?.data[0]?.profile?.family_type?.slug, ""])}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              {/* <select
                id="edit-family_type"
                type="text"
                name="family_type"
              ></select> */}
              <select
                id="edit-family-type"
                name="family_type"
                onChange={(e) => handleFamilyType(e.target.value)}
                value={familytype}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.family_type ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.family_type).map(
                          ([id, familytype]) => (
                            <option
                              key={"family-type-" + id}
                              value={familytype.slug}
                            >
                              {familytype?.slug ? t([familytype?.slug,'']) : familytype?.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        <span className="attribute family_value">
        <Tooltip
            id="family-value-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <label>{t(["family-values", "Family Values"])}
{/*           
          <div
              className="tooltipprofile"
              data-tooltip-id="family-value-tooltip"
              data-tooltip-content={t(['','Select Family Value'])}
            >
              !
            </div> */}

          </label>
          <div className="setdata" id="profile-family_value">
            {data?.data?.length > 0 && data?.data[0]?.profile?.family_value ? (
              <>
                {data?.data[0]?.profile?.family_value?.slug &&
                data?.data[0]?.profile?.family_value?.name
                  ? t([
                      data?.data[0]?.profile?.family_value?.slug,
                      data?.data[0]?.profile?.family_value?.name,
                    ])
                  : data?.data[0]?.profile?.family_value?.name}
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              <select
                id="edit-family-value"
                name="family_value"
                onChange={(e) => handleFamilyValue(e.target.value)}
                value={familyvalue}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.family_value ? (
                      <>
                        {Object.entries(data?.data[1]?.data.family_value).map(
                          ([id, familyvalue]) => (
                            <option
                              key={"family-value-" + id}
                              value={familyvalue.slug}
                            >
                              {familyvalue?.slug && familyvalue?.name ? t([familyvalue?.slug,familyvalue?.name]) : familyvalue?.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        <span className="attribute marital_status">
        <Tooltip
            id="marital-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <label>{t(["marital-status", "Marital Status"])}
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="marital-tooltip"
              data-tooltip-content={t(['','Select Marital Status'])}
            >
              !
            </div> */}
          </label>
          <div className="setdata" id="profile-marital_status">
            {data?.data?.length > 0 &&
            data?.data[0]?.profile?.marital_status ? (
              <>
                {data?.data[0]?.profile?.marital_status?.slug &&
                data?.data[0]?.profile?.marital_status?.name
                  ? t([
                      data?.data[0]?.profile?.marital_status?.slug,
                      data?.data[0]?.profile?.marital_status?.name,
                    ])
                  : data?.data[0]?.profile?.marital_status?.name}
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              {/* <select
                id="edit-marital_status"
                type="text"
                name="marital_status"
              ></select> */}

              <select
                id="edit-marital_status"
                name="marital_status"
                onChange={(e) => handleMaritalStatus(e.target.value)}
                value={marital}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.marital_status ? (
                      <>
                        {Object.entries(data?.data[1]?.data.marital_status).map(
                          ([id, marital_status]) => (
                            <option
                              key={"marital_status-value-" + id}
                              value={marital_status.slug}
                            >
                              {marital_status?.slug&&marital_status?.name ? t([marital_status?.slug,marital_status?.name]) : marital_status?.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        <span className="attribute diet">
        <Tooltip
            id="diet-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <label>{t(["diet", "Diet"])}
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="diet-tooltip"
              data-tooltip-content={t(['','Select Diet'])}
            >
              !
            </div> */}
          </label>
          <div className="setdata" id="profile-diet">
            {data?.data?.length > 0 && data?.data[0]?.profile?.diet ? (
              <>
                {data?.data[0]?.profile?.diet?.slug &&
                data?.data[0]?.profile?.diet?.name
                  ? t([
                      data?.data[0]?.profile?.diet?.slug,
                      data?.data[0]?.profile?.diet?.name,
                    ])
                  : data?.data[0]?.profile?.diet?.name}
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              {/* <select id="edit-diet" type="text" name="diet"></select> */}

              <select
                id="edit-diet"
                name="diet"
                onChange={(e) => handleDiet(e.target.value)}
                value={diet}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.diet ? (
                      <>
                        {Object.entries(data?.data[1]?.data?.diet).map(
                          ([id, diet]) => (
                            <option key={"diet-value-" + id} value={diet.slug}>
                              {diet?.slug && diet?.name ? t([diet?.slug,diet?.name]) : diet?.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        <span className="attribute children">
        <Tooltip
            id="children-tooltip"
            className="main-tooltips"
            classNameArrow="custom-arrows"
          />
          <label>{t(["children", "Children"])}
          {/* <div
              className="tooltipprofile"
              data-tooltip-id="diet-tooltip"
              data-tooltip-content={t(['','Select Children'])}
            >
              !
            </div> */}
          </label>
          <div className="setdata" id="profile-children">
            {data?.data?.length > 0 && data?.data[0]?.profile?.children ? (
              <>{data?.data[0]?.profile?.children?.name}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              {/* <select id="edit-children" type="text" name="children"></select> */}
              <select
                id="edit-diet"
                name="diet"
                onChange={(e) => handleChildren(e.target.value)}
                value={children}
              >
                {data?.data?.length > 0 ? (
                  <>
                    {data?.data[1]?.data?.children ? (
                      <>
                        {Object.entries(data?.data[1]?.data.children)
                        .reverse() 
                        .map(
                          ([id, children]) => (
                            <option
                              key={"diet-value-" + id}
                              value={children.slug}
                            >
                              {children.name}
                            </option>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        </span>
        {/* <span className="attribute school">
          <label>School</label>
          <div className="setdata" id="profile-school">
            {data?.data?.length > 0 && data?.data[0]?.profile?.school ? (
              <>{data?.data[0]?.profile?.school?.name}</>
            ) : (
              <>
                <div className="none">{t('not-specified-label')}</div>
              </>
            )}
          </div>
          <div className="edit-field">
            <div className="autocomplete">
              {data?.data?.length > 0 ? (
                <>
                  {data?.data[1]?.data?.school ? (
                    <>
                      <AutocompleteSelect
                        options={data?.data[1]?.data?.school}
                        onSelect={handleSchool}
                        placeholder={"School.."}
                        value={school}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {school?.length > 0 ? (
                <>
                  {school.map((value, key) => (
                    <>
                      <span
                        key={"citizen-" + value?.slug}
                        value={value?.slug}
                        className="autocompletefilter"
                        onClick={(e) => removeSchool(e, key)}
                      >
                        {value?.name}
                      </span>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </span> */}
      </div>
 
  

      <div className="user-tags profile-hobbies" id="user-tags">
        <span className="heading">{t(["hobbies-label", ""])}</span>
        <span className="tags">
          <div className="setdata tagwrap" id="profile-hobbies">
            {data?.data?.length > 0 && data?.data[0]?.profile?.hobbies ? (
              <>
                {/* {data?.data[0]?.profile?.hobbies} */}
                {Object.entries(data?.data[0]?.profile?.hobbies).map(
                  ([id, hobby]) => (
                    <span
                      data-slug={hobby?.slug}
                      className="tag"
                      key={"hobby-" + id}
                      value={hobby?.slug}
                    >
                      <div>
                        {hobby?.slug && hobby?.name
                          ? t([hobby?.slug, hobby?.name])
                          : hobby?.name}
                      </div>
                    </span>
                  )
                )}
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
    
          
          <div className="edit-field">
                {/* {console.log(hobbies)} */}
          {/* <MultiSelect 
            isMulti={true}
            options={data?.data[1]?.data?.hobbies}
            onChange={handleSelectEdHobbies}
            defaultValues={defaulthobbies}
            placeholder={t(["hobbies-placeholder", ""])}
            closeMenuOnSelect={false}
            isSearchable={false}
          /> */}



           <div className="autocomplete">
              {data?.data?.length > 0 ? (
                <>
                  {data?.data[1]?.data?.hobbies ? (
                    <>
                      <AutocompleteSelect
                        options={data?.data[1]?.data?.hobbies}
                        onSelect={handleHobbies}
                        placeholder={t(["hobbies-placeholder", ""])}
                        value={hobbies}
                        className={'profile-hobbies'}
                       
                      />
                      {}

                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {hobbies?.length > 0 ? (
                <>
                  {hobbies.map((value, key) => (
                    <>
                      <span
                        key={"citizen-" + value?.slug}
                        value={value.slug}
                        className="autocompletefilter"
                        onClick={(e) => removeHobbies(e, key)}
                      >
                        {value?.slug&&value?.name ? t([value?.slug,value?.name]) : value?.name}

                      </span>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}

      
            </div> 
          </div>
        </span>
      </div>
    </>
  );
}
