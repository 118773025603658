import AxiosWithAuth from "../auth/jwtMiddleware";
import { createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_PUBLIC_URL;

export const messageSlice = createSlice({
  name: "message",
  initialState: {
    recentlyLiked: [],
    isRecentlyLoading: false,
    errorrecentlyLiked: false,
    chatHistory: [],
    ischathistoryLoading: false,
    errorchathistoryloading: false,
    connectedMessage:false,
  },
  reducers: {
    startRecentlyLiikedLoading: (state) => {
      state.isRecentlyLoading = true;
      state.recentlyLiked = false;
    },
    initializeRecentlyLiked: (state, action) => {
      state.isRecentlyLoading = false;
      state.recentlyLiked = action.payload;
    },

    errorOnRecentlyLiked: (state) => {
      state.errorrecentlyLiked = true;
      state.isRecentlyLoading = false;
    },
    //
    startChatHistory: (state) => {
      state.ischathistoryLoading = true;
      state.errorchathistoryloading = false;
    },
    initialiazeChatHistory: (state, action) => {
      state.chatHistory = action.payload;
      state.ischathistoryLoading = false;
      state.errorchathistoryloading = false;
    },
    errorChatHistory: (state) => {
      state.errorchathistoryloading = true;
      state.ischathistoryLoading = false;
    },
    clearMessageData: (state) =>{
      state.recentlyLiked= [];
      state.isRecentlyLoading= false;
      state.errorrecentlyLiked= false;
      state.chatHistory= [];
      state.ischathistoryLoading= false;
      state.errorchathistoryloading= false;
    },
    updateMessageConnected : (state, action) =>{
      state.connectedMessage= action.payload
    }
  },
});

export const {
  startRecentlyLiikedLoading,
  initializeRecentlyLiked,
  errorOnRecentlyLiked,
  startChatHistory,
  initialiazeChatHistory,
  errorChatHistory,
  clearMessageData,
  updateMessageConnected
} = messageSlice.actions;

export default messageSlice.reducer;

export const getRecentlyLiked = (value) => async (dispatch) => {
  dispatch(startRecentlyLiikedLoading());
  try {
    await AxiosWithAuth()
    .post(`${url}/users/get-liked-users`, value)
    .then(({ data }) => {
      if (data?.status === 200) {
        dispatch(initializeRecentlyLiked(data?.data));
      }
    });
  } catch (error) {
    dispatch(errorOnRecentlyLiked());
  }
};

// export const getRecentlyLikedMore = (value) => async (dispatch) =>
// {
//   dispatch(startRecentlyLiikedLoading());
//   try {
//     await AxiosWithAuth()
//     .post(`${url}/users/get-liked-users`, value)
//     .then(({ data }) => {
//       if (data?.status === 200) {
//         dispatch(initializeRecentlyLikedMore(data?.data));
//       }
//     });
//   } catch (error) {
//     dispatch(errorOnRecentlyLiked());
//   }
// }

export const getChatHistory = (value) => async (dispatch) => {
  dispatch(startChatHistory());
  try {
        await AxiosWithAuth()
        .post(`${url}/chats/get-history`,value)
        .then(({data})=>{
            if(data?.status ===200){
                dispatch(initialiazeChatHistory(data?.data))
                // console.log(data)
                // dispatch()
            }
        })
        .catch(error=>{
          
        })

  } catch (error) {
    dispatch(errorChatHistory());
  }
};


export const setClearMessage = () => (dispatch) =>{
  dispatch(clearMessageData())
}


export const updateMessageConnectesStatus = (data) => async (dispatch) =>{
    dispatch(updateMessageConnected(data))
}