// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageFromLocalStorage } from "../../app/utilities/localization"
import './languageSwitcher.scss';

function LanguageSwitcher({customClass}) {
  const { i18n } = useTranslation();
  const language = getLanguageFromLocalStorage();

  const changeCurrentLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className={`${customClass} language-switch `}>
    <span className={`eng-temp ${language==='en' && 'active-language'}`} onClick={()=>changeCurrentLanguage('en')}>EN</span><span>/</span>
    <span className={`hindi-temp ${language==='in' && 'active-language'}`} onClick={()=>changeCurrentLanguage('in')}>HI</span>
  </div>
  );
}

export default LanguageSwitcher;
