import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import axiosAuth from "../auth/jwtMiddleware";
import { getLanguageFromLocalStorage } from "../utilities/localization"
const url = process.env.REACT_APP_PUBLIC_URL;

export const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    registrationFieldData: [],
    isRegistrationLoading: false,
    errorMessageRegistration: "",
    errorOnRegistrationMessage: "",
    registrationError: false,
    registrationSuccessful: false,
    verificationIsSuccessful:false,
    verificationError:false,
    errorVerificationMessage:"",
    showEntryForm:true,
    showOTPForm:false,
    otpFormEnabled:false,
    showSuccessForm:false,
    userdata:[],
    careerData:[],
    isCareerLoading:false,
    hobbiesData:[],
    isHobbiesLoading:false
  },
  reducers: {
    startRegistrationLoading: (state) => {
      state.isRegistrationLoading = true;
      state.registrationError = false;
      state.registrationSuccessful = false;
    },

    initializeRegistration: (state, action) => {
      state.registrationFieldData = action.payload;
      state.isRegistrationLoading = false;
      
    },
    startCareerLoading :(state) =>{
      state.isCareerLoading = true
    },
    initializeCareer:(state,action) =>{
      state.isCareerLoading = false
      state.careerData = action.payload
    },
    successfullRegistration :(state) => {
       state.registrationSuccessful =true;
       state.isRegistrationLoading = false;
       state.registrationError = false;
       state.showEntryForm=false;
       state.showOTPForm=true

    },
    initializeuserData:(state,action) =>{
      state.userdata =action.payload;
    },
    errorOnCareers :(state)=>{
      state.isCareerLoading = false
    },
    errorgettingRegistration: (state) => {
      state.errorMessageWishlist =
        "Could Not Get Data , Please Try Again Later";
      state.isRegistrationLoading = false;
    },
    startHobbiesLoading:(state)=>{
      state.isHobbiesLoading = true
    },
    initializeHobbies:(state, action)=>{
      state.isHobbiesLoading = false;
      state.hobbiesData = action.payload
    },
    errorOnHobbies:(state)=>{
      state.isHobbiesLoading = false;
      state.hobbiesData = []
    },
    errorOnRegistration: (state, action) => {
      state.errorOnRegistrationMessage = action.payload;
      state.isRegistrationLoading = false;
      state.registrationError = true;
      state.showOTPForm=false;
      state.showSuccessForm=false
    },
    successVerification:(state) =>{
      state.verificationIsSuccessful = true;
      state.isRegistrationLoading = false;
      state.showSuccessForm=true
    },
    errorVerification:(state,action) =>{
      state.errorVerificationMessage = action.payload
      state.verificationError=true;
      state.isRegistrationLoading = false;
      state.showOTPForm=true;
    },
    clearForms:(state) =>{
      state.showSuccessForm=false
      state.showOTPForm=false;
      state.verificationError=false;
      state.errorMessageRegistration ="";
      state.errorOnRegistrationMessage ="";
      state.registrationError=false
    }


  },
});

export const {
  startRegistrationLoading,
  initializeRegistration,
  errorgettingRegistration,
  errorOnRegistration,
  successfullRegistration,
  successVerification,
  initializeuserData,
  errorVerification,
  clearForms,
  startCareerLoading,
  initializeCareer,
  errorOnCareers,
  startHobbiesLoading,
  initializeHobbies,
  errorOnHobbies
} = registrationSlice.actions;
export default registrationSlice.reducer;

// export const getRegisterFields = () => async (dispatch) => {
//   // console.log(url)
//   dispatch(startRegistrationLoading());
//   try {
//     await axios.get(`${url}/registration/get-field-data`).then(({ data }) => {
//       dispatch(initializeRegistration({ data }));
//     })
//     .catch((error=>{
//       dispatch(errorgettingRegistration());
//       console.log(error)
//     }));
//   } catch (error) {
//     dispatch(errorgettingRegistration());
//     console.log(error);
//     return;
//   }
// };

export const getRegisterFields = () => async (dispatch) => {

  dispatch(startRegistrationLoading());
  const language = getLanguageFromLocalStorage() || "en"; 
  try {
    await axios.get(`${url}/registration/get-field-data`, {
      params: { language }, 
    })
      .then(({ data }) => {
        dispatch(initializeRegistration({ data }));
      })
      .catch((error) => {
        dispatch(errorgettingRegistration());
        // console.log(error);
      });
  } catch (error) {
    dispatch(errorgettingRegistration());
    // console.log(error);
    return;
  }
};


export const getCareers = () => async (dispatch)=>{
  dispatch(startCareerLoading());
  const language = getLanguageFromLocalStorage() || "en"; 

  try{
    await axios.get(`${url}/registration/get-careers`, {
      params: { language }, 
    })
      .then(({ data }) => {
        dispatch(initializeCareer({ data }));
      })
      .catch((error) => {
        dispatch(errorOnCareers())
        // console.log(error);
      });

  }
  catch (error){
    dispatch(errorOnCareers())
    return
  }
}

// 


export const getHobbies = () => async (dispatch)=>{
  dispatch(startHobbiesLoading());
  const language = getLanguageFromLocalStorage() || "en"; 

  try{
    await axios.get(`${url}/registration/get-hobbies`, {
      params: { language }, 
    })
      .then(({ data }) => {
        dispatch(initializeHobbies({ data }));
      })
      .catch((error) => {
        dispatch(errorOnHobbies())
        // console.log(error);
      });

  }
  catch (error){
    dispatch(errorOnHobbies())
    return
  }
}

// 
export const registerUser = (data) => async (dispatch) => {
  dispatch(startRegistrationLoading());
  const language = getLanguageFromLocalStorage() || "en";
  
  var headers = {
    "Content-Type": "multipart/form-data" 
  };
  try {
    await axios.post(`${url}/registration/register`, data,headers).then((res) => {
      console.log("registration/register  ",res?.data?.data)
      // console.log(res?.data)
      if (res?.data?.status === 200) {
        dispatch(initializeuserData(res?.data?.data))
        dispatch(successfullRegistration())
      } else {
        dispatch(errorOnRegistration(res?.data?.data));
      }
    })
    .catch(error=>{
      // console.log(error)
      // console.log(error?.response?.data?.data)
      dispatch(errorOnRegistration(error?.response?.data?.data));
    });
  } catch (error) {
    dispatch(errorgettingRegistration());
  }
};

export const verifyPhone =(data) => async (dispatch) =>{
  dispatch(startRegistrationLoading());
  const language = getLanguageFromLocalStorage() || "en";
  try{
    await axios.post(`${url}/users/verify-phone`, {...data, language}).then((res) => {
      // console.log(res)
      // console.log(res?.data)
      // console.log('verification')
      if (res?.data?.status === 200) {
        // console.log('successful verification')
         dispatch(successVerification())
      } else {
        dispatch(errorVerification(res?.data?.data))
        
        // dispatch(errorOnRegistration(res?.data?.data));
      }
    })
    .catch(error=>{
      dispatch(errorVerification(error?.response?.data?.data))
      // console.log(error)
    });
  }
  catch (error){
  
    // console.log(error?.response?.data?.data)
    // console.log('error')

  }
}

export const clearAllForms =() => async(dispatch)=>{
 dispatch(clearForms())
}
