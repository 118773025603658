/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AutocompleteSelect } from "../../components";
import MultiRangeSlider from "../../components/multiRangeSlider/MultiRangeSlider";
import MultiRangeSliderAge from "../../components/multiRangeSliderAge/MultiRangeSlider";

import {
  updateSidebarToggle,
  setUpdatedLanguage,
  clearUserData,
} from "../../app/actions/Global";
import axios from "axios";
import {
  appyFilters,
  setClearLoaders,
  removeUserLoader,
} from "../../app/actions/Home";
import { initFilters } from "../../app/actions/Global";
import "./sidebar.scss";

import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import {
  getLanguageFromLocalStorage,
  loadCities,
} from "../../app/utilities/localization";
import { getProfileData, getEditData } from "../../app/actions/Profile";
import { getNotificationsCount } from "../../app/actions/Notifications";

import filterIcon from '../../assets/images/Main/filtericon_new.svg';

const url = process.env.REACT_APP_PUBLIC_URL;

export default function Sidebar() {
  const AgeSliderRef = useRef();
  const HeightSliderRef = useRef();
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const [isToggledSidebar, SideBartoggle] = useState(false);
  //
  const {
    userLocation,
    userLocationCity,
    sidebarTogggle,
    currentLanguage,
    filterData,
  } = useSelector((state) => state.global);
  const { profileData, isEditDataLoading, editData } = useSelector(
    (state) => state.profile
  );

  //   const [isfilterToggle, setIsFilterToggle] = useState()
  const [ageFilterToggle, setAgeFilterToggle] = useState(true);
  const [heightfilterToggle, setHeightFilterToggle] = useState(false);
  const [locationFilterToggle, setLocationFilterToggle] = useState(false);
  const [careerFilterToggle, setCareerFilterToggle] = useState(false);
  const [religionFilterToggle, setReligionFilterToggle] = useState(false);
  const [communityfilterToggle, setCommunityFilterToggle] = useState(false);
  const [motherFilterToggle, setMotherFilterToggle] = useState(false);
  const [educationFilterToggle, setEducationFilterToggle] = useState(false);
  const [premiumFilterToggle, setPremiumFilterToggle] = useState(false);
  const [filterToggle, setFilterToggle] = useState(true);
  const [height, setHeight] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(false);

  const [cities, setCities] = useState([]);
  const [communities, setComunities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [education, setEducation] = useState([]);
  const [careers, setCareers] = useState([]);
  const [religions, setReligions] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  /****** Filter  Variables******************/

  const [minAge, setMinAge] = useState(18);
  const [minAgeInit, setMinAgeInit] = useState(18);
  const [maxAge, setMaxAge] = useState(99);
  const [maxAgeinit, setMaxAgeInit] = useState(99);
  const [minHeight, setMinHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(36);
  const [minHeightInit, setMinHeightTnit] = useState(0);
  const [maxHeightInit, setMaxHeightInit] = useState(36);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [selectedCareers, setSelectedCareers] = useState([]);
  const [fetchcarrier, setFetchCarriers] = useState(false);
  const [selectedreligion, setSelectedRegion] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [fetchlanguage, setFetchLanguage] = useState(false);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [fetchCommunities, setFetchCommunities] = useState(false);
  const [setselectededucation, setSetEducation] = useState([]);
  const [userloc, setUserLoc] = useState();
  const [userlocCity, setUserLocCity] = useState();

  const [geolocation, setGeolacation] = useState(false);
  const [premium, setPremium] = useState(false);

  const [filtercheck, setfilterCheck] = useState(false);

  //
  const { isFeedLoading, pageNumber } = useSelector((state) => state.home);
  const { notificationsData, notificationsCounter } = useSelector(
    (state) => state.notifications
  );

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const { settingsData } = useSelector((state) => state.setting);
  useEffect(() => {
    i18n.changeLanguage(settingsData?.preferences?.language?.value);
  }, []);
  /************************ */

  // location.pathname === "/"

  const [urlloc, setUrlLoc] = useState("home");

  // console.log(profileData);
  // const []
  //
  //

  function handleFilters(
    minage,
    maxage,
    minheight,
    maxheight,
    countryFil,
    cityFil,
    careerFil,
    selectedFil,
    communityFil,
    langFil,
    educFil,
    premiumFil
  ) {
    // console.log(countryFil)
    // console.log(profileData?.main?.email);
    // console.log(profileData)

    // if (location.pathname === "/") {
    // console.log(location.pathname )
    // console.log(profileData )
    // console.log(isFeedLoading )
    // console.log(profileData )
    if (
      location.pathname === "/" &&
      !isFeedLoading &&
      profileData?.main?.email
    ) {
      const language = getLanguageFromLocalStorage() || "en";

      var formData = new FormData();

      formData?.append("language", language);
      formData?.append("user", profileData?.main?.email);
      formData?.delete("filters[country][]");
      formData?.delete("filters[city][]");
      formData?.delete("filters[career][]");
      formData?.delete("filters[religion][]");
      formData?.delete("filters[caste][]");
      formData?.delete("filters[language][]");
      formData?.delete("filters[education][]");
      formData?.delete("filters[premium][]");
      formData?.delete("filters[age][min]");
      formData?.delete("filters[age][max]");
      formData?.delete("filters[height][min][]");
      formData?.delete("filters[height][max][]");
      formData?.delete("page");

       if(window.innerWidth < 780) {
        formData.append("limit", 20);
       } else {
        if(profileData?.is_premium ){
          formData.append("limit", 10);
        }
        else{
          formData.append("limit", 10);
        }
      }
  

      if (minage || minAge) {
        if (minage) {
          formData.append("filters[age][min]", minage);
          setfilterCheck(true);
        } else {
          formData.append("filters[age][min]", minAge);
        }
      }

      if (maxage || maxAge) {
        if (maxage) {
          formData.append("filters[age][max]", maxage);
          setfilterCheck(true);
        } else {
          formData.append("filters[age][max]", maxAge);
        }
      }

      //
      // console.log(minHeight)
      if (minheight || minHeight === 0) {
        if (minheight) {
          formData.append("filters[height][min]", minheight);
          setfilterCheck(true);
        } else {
          formData.append("filters[height][min]", 0);
        }
      }
      if (maxheight || maxHeight) {
        if (maxheight) {
          formData.append("filters[height][max]", maxheight);
          setfilterCheck(true);
        } else {
          formData.append("filters[height][max]", maxHeight);
        }
      }

      // console.log(country)

      if (countryFil || country) {
        if (countryFil) {
          formData.append("filters[country][]", countryFil);
          setfilterCheck(true);
        } else {
          formData.append("filters[country][]", country);
        }
      }
      //
      if (cityFil || city !== "") {
        if (cityFil) {
          formData.append("filters[city][]", cityFil);
          setfilterCheck(true);
        } else {
          formData.append("filters[city][]", city);
        }
      }
      //
      if (premiumFil || premium) {
        if (premiumFil) {
          formData.append("filters[premium][]", premiumFil);
          setfilterCheck(true);
        } else {
          formData.append("filters[premium][]", premium);
        }
      }
      //
      if (careerFil?.length > 0 || selectedCareers?.length > 0) {
        if (careerFil?.length > 0) {
          careerFil.forEach((element) => {
            formData.append("filters[career][]", element.slug);
          });
          setfilterCheck(true);
        } else {
          selectedCareers.forEach((element) => {
            formData.append("filters[career][]", element.slug);
          });
        }
      }
      //
      if (selectedFil?.length > 0 || selectedreligion?.length > 0) {
        if (selectedFil?.length) {
          selectedFil.forEach((element) => {
            formData.append("filters[religion][]", element.slug);
          });
          setfilterCheck(true);
        } else {
          selectedreligion.forEach((element) => {
            formData.append("filters[religion][]", element.slug);
          });
        }
      }

      if (communityFil?.length > 0 || selectedCommunities?.length > 0) {
        if (communityFil?.length > 0) {
          communityFil.forEach((element) => {
            formData.append("filters[caste][]", element.slug);
          });
          setfilterCheck(true);
        } else {
          selectedCommunities.forEach((element) => {
            formData.append("filters[caste][]", element.slug);
          });
        }
      }

      if (langFil?.length > 0 || selectedLanguages?.length > 0) {
        if (langFil?.length > 0) {
          langFil?.forEach((element) => {
            formData.append("filters[language][]", element.slug);
          });
          setfilterCheck(true);
        } else {
          selectedLanguages?.forEach((element) => {
            formData.append("filters[language][]", element.slug);
          });
        }
      }
      //
      if (educFil?.length > 0 || setselectededucation?.length > 0) {
        if (educFil?.length > 0) {
          educFil?.forEach((element) => {
            formData.append("filters[education][]", element.slug);
          });
          setfilterCheck(true);
        } else {
          setselectededucation?.forEach((element) => {
            formData.append("filters[education][]", element.slug);
          });
        }
      }

      if (pageNumber) {
        var page = pageNumber;
      } else {
        page = profileData?.current_pagination;
      }
      if (!filtercheck) {
        formData.append("page", page);
      } else {
        formData.append("page", 1);
      }
      if(window.innerWidth < 767){
        const prevPage = localStorage.getItem("prevPage");
        const storedPage = localStorage.getItem("currentPage");
        let mobpage = storedPage;

        if(Number(mobpage)===0)
        {
          mobpage = Number(prevPage) + 1;
        }
        formData.delete("page");
        formData.append("page", Number(mobpage));
      }
      // if (filterData) {
      //   if (filterData !== formData) {
      //     formData.append("filter_reset", true);
      //   } else {
      //  formData.append("filter_reset", false);
      //   }
      // }
      // console.log(firstLoad);
      // if(firstLoad){
      // formData.append("filter_reset", true);
      // dispatch(appyFilters(formData));
      // } else {
       
      // }


      setTimeout(() => {
        formData.delete("filter_reset");
        dispatch(initFilters(formData));
         setFirstLoad(false);
      }, 250);
    } else {
      //  dispatch(setClearLoaders())
    }
  }

  function handleNewFilters() {
    if (
      location.pathname === "/" &&
      !isFeedLoading &&
      profileData?.main?.email
    ) {
    }
  }

  function formDataToJson(formData) {
    const json = {};
    formData.forEach((value, key) => {
      json[key] = value;
    });
    return JSON.stringify(json);
  }

  function jsonToFormData(json) {
    const formData = new FormData();
    Object.entries(json).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  function isFormDataNotEmpty(formData) {
    // console.log(formData)
    // console.log(formData.length)
    //   if(formData){
    for (let pair of formData?.entries()) {
      // Check if the value of the FormData entry is not empty
      if (pair[1] !== "" && pair[1] !== null && pair[1] !== undefined) {
        return true; // FormData is not empty
      }
    }
    return false; // FormData is empty
    // }
    // else{
    //   return false
    // }
  }

  //
  //
  const language = getLanguageFromLocalStorage() || "en";

  useEffect(() => {
    var formData = new FormData();
    formData.append("slug", profileData?.profile?.slug);
    formData.append("language", language);
    // console.log(profileData?.profile?.slug)

    if (profileData?.profile?.slug) {
      dispatch(getProfileData(formData));
    }
    // console.log(location.pathname)
    if (
      location.pathname !== "/blog" &&
      location.pathname !== "/profile" &&
      location.pathname !== "/premium-plan" &&
      location.pathname !== "/contact-us" &&
      location.pathname !== "/frequently-asked-questions" &&
      location.pathname !== "/frequently-asked-questions"
    ) {
     // dispatch(updateSidebarToggle(false));
    }

    if (location.pathname !== "/") {
      setTimeout(() => {
        dispatch(removeUserLoader());
      }, 5000);
    }
    if (!editData && !isEditDataLoading) {
      // dispatch(getEditData());
    }

    dispatch(removeUserLoader());
  }, [location]);

  useEffect(() => {
    //
    // console.log(editData)
    setHeight(editData?.data?.height);
    setCountries(editData?.data?.country);
    setCareers(editData?.data?.career);
    let rel = [];
    if (editData?.data?.religion) {
      editData?.data?.religion.map((el) => {
        let rgon = {
          name: el.name,
          slug: el.slug,
          isActive: false,
        };

        rel.push(rgon);
      });
    }
    setReligions(rel);
    setComunities(editData?.data?.caste);
    setLanguages(editData?.data?.languages);
    //
    let edu = [];
    if (editData?.data?.education) {
      editData?.data?.education.map((el) => {
        let edtn = {
          name: el.name,
          slug: el.slug,
          isActive: false,
        };
        edu.push(edtn);
      });
    }
    setEducation(edu);
    // console.log(height)
  }, [editData]);

  //
  //
  const handleAgeFilter = (min, max) => {
    // const minV = min;
    return;
  };

  const handleAgeFilterBlur = (min, max) => {
    setMinAge(min);
    setMaxAge(max);
  };

  const handleHeightFilterBlur = (min, max) => {
    setMinHeight(min);
    setMaxHeight(max);
  };

  const handleHeightFilter = (min, max) => {
    // console.log(min)
  };

  const handleCountry = async (value) => {
    // console.log(value);
    if (value !== "all") {
      loadCities(value);
      try {
        await axios
          .get(`${url}/cities/get-by-country?country=${value}`)
          .then(({ data }) => {
            // console.log(data?.data);
            setSelectedCountry(true);
            setCities(data?.data);
            setCountry(value);
            // console.log(data);
          })
          .catch((error) => {
            // console.log(error)
          });
      } catch (error) {
        // console.log(error);
      }
    } else {
      setSelectedCountry(false);
      setCountry("");
      setCity("");
    }
  };
  //

  const handleCity = async (value) => {
    // console.log(value);
    setCity(value);
  };

  const handleSelectCareer = (value) => {
    // console.log(JSON.parse(value));
    // console.log('value')
    // JSON.parse()
    let objects = [];

    setSelectedCareers();
    // setFetchCarriers(false)
    //  setCareerFilterToggle(false);
    // console.log(careerFilterToggle)
    if (selectedCareers) {
      objects = selectedCareers;
      // console.log(objects)
      var val = (e) => e.name === value.name;
      if (objects.some(val)) {
        let index = objects.findIndex(val);
        // console.log(objects.findIndex(val))
        objects.splice(index, 1);
      } else {
        objects.push(value);
      }
    } else {
      objects.push(value);
    }
    // console.log(objects);
    setSelectedCareers(objects);
    setTimeout(() => {
      setFetchCarriers(!fetchcarrier);
      //
    }, 5);

    // return objects
  };

  const removeCareer = (e, value) => {
    e.preventDefault();

    var sels = selectedCareers;
    // console.log()
    setSelectedCareers();
    sels.splice(value, 1);
    setSelectedCareers(sels);
    setTimeout(() => {
      setFetchCarriers(!fetchcarrier);
    }, 5);

    //  console.log(selectedCareers)
  };

  const selectReligion = (e, value) => {
    e.preventDefault();
    // setSelectedRegion([])
    // console.log(value);
    let relgn = [];
    let selregn = [];
    religions.forEach((element) => {
      let rel = {};
      let selre = {};
      if (element === value) {
        // let active = true;
        if (element.isActive === true) {
          rel = {
            name: element.name,
            slug: element.slug,
            isActive: false,
          };
        } else {
          rel = {
            name: element.name,
            slug: element.slug,
            isActive: true,
          };
          selre = {
            name: element.name,
            slug: element.slug,
            isActive: true,
          };
        }
      } else {
        if (element.isActive === true) {
          selre = {
            name: element.name,
            slug: element.slug,
            isActive: true,
          };
        }
        rel = {
          name: element.name,
          slug: element.slug,
          isActive: element.isActive,
        };
      }
      relgn.push(rel);
      if (selre?.name) {
        selregn.push(selre);
      }
    });
    // setSelectedRegion([])
    setSelectedRegion(selregn);
    setReligions(relgn);
  };

  const handleSelectCommunity = async (value) => {
    // console.log(JSON.parse(value));
    // JSON.parse()
    let objects = [];
    setSelectedCommunities();
    if (selectedCommunities) {
      objects = selectedCommunities;
      // console.log(objects)
      var val = (e) => e.name === value.name;
      if (objects.some(val)) {
        let index = objects.findIndex(val);
        // console.log(objects.findIndex(val))
        objects.splice(index, 1);
      } else {
        objects.push(value);
      }
    } else {
      objects.push(value);
    }

    // console.log(objects);
    setSelectedCommunities(objects);
    setTimeout(() => {
      setFetchCommunities(!fetchCommunities);
      //
    }, 5);
  };

  const removeCommunity = (e, value) => {
    e.preventDefault();
    selectedCommunities.splice(value, 1);
    setTimeout(() => {
      setFetchCommunities(!fetchCommunities);
      //
    }, 5);
    // console.log(value)
  };

  //
  const handleSelectLanguage = async (value) => {
    // console.log(JSON.parse(value));
    // JSON.parse()
    let objects = [];
    setSelectedLanguages();

    if (selectedLanguages) {
      objects = selectedLanguages;
      // console.log(objects)
      var val = (e) => e.name === value.name;
      if (objects.some(val)) {
        let index = objects.findIndex(val);
        // console.log(objects.findIndex(val))
        objects.splice(index, 1);
      } else {
        objects.push(value);
      }
    } else {
      objects.push(value);
    }
    // console.log(objects);
    setSelectedLanguages(objects);

    setTimeout(() => {
      setFetchLanguage(!fetchlanguage);
    }, 5);
  };

  const removeLanguage = (e, value) => {
    e.preventDefault();
    setFetchLanguage();
    var sel = selectedLanguages;
    sel.splice(value, 1);
    setFetchLanguage(sel);

    setTimeout(() => {
      setFetchLanguage(!fetchlanguage);
    }, 5);

    // console.log(value)
  };

  const handleEducation = (e, value) => {
    e.preventDefault();
    setSetEducation([]);

    // console.log(value);
    let edtn = [];
    let seedtn = [];
    education.forEach((element) => {
      let edn = {};
      let seed = {};
      if (element === value) {
        // let active = true;
        if (element.isActive === true) {
          //

          edn = {
            name: element.name,
            slug: element.slug,
            isActive: false,
          };
        } else {
          edn = {
            name: element.name,
            slug: element.slug,
            isActive: true,
          };
          seed = {
            name: element.name,
            slug: element.slug,
            isActive: true,
          };
        }
      } else {
        if (element.isActive === true) {
          seed = {
            name: element.name,
            slug: element.slug,
            isActive: true,
          };
        }
        edn = {
          name: element.name,
          slug: element.slug,
          isActive: element.isActive,
        };
      }
      edtn.push(edn);
      // console.log(seedtn);
      if (seed?.name) {
        seedtn.push(seed);
      }
    });
    setEducation(edtn);
    setSetEducation(seedtn);
  };

  const resetEducation = () => {
    education.forEach((element) => {});
  };

  //
  function handleSideBarToggle() {
    // console.log(isToggledSidebar)
    // SideBartoggle(!sidebarTogggle);
    dispatch(updateSidebarToggle(!sidebarTogggle));
  }
  // function handleFilterToggleSidebar(e) {
  //   e.preventDefault();
  //   dispatch(updateSidebarToggle(true));
  // }
  function handleAgeFilterToggle() {
    setAgeFilterToggle(!ageFilterToggle);
  }

  function handleHeightFilterToggle() {
    setHeightFilterToggle(!heightfilterToggle);
  }

  function handleLocationFilterToggle() {
    setLocationFilterToggle(!locationFilterToggle);
  }

  function handleCareerFilterToggle() {
    setCareerFilterToggle(!careerFilterToggle);
  }

  function handleReligionFilterToggle(e) {
    e.preventDefault();
    setReligionFilterToggle(!religionFilterToggle);
  }

  function handleCommunityFilterToggle() {
    setCommunityFilterToggle(!communityfilterToggle);
  }

  function handleMotherFilterToggle() {
    setMotherFilterToggle(!motherFilterToggle);
  }

  function handleEducationFilterToggle() {
    setEducationFilterToggle(!educationFilterToggle);
  }

  function toggleGeolocation(e) {
    e.preventDefault();
    // console.log("toggleGeolocation");

    // console.log(userLocation)
    // console.log(userLocationCity
    if (geolocation) {
      setCountry("");
      setCity("");
    } else {
      // console.log(geolocation)
      if (userLocation?.length > 0) {
        setCountry(userLocation?.code);
        setCity(userLocationCity?.slug);
        setUserLoc(userLocation);
        setUserLocCity(userLocationCity);
      } else {
        setCountry(profileData?.profile?.location?.country?.code);
        setCity(profileData?.profile?.location?.city?.slug);
        setUserLoc(profileData?.profile?.location?.country);
        setUserLocCity(profileData?.profile?.location?.city);
      }
    }

    setTimeout(() => {
      setGeolacation(!geolocation);
    }, 0);
  }

  function togglePremiumFilter(e) {
    e.preventDefault();
    // console.log(premiumFilterToggle);

    setTimeout(() => {
      setPremium(!premium);
    }, 0);
    // setPremiumFilterToggle(!premiumFilterToggle);
  }

  const resetFilters = () => {
    // console.log('reset')
    // window.location.reload();
    // console.log("reets");
    setMinAge(18);
    setMinAgeInit(18);
    setMaxAgeInit(99);
    setMaxAge(99);
    setMinHeight(0);
    setMaxHeight(36);
    setHeightFilterToggle(false);
    setSelectedCountry(false);
    setLocationFilterToggle(false);
    setCountry("all");
    setCity("");
    setSelectedCareers([]);
    setCareerFilterToggle(false);
    setFetchCarriers([]);
    setSelectedRegion([]);
    let relgn = [];
    religions.forEach((element) => {
      let rel = {
        name: element.name,
        slug: element.slug,
        isActive: false,
      };
      relgn.push(rel);
    });
    setReligions(relgn);

    setReligionFilterToggle(false);
    // setReligions([])
    setSelectedLanguages([]);
    setFetchLanguage(false);
    setSelectedCommunities([]);
    setCommunityFilterToggle(false);
    setFetchCommunities(false);
    setSetEducation([]);
    setUserLoc();
    setUserLocCity();
    setGeolacation(false);
    setPremium(false);
    setEducationFilterToggle(false);
    setMotherFilterToggle(false);
    let edtn = [];
    education.forEach((element) => {
      let edn = {
        name: element.name,
        slug: element.slug,
        isActive: false,
      };
      edtn.push(edn);
    });
    setEducation(edtn);
    // console.log(AgeSliderRef)
    if (AgeSliderRef.current) {
      // Call the onReset function]
      // console.log(AgeSliderRef.current)
      AgeSliderRef.current.resetValues();
    }

    if (HeightSliderRef.current) {
      HeightSliderRef.current.resetValues();
    }
    dispatch(updateSidebarToggle(false));
  };
  useEffect(() => {
    // Function to update the state based on screen size

    var isMobile = false;

    const checkScreenSize = () => {

      if (window.innerWidth < 780 ) {
         if(!isMobile){
     
        isMobile = true


          setIsSmallScreen(!isSmallScreen);

          dispatch(updateSidebarToggle(false));
        }
      } else {
        isMobile = false;
        setIsSmallScreen(!isSmallScreen);
      }
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    // setFetchCarriers(false);

    if (profileData?.main) {
      handleFilters(
        minAge,
        maxAge,
        minHeight,
        maxHeight,
        country,
        city,
        selectedCareers,
        selectedreligion,
        selectedCommunities,
        selectedLanguages,
        setselectededucation,
        premium
      );
    }
  }, [
    profileData,
    minAge,
    maxAge,
    minHeight,
    maxHeight,
    country,
    city,
    fetchCommunities,
    selectedreligion,
    fetchlanguage,
    setselectededucation,
    fetchcarrier,
    premium,
  ]);

  function closeFilters(e) {
    e.preventDefault();
    // SideBartoggle(false);
    dispatch(updateSidebarToggle(false));
  }

  //

  useEffect(() => {
    // console.log(location)
    if (location.pathname === "/") {
      // console.log('home')

      setTimeout(() => {
        setUrlLoc("home");
      }, 50);
    }
    if (location.pathname === "/premium-plan") {
      setUrlLoc("premium");
    }

    if (location.pathname === "/messages") {
      setUrlLoc("messages");
    }
    if (location.pathname === "/profile") {
      setUrlLoc("profile");
    }
    if (location.pathname === "/blog") {
      setUrlLoc("blog");
    }

    // console.log(urlloc);
  }, [location]);

  useEffect(() => {
    var user = {
      user: profileData?.main?.id,
    };
    dispatch(getNotificationsCount(user));
  }, [profileData]);

  useEffect(() => {
    if (language !== currentLanguage) {
      dispatch(setUpdatedLanguage(language));
    }
    if (!isEditDataLoading) {
      console.log("get-edit-data");
      dispatch(getEditData());
    }
  }, [language]);

  return (
    <>
      <div
        id="side-bar-space-area"
        className={sidebarTogggle ? "active " : ""}
      ></div>
      {location.pathname.includes("premium") ? (
        <>
        {!sidebarTogggle && (
          <div id="side-bar-logo" className={`premium-pagesidebar ${sidebarTogggle ? "active " : ""}`}>
          <Link to={"/"} id="main-icon"></Link>
        </div>
        )}
        </>
      ) : (
        <div id="side-bar-logo" className={sidebarTogggle ? "active " : ""}>
          <Link to={"/"} id="main-icon"></Link>
        </div>
      )}

      <div id="sidebar" className={sidebarTogggle ? "active " : ""}>
        {/* <Link to={'/'} id="main-icon"></Link> */}

        <div
          href="#"
          id="toggleSidebar"
          onClick={handleSideBarToggle}
          className={sidebarTogggle ? " custom-link active" : "custom-link"}
        ></div>

        <ul id="main-nav">
          <li>
            <Link
              to="/"
              className={`${
                location.pathname === "/" || location.pathname === ""
                  ? "active "
                  : " "
              }  home animated-text`}
            >
              <span>{t(["home-link", ""])}</span>
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              className={`${
                urlloc === "blog" ? "active " : " "
              } blog animated-text`}
            >
              <span>{t(["blog-link", ""])}</span>
            </Link>
          </li>
          
          <li>
            <Link
              to="/premium-plan"
              className={`${
                urlloc === "premium" ? "active " : " "
              } premium animated-text`}
            >
              <span>{t(["premium-link", ""])}</span>
            </Link>
          </li>
          <li>
            <Link
              to="/messages"
              className={` ${
                urlloc === "messages" ? "active " : " "
              } message  animated-text`}
              id="messagesLink"
              // onClick={(e) => handleNotificationToggle(e)}
            >
              <span>{t(["message-link", ""])}</span>
              {notificationsCounter?.unread_messages > 0 ? (
                <>
                  <div className="count-bubble">
                    {notificationsCounter?.unread_messages}
                  </div>
                </>
              ) : (
                ""
              )}
            </Link>
          </li>
        
         {location.pathname  === "/" && (
           <li>
            <button className={`filters_button_menu_close ${sidebarTogggle && "show_filterbutton"}`}
              onClick={handleSideBarToggle}
            >
             <img src={filterIcon} alt="filter-icon" />
            </button>
          </li> )}
        </ul>

        {location.pathname === "/" ? (
          <>
            <div id="filters">
              <div className="heading">
                {t(["filter-header", ""])}{" "}
                <button id="resetFilters" onClick={(e) => resetFilters(e)}>
                  {t(["reset-link", ""])}
                </button>
              </div>
              <ul>
                <li className="">
                  {profileData?.is_premium ? (
                    <>
                      <div id="geolocate-filter">
                        <span className="location country no-arrow">
                          <span>{t(["auto-locate-header", ""])}</span>
                        </span>
                        <label
                          className="toggle-switch"
                          htmlFor="geolocation-toggle"
                        >
                          <input
                            type="checkbox"
                            checked={geolocation}
                            id="geolocation-toggle"
                            value={geolocation}
                            onChange={(e) => toggleGeolocation(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {geolocation ? (
                        <span className="filter-detail">
                          {t(["location-detected-message", ""])}{" "}
                          {userlocCity?.name && userlocCity?.slug
                            ? t([userlocCity?.slug, userlocCity?.name])
                            : userlocCity?.name}
                          ,
                          {userloc?.name && userloc?.slug
                            ? t([userloc?.slug, userloc?.name])
                            : userloc?.name}{" "}
                        </span>
                      ) : (
                        <>
                          <span className="filter-detail">
                            {t(["people-near-me-label", ""])}
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div id="geolocate-filter">
                        <span className="location country no-arrow disabled">
                          <span>{t(["auto-locate-header", ""])}</span>
                        </span>
                        <Link to="/premium-plan" className="premium-only">
                          {t(["upgrade-label", ""])}
                        </Link>
                      </div>
                      <span className="filter-detail">
                        {t(["auto-locate-details", ""])}
                      </span>
                    </>
                  )}
                </li>

                <li className="">
                  {profileData?.is_premium ? (
                    <>
                      <div id="premium-filter">
                        <span className="premium country no-arrow">
                          <span>{t(["premium-only-label", ""])}</span>
                        </span>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            id="premium-filter-toggle"
                            checked={premium}
                            onChange={(e) => togglePremiumFilter(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <span className="filter-detail">
                        {t(["show-premium-label", ""])}
                      </span>
                    </>
                  ) : (
                    <>
                      <div id="premium-filter">
                        <span className="premium country no-arrow disabled">
                          <span>{t(["premium-only-label", ""])}</span>
                        </span>

                        <Link to="/premium-plan" className="premium-only">
                          {t(["upgrade-label", ""])}
                        </Link>
                      </div>
                      <span className="filter-detail">
                        {t(["show-premium-label", ""])}
                      </span>
                    </>
                  )}
                </li>
                <li className={ageFilterToggle ? "active" : ""}>
                  <span className="label age" onClick={handleAgeFilterToggle}>
                    <span>{t(["age-label", ""])}</span>
                  </span>
                  <MultiRangeSlider
                    ref={AgeSliderRef}
                    min={minAgeInit}
                    max={maxAgeinit}
                    title={t(["ages-label", ""])}
                    apptext={""}
                    onChange={({ min, max }) => handleAgeFilter(min, max)}
                    onMouseUp={({ min, max }) => handleAgeFilterBlur(min, max)}
                    onPointerUp={({ min, max }) =>
                      handleAgeFilterBlur(min, max)
                    }
                    onTouchEnd={({ min, max }) => handleAgeFilterBlur(min, max)}
                  />
                </li>
                <li className={heightfilterToggle ? "active" : ""}>
                  <span
                    className="label height"
                    onClick={handleHeightFilterToggle}
                  >
                    <span>{t(["height-label", ""])}</span>
                  </span>

                  {height ? (
                    // console.log(height.length),

                    <MultiRangeSliderAge
                      ref={HeightSliderRef}
                      min={minHeightInit}
                      max={maxHeightInit}
                      title={t(["from-label", ""])}
                      data={height}
                      apptext={""}
                      onChange={({ min, max }) => handleHeightFilter(min, max)}
                      onTouchEnd={({ min, max }) =>
                        handleHeightFilter(min, max)
                      }
                      onMouseUp={({ min, max }) =>
                        handleHeightFilterBlur(min, max)
                      }
                      onPointerUp={({ min, max }) =>
                        handleHeightFilter(min, max)
                      }
                    />
                  ) : (
                    <></>
                  )}
                </li>

                {!geolocation ? (
                  <li className={locationFilterToggle ? "active" : ""}>
                                      {profileData?.is_premium ? (
                    <>
                    <span
                      className="label location country"
                      onClick={handleLocationFilterToggle}
                    >
                      <span>{t(["location-label", ""])}</span>
                    </span>
                    <div className="filter-wrap full">
                      <select
                        name="country"
                        id="filter-country"
                        onChange={(e) => handleCountry(e.target.value)}
                      >
                     <option value="all" selected={country === 'all'}>
                          {t(["all-countries-placeholder", ""])}
                        </option>
                        {countries ? (
                          <>
                            {" "}
                            {Object.entries(countries).map(([id, country]) => (
                              <option
                                key={"country-code-" + country.code}
                                value={country.code}
                              >
                                {country?.code && country?.name
                                  ? t([country?.code, country?.name])
                                  : country?.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>

                      <select
                        name="city"
                        id="filter-city"
                        className={selectedCountry ? "" : "d-none"}
                        onChange={(e) => handleCity(e.target.value)}
                      >
                        <option value="all">
                          {t(["all-cities-placeholder", ""])}
                        </option>
                        {cities ? (
                          // console.log(cities),
                          <>
                            {" "}
                            {Object.entries(cities).map(([id, city]) => (
                              <option
                                key={"city-code-" + city.slug}
                                value={city.slug}
                              >
                                {city?.slug && city?.name
                                  ? t([city?.slug, city?.name])
                                  : city?.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                    </>) : (
                        <>
                                              <div className="d-flex">
                        <span className="label location country disabled no-arrow ">
                          {t(["location-label", ""])}
                        </span>

                        <Link to="/premium-plan" className="premium-only">
                          {t(["upgrade-label", ""])}
                        </Link>
                      </div>
                        </>
                    )}
                  </li>
                ) : (
                  <></>
                )}

                <li className={religionFilterToggle ? "active" : ""}>
                  <span
                    className={`label religion `}
                    onClick={(e) => handleReligionFilterToggle(e)}
                  >
                    {t(["religion-label", ""])}
                  </span>

                  <ul>
                    {religions.map((data, key) => (
                      <li
                        key={key}
                        className={data?.isActive ? "active" : ""}
                        onClick={(e) => selectReligion(e, data)}
                      >
                        <Link to="/" className="filter nolinkstyle text-black">
                          {data?.slug && data?.name
                            ? t([data?.slug, data?.name])
                            : data?.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className={careerFilterToggle ? "active" : ""}>
                  {profileData?.is_premium ? (
                    <>
                      <span
                        className={`label career `}
                        onClick={handleCareerFilterToggle}
                      >
                        {t(["career-label", ""])}
                      </span>

                      <AutocompleteSelect
                        options={careers}
                        onSelect={handleSelectCareer}
                        placeholder={t(["search-career-placeholder", ""])}
                      />
                      {selectedCareers && careerFilterToggle ? (
                        <>
                          {selectedCareers.map((career, key) => (
                            <Link
                              to="/"
                              key={"selected-key-" + career.slug + "-" + key}
                              className={"autocompletefilter nolinkstyle"}
                              onClick={(e) => removeCareer(e, key)}
                            >
                              <div>
                                {career?.slug && career?.name
                                  ? t([career?.slug, career?.name])
                                  : career?.name}
                              </div>
                            </Link>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <span className="label career no-arrow disabled">
                          {t(["career-label", ""])}
                        </span>

                        <Link to="/premium-plan" className="premium-only">
                          {t(["upgrade-label", ""])}
                        </Link>
                      </div>
                    </>
                  )}
                </li>

                <li className={communityfilterToggle ? "active" : ""}>
                  {profileData?.is_premium ? (
                    <>
                      <span
                        className={`label community `}
                        onClick={handleCommunityFilterToggle}
                      >
                        {t(["community-label", ""])}
                      </span>
                      <AutocompleteSelect
                        options={communities}
                        onSelect={handleSelectCommunity}
                        placeholder={t(["search-community-placeholder", ""])}
                      />
                      {selectedCommunities && communityfilterToggle ? (
                        <>
                          {selectedCommunities.map((community, key) => (
                            <Link
                              to="/"
                              key={"selected-key-" + community.slug + "-" + key}
                              className={"autocompletefilter nolinkstyle"}
                              onClick={(e) => removeCommunity(e, key)}
                            >
                              <div>
                                {community?.slug && community?.name
                                  ? t([community?.slug, community?.name])
                                  : community?.name}
                              </div>
                            </Link>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <span className="label community disabled no-arrow">
                          {t(["community-label", ""])}
                        </span>

                        <Link to="/premium-plan" className="premium-only">
                          {t(["upgrade-label", ""])}
                        </Link>
                      </div>
                    </>
                  )}
                </li>

                <li className={motherFilterToggle ? "active" : ""}>
                  {profileData?.is_premium ? (
                    <>
                      <span
                        className={`label language ${
                          isEditDataLoading && "lable-disable"
                        }`}
                        onClick={handleMotherFilterToggle}
                      >
                        {t(["mother-tongue-label", ""])}
                      </span>

                      <AutocompleteSelect
                        options={languages}
                        onSelect={handleSelectLanguage}
                        placeholder={t(["search-language-placeholder", ""])}
                      />
                      {selectedLanguages && motherFilterToggle ? (
                        <>
                          {selectedLanguages.map((community, key) => (
                            <Link
                              to="/"
                              key={"selected-key-" + community.slug + "-" + key}
                              className={"autocompletefilter nolinkstyle"}
                              onClick={(e) => removeLanguage(e, key)}
                            >
                              <div>
                                {community?.slug && community?.name
                                  ? t([community?.slug, community?.name])
                                  : community?.name}
                              </div>
                            </Link>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <span className="label language disabled no-arrow ">
                          {t(["mother-tongue-label", ""])}
                        </span>

                        <Link to="/premium-plan" className="premium-only">
                          {t(["upgrade-label", ""])}
                        </Link>
                      </div>
                    </>
                  )}
                </li>

                <li className={educationFilterToggle ? "active" : ""}>
                  {profileData?.is_premium ? (
                    <>
                      <span
                        className={`label education`}
                        onClick={handleEducationFilterToggle}
                      >
                        {t(["education-label", ""])}
                      </span>

                      <ul>
                        {education.map((data, key) => (
                          <li
                            key={key}
                            className={data?.isActive ? "active" : ""}
                            onClick={(e) => handleEducation(e, data)}
                          >
                            <Link
                              to="/"
                              className="filter nolinkstyle text-black"
                            >
                              {data?.slug && data?.name
                                ? t([data?.slug, data?.name])
                                : data?.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <span className="label education no-arrow disabled">
                          {t(["education-label", ""])}
                        </span>

                        <Link to="/premium-plan" className="premium-only">
                          {t(["upgrade-label", ""])}
                        </Link>
                      </div>
                    </>
                  )}
                </li>
              </ul>

              <div className="buttons">
                <button
                  id="closeFilters"
                  onClick={(e) => closeFilters(e)}
                  className="large__btn mt-20"
                >
                  {t(["apply-filter-button", ""])}
                </button>
                {/* <button
                  id="resetFilters"
                  onClick={resetFilters}
                  className="main-bt small mt-20"
                >
                  Reset Filters
                </button> */}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <ul id="sub-menu">
          <li>
            <Link to="/about-us">
              {t(["about-us-link", ""])}
            </Link>
          </li>
          {/* <li>
            <Link to="https://dev.mohabbatein.com/main/success-stories/">{t(["mohabbatein-matches-link",''])}</Link>
          </li> */}
          <li>
            <Link to="https://mohabbatein.com/partner-with-us/" target="_blank">
              {t(["partner-with-us-link", ""])}
            </Link>
          </li>
          <li>&nbsp;</li>
          <li>
            <Link to="/settings">{t(["settings-link", ""])}</Link>
          </li>
          <li>
            <Link to="/frequently-asked-questions">{t(["faqs-link", ""])}</Link>
          </li>
          <li>
            <Link to="/settings/?privacy=your-safety-online">
              {t(["your-safety-online-link", ""])}
            </Link>
          </li>
          <li>
            <Link to="/settings/?privacy=terms">{t(["terms-link", ""])}</Link>
          </li>
          
          <li>
            <Link to="/settings/?privacy=privacy_policy">
              {t(["privacy-link", ""])}
            </Link>
          </li>
        </ul>
        <div className="side-bar-footer-links">
          <ul className="side-bar-footer-menu" id="side-bar-footer-menu-area">
            <li>
              <Link
                to="https://wa.me/971502470906"
                className={`whatsapp animated-text`}
                target="_blank"
              >
                <span>{t(["whatsapp-link", "WhatsApp"])}</span>
              </Link>
            </li>
            <li>
              <Link to="/contact-us/" className={`support animated-text`}>
                <span>{t(["support-link", ""])}</span>
              </Link>
            </li>
          </ul>
        </div>
        <LanguageSwitcher customClass={"sideBarSwitcher"} />
        <div className="copyright">
          {/* Website By <a href="https://arenacapital.com/">ARENA</a> <br />© 2023 */}
          {t(["rights-shorter", ""])}
        </div>
      </div>
    </>
  );
}
