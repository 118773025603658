
import { showAlert } from "../../components/showAlerts";
import axiosWithAuth from "../auth/jwtMiddleware";


const url = process.env.REACT_APP_PUBLIC_URL;



export const  generateYears = ()=> {
    let year = [];
    let date = new Date();
    let newdate = date.getFullYear() - 18;
    for (let i = 0; i < 100; i++) {
      year.push({
        name: newdate - i,
      });
    }
    return year
    // setYears(year);
    // selectedYear(year[0]?.name);
  }

  
export const  generateDays = () =>{
    let days = [];
    for (let i = 1; i < 32; i++) {
      days.push({
        name: i,
      });
    }
    return days
  }


  export const mergeCountriesAndCodes = (countries,country_codes ) =>{



const updatedCountries = countries?.map(country => {
    const code = country.code;
    if (country_codes[code]) {
        return { ...country, phone: country_codes[code] };
    }
    return country;
});
 
// Print the updated countries array
 return updatedCountries;


  }


  export function LogOut(e, userId){
    e.preventDefault();
    
    // Get the dispatch function from Redux
 
    // console.log('logout')


    var formData = new FormData();
    formData.append('user_id',userId) 

    var data ={
      'user_id':userId
    }
    try {
      axiosWithAuth()
        .post(`${url}/auth/logout`,data)
        .then(res => {
          // Clear local storage\
          // navigate('/')
            
            localStorage.removeItem("authtoken");    
            localStorage.removeItem('floatingMessageAdd')
            localStorage.removeItem('persist:root')
            localStorage.removeItem('premiumPopup-lastRun')
            localStorage.removeItem('ghostPopup-lastRun')
            localStorage.removeItem('iphone-lastRun')
            localStorage.removeItem('premiumPopup-lastRun')
            localStorage.removeItem('activeSlide')
            localStorage.removeItem('currentPage')
            localStorage.removeItem('prev_page_location')
            localStorage.removeItem('currentPageDesktop')
            localStorage.removeItem('phoneVerificationShown');
            localStorage.removeItem('emailVerificationShown');
            


            window.location.reload(true);

            window.location.href = '/login';
        
          // Perform any other necessary cleanup

          
        })
        .catch(error => {
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          } 
          if(error?.response?.data?.status===422){
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (e) {
      // showAlert()
      showAlert();
      // console.log(e);
    }
  }





 export const capitalizeWords = (str) => {
    const strString = String(str);
    return strString
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };


