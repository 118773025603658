import React, { useState, useEffect } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import Topbar from "./Topbar";
// import Sidebar from "./Sidebar";
//muthu
import Sidebar from "./Sidebar/indexWithPayment.js";
import Notifications from "./Notifications";
import Bottombar from "./Bottombar";
import TrialPage from "../pages/trial-page";
import "./layout.css";
import LoginPage from "../app/auth/Login";
import { ProfileSubscription } from "../components";
import GlobalAlerts from "../components/globalAlerts";
import { useSelector, useDispatch } from "react-redux";
import { getProfileData } from "../app/actions/Profile";
import { toggleNotification } from "../app/actions/Global"

import {
  getLanguageFromLocalStorage,
} from "../app/utilities/localization";

export default function Index({ children }) {
  const token = localStorage.getItem("authtoken");
  const { profileData } = useSelector((state) => state.profile);
  const [trailSuccessFull,setTrialSuccessful] = useState(false);
  const [filterSlider,setfilterSlider] = useState(false);
  const dispatch = useDispatch();
  const { sidebarTogggle, notificationToggle } = useSelector(
    (state) => state.global
  );
  const location = useLocation();
  const { isUserProfilesLoading } = useSelector((state) => state.home);

  const handleOverlayClick = () => {
    dispatch(toggleNotification(!notificationToggle));
  };

  //
  // useEffect(() => {

  //   if(location.pathname === "/premium") {
  //     const queryParams = new URLSearchParams(location.search);
  //     const success = queryParams.get('success');
  //    if(success==="true") {
  //     const language = getLanguageFromLocalStorage() || "en";
  //     var formData = new FormData();
  //     formData.append("slug", profileData?.profile?.slug);
  //     formData.append("language", language);
    
  //     if (profileData?.profile?.slug) {
  //       dispatch(getProfileData(formData));
  //     }
  //    }
  //   }
  // }, []);

// useEffect(() => {
//   if(location.pathname === "/premium") {
//     const queryParams = new URLSearchParams(location.search);
//     const success = queryParams.get('success');
//    if(success==="true") {
//     const language = getLanguageFromLocalStorage() || "en";
//     var formData = new FormData();
//     formData.append("slug", profileData?.profile?.slug);
//     formData.append("language", language);
  
//     if (profileData?.profile?.slug) {
//       dispatch(getProfileData(formData));
//     }
//    }
//   }
// }, []);

// const handleLocationRequest = () => {
//   if (!navigator.geolocation) {
//     console.log('Geolocation is not supported by your browser');
//     return;
//   }

//   const success = (position) => {
//     const latitude = position.coords.latitude;
//     const longitude = position.coords.longitude;
//     console(`Latitude: ${latitude}, Longitude: ${longitude}`);
//     console(null); // Reset error state in case of success
//   };

//   const failure = () => {
//     console('Unable to retrieve your location');
//   };

//   navigator.geolocation.getCurrentPosition(success, failure);
// };

// useEffect(() => {
//   handleLocationRequest();
// },[]);



  // if(token && profileData?.show_trial_page) {

  //   return (
  //     <>
  //       <TrialPage />
  //     </>
  //   );
  // } else 

  if(location.pathname === "/premium" || 
     location.pathname === "/upgrade-to-premium" || 
     location.pathname === "/start-your-free-trial" || 
     location.pathname === "/iphone-terms" || 
     location.pathname === "/premium/" || 
     location.pathname === "/upgrade-to-premium/" || 
     location.pathname === "/iphone-terms/" || 
     location.pathname === "/start-your-free-trial/" 
     ) {
    window.location.href = "/";
  } else 
  if (
    (!token || token === "") &&
    location.pathname !== "/register" &&
    location.pathname !== "/register/:id" &&
    location.pathname !== "/reset-password" &&
    location.pathname !== "/reset-password/" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/forgot-password/" &&
    location.pathname !== "/unsubscribe/" 
       
  ) {
    return (
      <>
        <LoginPage />
      </>
    );
  } else {
    return (
      <>
        {
        location.pathname === "/register" ||
        location.pathname === "/register/" ||
        location.pathname === "/register/:id" ||
        location.pathname === "/register/:id/" ||
        location.pathname === "/reset-password/" ||
        location.pathname === "/reset-password" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/forgot-password/" ||
        location.pathname === "/login" ||
        location.pathname === "/login/" ||
        location.pathname === "/upgrade-to-premium" ||
        location.pathname === "/upgrade-to-premium/" ||
        location.pathname === "/start-your-free-trial" ||
        location.pathname === "/start-your-free-trial/" || 
        location.pathname === "/unsubscribe/" ||
        location.pathname === "/unsubscribe"  
        ? (
          <>{children}</>
        ) : (
    
          <div id="main"  className={`${(location.pathname.includes('premium') &&' hasPremium ') || (location.pathname.includes('about') &&' hasaboutUs ')}`}   >
            <Topbar key={"topbar"} setfilterSlider={setfilterSlider} />
            <Sidebar key={"sidebar"} filterSlider = {filterSlider}/>
            <Notifications key={"notification"} />

            <div
              id="main-content"
              className={`${location.pathname !== "/" ? "full" : ""} ${
                sidebarTogggle ? " active" : ""
              }`}
              key={"main-div"}
            >
     
              {children}
            </div>

            <Bottombar key={"bottombar"} />
            <ProfileSubscription />

            {/* <div class="loader"></div> */}
          </div>
        )}
        <div className="overlay" onClick={handleOverlayClick}></div>
        <GlobalAlerts />
      </>
    );
  }
}
