

export default function Emotes({onSelect, messageId}) {

    var emotes = [
        "wow",
        "inlove",
        "heart",
        "smile",
        "lol",
        "sad",
        "wink",
        "laugh",
        "shock",
        "tongue",
      ];
      var emotesShortCodes = [];
    
      for (var x = 0; x < emotes.length; x++) {
        emotesShortCodes.push("![emote-" + emotes[x] + "]!");
      }

    function chooseEmoticon(e){
    e.preventDefault();
    // console.log(messageId)
      //  console.log(e.target.value)
       var data ={
        'emote': e.target.value,
        'messageid' : messageId

       }
       onSelect(data)
    }


    return(
    <>
    <div className="emote-popup">
    {emotes.map((emote, index) => (
          <button
            key={index}
            value={emote}
            className={`emoteBt ${emote}`}
             onClick={(e) =>chooseEmoticon(e)}
             >
          </button>
        ))
    }
    </div>
       


    </>)
}