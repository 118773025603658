import React, { useState, useEffect } from "react";

import { useTranslation } from 'react-i18next';
import { savePageTranslateLocalStorage,initializeI18n,getLanguageFromLocalStorage } from "../utilities/localization"
import AuthFooter from './AuthFooter';
import AuthHeader from "./AuthHeader";
import Logo from "../../assets/images/Main/main-logo.svg";
import "./resetpasswordstyle.scss";
import axios from "axios";
import sideloader from "../../assets/images/sidebuttonloader.gif";
import SuccessImage from "../../assets/images/success.png";

const url = process.env.REACT_APP_GUEST_URL;

export default function ResetPassword() {
  const [token, setToken] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [passRepeat, setPassRepeat] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [resetResponse, setResetResponse] = useState(0);
  const [resetMessage, setResetMessage] = useState("");

  //

  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      await savePageTranslateLocalStorage("auth");
      initializeI18n();
    };
    fetchData();

   
    }, []);



  useEffect(() => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);

    if (queryParams.get("token")) {
      setToken(queryParams.get("token"));
    }
    if (queryParams.get("email")) {
      setEmail(queryParams.get("email"));
    }
  }, []);

  function handleFormSubmit(e) {
    e.preventDefault();
    //
    var data = {
      email: email,
      password: password,
      "password_confirmation": passRepeat,
      token: token,
    };

    // console.log(data);
    setResetResponse(0);
    setResetMessage("");
    setIsLoading(true);
    setSuccess(false);
    try {
      axios
        .post(`${url}reset-password`, data)
        .then((response) => {
          setResetResponse(1);
          // console.log(response);
          setIsLoading(false);
          setSuccess(true);
        })
        .catch((error) => {
          setResetResponse(2);
          // console.log(error);
          setResetMessage(error?.response?.data?.data);
          setIsLoading(false);
          setSuccess(false);
        });
    } catch (e) {
      setResetResponse(2);
      setResetMessage("We have encountered an error, please try again later");
      setIsLoading(false);
      setSuccess(false);
      // console.log(e);
    }
  }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  return (
    <>
      <div id="ResetPassword">
    
          <>
            <AuthHeader />
            <section id="main">
              <div className="left-top heart"></div>
              <div className="left-bot heart"></div>

              <div
                id="formwrap"
                className={`formwrap  ${success ? "" : " active"}`}
              >
                <form id="reset-form" onSubmit={handleFormSubmit}>
                  <div className="text-center">
                    <h1 className="heading" style={{ marginBottom: "60px" }}>
                    {t(["password-reset-heading",''])}
                    </h1>
                    {/* <input
                  className=""
                  type="text"
                  name="token"
                  value="{{ $_GET['token'] }}"
                />
                <input
                  className=""
                  type="text"
                  name="email"
                  value="{{ $_GET['email'] }}"
                />
                <br />
                <br /> */}

                    <div className="step step-1 active">
                      <div className="form-group">
                        <div className="label">{t(['pr-new-pass-label',''])}</div>
                        <input
                          className=""
                          type="password"
                          placeholder="***********"
                          name="password"
                          value={password}
                          onChange={handlePassword}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <div className="label">{t(['pr-new-pass-repeat-label',''])}</div>
                        <input
                          className=""
                          type="password"
                          placeholder="***********"
                          name="password_confirmation"
                          value={passRepeat}
                          onChange={(e) => setPassRepeat(e.target.value)}
                          required
                        />
                      </div>

                      <div className="row">
                        <div className="col-12 text-error">
                          {resetResponse === 1 ? (
                            <span>{resetMessage} </span>
                          ) : (
                            <span> {resetMessage} </span>
                          )}
                        </div>
                      </div>

                      <div className="row navs">
                        <div className="col-12">
                          <div
                            className="alert alert-danger"
                            id="login-error"
                          ></div>
                          <div className="d-flex justify-content-end">
                            {/*  */}
                            {isLoading ? (
                              <button
                                className="nav-bt submit-loading float-end"
                                disabled
                                style={{ minWidth: "118px" }}
                              >
                                <img
                                  src={sideloader}
                                  alt={"loader"}
                                  style={{
                                    height: "50px",
                                    marginRight: "-10px",
                                    marginTop: "-5px",
                                  }}
                                />
                              </button>
                            ) : (
                              <>
                                <button
                                  type="submit"
                                  className="nav-bt red float-lg-end"
                                >
                                 {t(["reset-button",''])}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/*  */}

              <div
                id="registration-complete"
                className={`formwrap  ${success ? " active" : "  "}`}
              >
                <div className="text-center">
                  <h2 className="heading">
                   {t(['all-set-1',''])} <span className="red-text">{t(['all-set-2',''])}</span>
                  </h2>
                  <img src={SuccessImage} alt="Mohabbatein Success" />
                  <br />
                  <p>
                    {t(['discover-text',''])}
                  </p>
                  <br />
                  <a href="/login">
                    <button
                      className="nav-bt red float-end"
                      // onClick={(e) => handleLogin(e)}
                    >
                      {t(['login-now',''])}
                    </button>
                  </a>
                </div>
              </div>

              {/*  */}

              <div className="right-top heart"></div>
              <div className="right-bot heart"></div>
            </section>

        
          <AuthFooter />
          </>



      </div>
    </>
  );
}
