import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./GhostToggle.scss";
import axiosWithAuth from "../../app/auth/jwtMiddleware";
import { useTranslation } from "react-i18next";
const url = process.env.REACT_APP_PUBLIC_URL;

function GhostToggle({ customClass = "" }) {
  const { t } = useTranslation();
  const { settingsData } = useSelector((state) => state.setting);
  const {profileData} = useSelector ((state) => state.profile)
  const [incognito, setIncognito] = useState(profileData?.profile?.ghost_mode_active);

  function toggleSettingChange(e) {
    // e.preventDefault();
    // setIncognito(current => !current);
    var slug = e.target.value;
    var value = e.target.checked;
    updateSetting(slug, value);
  }

  function updateSetting(target, value) {
    var data = {
      user: profileData?.main?.id,
      setting: target,
      value: value,
    };
    // console.log(data);
    try {
      axiosWithAuth()
        .post(`${url}/account/update-setting`, data)
        .then((res) => {
          setIncognito(!incognito);
          // if(type===)
        })
        .catch((error) => {
          //   console.log(error);
        });
    } catch (error) {
      //   console.log(error);
    }
  }
  
useEffect(() => {
  setIncognito(profileData?.profile?.ghost_mode_active)
}, [profileData])


  return (
    <div id="top-bar-ghost-switch" className="icon-only">
      <div className={`${customClass} ghost-switch `}>
        <label className="toggle-switch">
          <input
            type="checkbox"
            checked={incognito}
            key="notifications-summary-emails"
            id="notifications-summary-emails"
            value="incognito"
            onClick={toggleSettingChange}
          />
          <span className="checkmark"></span>
        </label>
        <span className={`ghost-toggle-lable ${incognito && "active-lable"}`}>
          {t(["ghost-mode", ""])}
        </span>
      </div>
    </div>
  );
}

export default GhostToggle;
