import React,{useState,useEffect} from 'react'
import { useSelector } from "react-redux";
import axiosWithAuth from "../../app/auth/jwtMiddleware";
import { showAlert } from "../../components/showAlerts";
// import Trial7Dayes from '../../components/Trial7Days';
import {
  savePageTranslateLocalStorage,
  initializeI18n,
  getLanguageFromLocalStorage,
} from "../../app/utilities/localization";
import { useTranslation } from "react-i18next";
import './trialPage.scss';



const url = process.env.REACT_APP_PUBLIC_URL;

const TrialPage = () => {

  const [loading, setLoading] = useState(true);
  const [currency,setCurrency] = useState();
  const [pricing,setPricing] = useState();
  const { profileData } = useSelector((state) => state.profile);
  const { t } = useTranslation();


  useEffect(() => {
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 300);

    const fetchData = async () => {
      await savePageTranslateLocalStorage("registration");
      initializeI18n(['settings']);
    };
    fetchData();
  }, []);

  const handleStartTrial = (e) => {
    e.preventDefault();
    makePurchase('trial');
    console.log('Starting trial...');
  };

  const handleSubscription = (e) => {
    e.preventDefault();
    makePurchase('subscribe');
    console.log('Starting subscription...');
  };


  const cancelTrial = (e) => {
    e.preventDefault();
    makePurchase('cancel_trial');
    console.log('Canceling trial...');
  };

//   useEffect(()=>{
//     getPricing();
//  },[])


 function getPricing() {
  
  var data = {
    user: profileData?.main?.id,
    country: profileData?.profile?.location?.country?.slug,
  };
  setLoading(true);
  try {
    axiosWithAuth()
      .post(`${url}/premium/get`, data)
      .then((response) => {
        let thissubscriptions = response?.data?.data?.subscriptions;
        setCurrency(thissubscriptions?.currency);
        setPricing(thissubscriptions);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.code === "ERR_NETWORK") {
          showAlert(error?.message);
        }
        if (error?.response?.data?.status === 422) {
          showAlert(error?.response?.data?.data);
        } else {
          showAlert("We have encountered an error");
        }
        setLoading(false);
      });
  } catch (error) {
    setLoading(false);
    showAlert("We have encountered an error");
  }
}


  function makePurchase(action) {
   
    setLoading(true);
    var purchaseData = [];
    var purchase_url = "/payments/create-order";

    switch (action) {
        case 'trial':
            purchase_url = '/trial-payments/create-order';
            break;
        case 'cancel_trial': 
            purchase_url = '/trial-payments/cancel-trial';
            break;
        default:
            break;
    }
    
      let det = {
        type: "subscription",
        slug: "premium",
        length: 1,
      };
      purchaseData.push(det);
    

    var data = {
      user_id: profileData?.main?.id,
      currency: currency,
      items: purchaseData,
    };


    try {
      axiosWithAuth()
        .post(`${url}${purchase_url}`, data)
        .then((response) => {
          setLoading(false);
          // setPaymentLoading(false);
          if (response?.data?.status === 200) {
            console.log(response.data);
            if(action==='cancel_trial') {
              if(response?.data?.success)
              {
                showAlert('Your trial has been canceled');
              }
            } else  {
              window.location.href = response?.data?.data?._links?.payment.href;

            }
          }
        })
        .catch((error) => {
          setLoading(false);
          // setPaymentLoading(false);
        });
    } catch (e) {
      setLoading(false);
      // setPaymentLoading(false);
    }
  }



  return (
    <div id="TrialPage">
         {/* <h1>Trial Page</h1>
         <ul className='trial-list'>
          <li>
            <button
            onClick={handleStartTrial}
            >Start Trial</button>
          </li>
          <li>
            <button
            onClick={handleSubscription}
            >Start Subscription</button>
          </li>
          <li>
            <button
            onClick={cancelTrial}
            >Cancle Trial</button>
          </li>

         </ul> */}
        {/* <Trial7Dayes /> */}
        </div>
  )
}

export default TrialPage