import React,{useState,useEffect} from 'react';

const FormattedText = ({ text, separator }) => {
  
  const formatText = (text) => {
    const lines = text.split('\n');
    return lines.map((line, index) => {
      // Split the line at the separator
      const parts = line.split(separator);
      // console.log(parts);
      const formattedLine = parts.map((part, partIndex) => (
        partIndex === 1 ? (
          <span key={partIndex} style={{ color: "#9D101E" }}>
            {part}
          </span>
        ) : (
          <span key={partIndex}>{part}</span>
        )
      ));

      return (
        <React.Fragment key={index}>
          
          {formattedLine}
          {line!=="" ? <br /> : ""}
          
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      {formatText(text)}
    </div>
  );
};

export default FormattedText;
