import React,{useEffect} from 'react'
import './firstPricingPage.scss';

import FirstPricing from '../../components/FirstPricing';

const FirstPricingPage = () => {

  // useEffect(() => {
  //     localStorage.setItem('hasLoggedInBefore', 'true');
  // }, []);


  return (
    <div id="FirstPricingPage">
            <FirstPricing />
        </div>
  )
}

export default FirstPricingPage