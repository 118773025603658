import React,{useState,useEffect} from 'react'
import PhoneVerification from './PhoneVerification'
import EmailVerification from './EmailVerification';
import './VerificationPopup.scss';
const VerificationPopup = ({ profileData }) => {
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  // console.log("useeffect verification page : "+profileData?.main?.email);
  const [showVerificationPopup, setshowVerificationPopup] = useState(false);

    // Log the state whenever it updates
  

  useEffect(() => {
    console.log("Initial Updated - Phone:", showPhoneVerification, "Email:", showEmailVerification);
      // Simulating condition to show email verification
      // if (!profileData?.show_email_verification && !profileData?.show_phone_verification) {
      //   setTimeout(() => {
      //     setShowEmailVerification(true); // Simulate state update
      //   }, 1000);
      // }

  }, [profileData]); // Triggered when profileData changes

  useEffect(() => {
    console.log("State Updated - Phone:", showPhoneVerification, "Email:", showEmailVerification);
  }, [showPhoneVerification, showEmailVerification]);
  
  // useEffect(() => {
  //   if (!profileData?.show_phone_verification) {
  //   const phoneVerificationShown = localStorage.getItem('phoneVerificationShown');

  //   console.log("showPhoneVerification old : "+showPhoneVerification);

  //   console.log("showEmailVerification old : "+showEmailVerification);


  //   // setShowPhoneVerification(true);

  //   console.log("showPhoneVerification : "+showPhoneVerification);

  //   console.log("showEmailVerification : "+showEmailVerification);

  //   const now = new Date();
  //   // setShowPhoneVerification(true);
  //   if (!phoneVerificationShown) {
  //     const phonedata = {
  //       startTime: now.getTime(),
  //       show: false,
  //     };
  //     localStorage.setItem('phoneVerificationShown', JSON.stringify(phonedata));
  //     setTimeout(() => {
  //       const phoneupdateddata = {
  //         startTime: now.getTime(),
  //         show: true,
  //       };
  //       localStorage.setItem('phoneVerificationShown', JSON.stringify(phoneupdateddata));
  //       setShowPhoneVerification(true);
  //     }, 5 * 60 * 1000);
  //   } else {
  //     console.log("phone verification popup show ");
  //     const { startTime, show } = JSON.parse(phoneVerificationShown);
  //     const current = new Date();
  //     let elapsed = current.getTime() - parseInt(startTime, 10);
  //     const fiveMinutes = 5 * 60 * 1000; 
  //     if(show) {

  //       setShowPhoneVerification(true);
  //     } else 
  //     if (!show && elapsed < fiveMinutes) {
  //       setTimeout(() => {
  //         const phoneupdateddata = {
  //           startTime: now.getTime(),
  //           show: true,
  //         };
  //         localStorage.setItem('phoneVerificationShown', JSON.stringify(phoneupdateddata));
  //         setShowPhoneVerification(true);
  //       }, fiveMinutes - elapsed);
  //     } else if (!show) {
  //       const phoneupdateddata = {
  //         startTime: now.getTime(),
  //         show: true,
  //       };
  //       localStorage.setItem('phoneVerificationShown', JSON.stringify(phoneupdateddata));
  //       setShowPhoneVerification(true);
  //     } 
  //   }
  //   }
  // }, [profileData?.show_phone_verification]);
  

  // useEffect(()=>{

  //   if (!profileData?.show_email_verification) {
  //     console.log("email popup calling ");
  //     // console.log("profileData?.main?.email verification "+ profileData?.main?.id);
  //     // console.log("profileData : ", JSON.stringify(profileData?.main, null, 2));
  //     setShowEmailVerification(true);

  //     // console.log("email  useEffect verification...."+profileData?.show_email_verification);
  //   // const emailVerificationShown = localStorage.getItem('emailVerificationShown');
  //   // const now = new Date();
  //   // if(!emailVerificationShown) {
  //   //   const emaildata = {
  //   //     startTime: now.getTime(),
  //   //     show: false,
  //   //   };
  //   //   localStorage.setItem('emailVerificationShown', JSON.stringify(emaildata));
  //   //   setTimeout(() => {
  //   //     const emailupdateddata = {
  //   //       startTime: now.getTime(),
  //   //       show: true,
  //   //     };
  //   //     localStorage.setItem('emailVerificationShown', JSON.stringify(emailupdateddata));
  //   //     setShowEmailVerification(true);
  //   //   }, 30 * 60 * 1000);
  //   // } else {
  //   //   console.log("email else verification...."+emailVerificationShown);
  //   //   const { startTime, show } = JSON.parse(emailVerificationShown);
  //   //   const current = new Date();
  //   //   let elapsed = current.getTime() - parseInt(startTime, 10);
  //   //   const fiveMinutes = 30 * 60 * 1000; 
  //   //   console.log("email else current...."+current);
  //   //   console.log("email else elapsed...."+elapsed);
  //   //   console.log("email else fiveMinutes...."+fiveMinutes);
  //   //   if(show) {
  //   //     console.log("email popup calling ");
  //   //     console.log("profileData?.main?.email verification "+ profileData?.main?.id);
  //   //     console.log("profileData : ", JSON.stringify(profileData?.main, null, 2));
  //   //     setShowEmailVerification(true);
  //   //   } else 
  //   //   if (!show && elapsed < fiveMinutes) {
  //   //     setTimeout(() => {
  //   //       const emailupdateddata = {
  //   //         startTime: now.getTime(),
  //   //         show: true,
  //   //       };
  //   //       localStorage.setItem('emailVerificationShown', JSON.stringify(emailupdateddata));
  //   //       setShowEmailVerification(true);
  //   //     }, fiveMinutes - elapsed);
  //   //   } else if (!show) {
  //   //     console.log("email else !show current...."+current);
  //   //     console.log("email else !show elapsed...."+elapsed);
  //   //     console.log("email else !show fiveMinutes...."+fiveMinutes);
  //   //     const emailupdateddata = {
  //   //       startTime: now.getTime(),
  //   //       show: true,
  //   //     };
  //   //     localStorage.setItem('emailVerificationShown', JSON.stringify(emailupdateddata));
  //   //     setShowEmailVerification(true);
  //   //   } 
  //   // }
  // }
  // },[profileData?.show_email_verification])
  


 
  useEffect(() => {
  
  if (!profileData?.show_email_verification) {
   
          // Simulating condition to show email verification
      setTimeout(() => {
        setshowVerificationPopup("email");
      }, 1000); 
    }

  }, [profileData?.show_email_verification]); // Triggered when profileData changes

  useEffect(() => {

  if (!profileData?.show_phone_verification ) {
  
      // Simulating condition to show email verification
      setTimeout(() => {
        setshowVerificationPopup("mobile");
      }, 1000); 
    }
  }, [profileData?.show_phone_verification]); // Triggered when profileData changes

 
  let verificationComponent;
  if (showVerificationPopup==="email" ) {
    console.log("showVerificationPopup  : "+showVerificationPopup);
    // When email verification is shown but not phone verification
    verificationComponent = <EmailVerification profileData={profileData} />;
  } else if (showVerificationPopup==="mobile" ) {
    // When phone verification is shown but not email verification
    verificationComponent = <PhoneVerification profileData={profileData} />;
  }

  


  return (
    <>
    <div id='verification-popup'>  
{/* mk start end code */}


{verificationComponent}


{/* mk new end code */}
{/* old code */}
        {/* {showEmailVerification && !showPhoneVerification && (
          <EmailVerification profileData={profileData} />
        )}
        {showPhoneVerification && !showEmailVerification && <PhoneVerification profileData={profileData} />}
    */}
   
   
    </div>
     </>
  )
}

export default VerificationPopup