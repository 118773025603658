import i18n from '../i18n';
import { initReactI18next } from 'react-i18next';

const API_BASE_URL = process.env.REACT_APP_TRANSLATION;


//  const API_BASE_URL = '/locales/';

export const saveLanguageToLocalStorage = (language) => {
  localStorage.setItem('selectedLanguage', language);
};

export const getLanguageFromLocalStorage = () => {
  return localStorage.getItem('selectedLanguage') || 'en';
};

export const savePageTranslateLocalStorage = (page) => {
  localStorage.setItem('pageTranslate', page);
};

export const getPageTranslateLocalStorage = () => {
  return localStorage.getItem('pageTranslate') || 'auth';
};


export const loadCities = async (country_code) => {
  const englishCitiesList = `${API_BASE_URL}locales/en/cities/${country_code}`;
  const hindiCitiesList = `${API_BASE_URL}locales/in/cities/${country_code}`;
    
  try {
    const [enCities, hiCities] = await Promise.all([
      fetchJsonWithOutStore(englishCitiesList),
      fetchJsonWithOutStore(hindiCitiesList)
    ]);
    i18n.addResourceBundle('en', 'translation', enCities, true, false);
    i18n.addResourceBundle('in', 'translation', hiCities, true, false);

  } catch (error) {
    console.error('Error loading cities:', error);
  }
};

const saveTranslationToLocalStorage = (language, key, translation) => {
  const storageKey = `translation_${language}_${key}`;
  localStorage.setItem(storageKey, JSON.stringify(translation));
};

const getTranslationFromLocalStorage = (language, key) => {
  const storageKey = `translation_${language}_${key}`;
  const storedTranslation = localStorage.getItem(storageKey);
  return storedTranslation ? JSON.parse(storedTranslation) : null;
};
const fetchJSON = async (url, key, language) => {
  const storedTranslation = getTranslationFromLocalStorage(language, key);
  if (storedTranslation) {
    return storedTranslation;
  }

  try {

    
    const response = await fetch(url);
 
    // const response = await axios.get(url);

    if (!response.ok) {
      console.error('Failed to fetch data');
    }

    const translation = await response.json();
    saveTranslationToLocalStorage(language, key, translation);
    return translation;
  } catch (error) {
    console.error('Error:', error);
   // throw error; 
  }
};

const fetchJsonWithOutStore = async (url) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      console.error('Failed to fetch data');
    }

    return response.json();
  } catch (error) {
    console.error('Error:', error);
    //  console.log(error); 
  }
};

const isTranslationFileAvailable = async (language, page) => {
  const translationFileUrl = `${API_BASE_URL}locales/${language}/${page}`;
  
  try {
    const response = await fetch(translationFileUrl);
    return response.ok;
  } catch (error) {
    console.error('Error checking translation file availability:', error);
    return false;
  }
};


const loadTranslation = async (language, page, loadOthers = [],localTranlation=false) => {
  const commonTranslationUrl = `${API_BASE_URL}locales/${language}/global`;
  const pageTranslationUrl = `${API_BASE_URL}locales/${language}/${page}`;
  // if(localTranlation) {

  // }
  const commonTranslation = fetchJSON(commonTranslationUrl, 'global', language);
  const pageTranslation = fetchJSON(pageTranslationUrl, page, language);

  const otherTranslations = loadOthers.map(other => {
    const otherUrl = `${API_BASE_URL}locales/${language}/${other}`;
    return fetchJSON(otherUrl, other, language);
  });

  const allTranslations = await Promise.all([commonTranslation, pageTranslation, ...otherTranslations]);
  return allTranslations.reduce((acc, translations) => ({ ...acc, ...translations }), {});
};

export async function initializeI18n(loadOthers = []) {
  console.log('log')
  const selectedLanguage = getLanguageFromLocalStorage();
  const pageTranslate = getPageTranslateLocalStorage();

  // if()

  try {

    cleanUpUnusedTranslationsOnPageChange(pageTranslate, loadOthers);

    const enTranslations = loadTranslation('en', pageTranslate, loadOthers);
    const inTranslations = loadTranslation('in', pageTranslate, loadOthers);

    const [translationsEN, translationsIN] = await Promise.all([enTranslations, inTranslations]);

    i18n
      .use(initReactI18next)
      .init({
        resources: {
          en: { translation: translationsEN },
          in: { translation: translationsIN },
        },
        lng: selectedLanguage,
        fallbackLng: 'en',
      });

    i18n.on('languageChanged', (newLang) => {
      saveLanguageToLocalStorage(newLang);
    });

  } catch (error) {
    console.error('Error during initialization:', error);
    //throw error;
  }
}



export const cleanUpUnusedTranslationsOnPageChange = (currentPage, loadOthers = []) => {
  const keysToKeepPattern = [
    '_global',
    `_${currentPage}`,
    ...loadOthers.map(other => `_${other}`)
  ];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith('translation_')) {
      const isKeyToKeep = keysToKeepPattern.some(pattern => key.includes(pattern));

      if (!isKeyToKeep) {
        localStorage.removeItem(key);
      }
    }
  }
};

