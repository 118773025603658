export function shiftToDesktop() {
  shiftObject("top-main", "user-name-tag", "prepend");
  shiftObject("location-tag-wrap", "location-tag");
  shiftObject("section-end", "likers-wrap");
  shiftObject("section-end", "viewers-wrap");
  shiftObject("mobile-left-bar", "user-tags");
  shiftObject("user-photos-wrap", "user-photos");
  shiftObject("self-tab", "editProfile");
  shiftObject("chat-wrap", "chatbox");

  if (document.getElementById("primary-gallery")) {
    document.getElementById("primary-gallery").style.display = "block";
  }

  if (document.getElementById("profile-gallery")) {
    document.getElementById("profile-gallery").style.display = "flex";
  }
}

export function shiftToMobile() {
  // console.log('sfift-to-mobile')
  shiftObject("user-name-tag-mobile-wrap", "user-name-tag", true);
  shiftObject("user-attributes", "location-tag", true);
  shiftObject("mobile-left-bar", "likers-wrap");
  shiftObject("mobile-left-bar", "viewers-wrap");
  shiftObject("mid-section", "user-tags");
  shiftObject("mobile-left-bar", "user-photos", true);
  shiftObject("mobile-left-bar", "editProfile", true);
  shiftObject("midColumn", "chatbox");

  if (document.getElementById("primary-gallery")) {
    document.getElementById("primary-gallery").style.display = "none";
  }

  if (document.getElementById("profile-gallery")) {
    document.getElementById("profile-gallery").style.display = "block";
  }
}

export function shiftObject(target, element, prepend = false) {
  if (document.getElementById(target) && document.getElementById(element)) {
    if (!prepend) {
      // console.log(document.getElementById(target))
      document
        .getElementById(target)
        .appendChild(document.getElementById(element));
        // 
        const newd =document.getElementById('mid-section')
        if(target === 'mid-section'){
          const newdata =document.getElementById('mid-section')
  
          // console.log(newd)
          // console.log(newdata)
          // document.getElementById(target).remove()
          // while (newdata.firstChild) {
          //   newdata.removeChild(newdata.firstChild);
          // }
          //   console.log(newdata)
          //  document.getElementById('profile-page').appendChild(newdata);
        }
        // console.log(newd)
        // document.getElementById(target).prepend(document.getElementById(element));
    }
  
    else
      document
        .getElementById(target)
        .insertBefore(
          document.getElementById(element),
          document.getElementById(target).firstChild
        );
  }
}

var viewMode = "desktop";

export function checkViewSize() {
  // console.log('vieeeeewwwww')

  if (window.innerWidth <= 767) {
    viewMode = "mobile";
    shiftToMobile();

    return viewMode;
  } else if (window.innerWidth > 767) {
    shiftToDesktop();
    viewMode = "desktop";

    return viewMode;
  }
}
