import React,{useState,useEffect} from 'react'
import FeaturesCarousel from './FeaturesCarousel'
import { useSelector, useDispatch } from "react-redux";
import axiosWithAuth from "../../app/auth/jwtMiddleware";
import { showAlert } from "../../components/showAlerts";

import PricingCard from './PricingCard'

const url = process.env.REACT_APP_PUBLIC_URL;

const FirstPricing = () => {
  const [activeCard, setActiveCard] = useState(2);
  const [activePlan, setActiveActive] = useState(6);
  const [pricing,setPricing] = useState();
  const [currency,setCurrency] = useState();
  const [loading, setLoading] = useState(true);

  const { profileData } = useSelector((state) => state.profile);
  
  useEffect(()=>{
     getPricing();
  },[])
  
  function getPricing() {
  
    var data = {
      user: profileData?.main?.id,
      country: profileData?.profile?.location?.country?.slug,
    };
    setLoading(true);
    try {
      axiosWithAuth()
        .post(`${url}/premium/get`, data)
        .then((response) => {
          let thissubscriptions = response?.data?.data?.subscriptions;
          setCurrency(thissubscriptions?.currency);
          setPricing(thissubscriptions);
          setLoading(false);
        })
        .catch((error) => {
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          } else {
            showAlert("We have encountered an error");
          }
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      showAlert("We have encountered an error");
    }
  }



  function makePurchase(e) {
    e.preventDefault();
    setLoading(true);
    var purchaseData = [];
    let addlength = 0;
    let sublenth = 0;



      let det = {
        type: "subscription",
        slug: "premium",
        length: activePlan,
      };
      purchaseData.push(det);
    

    var data = {
      user_id: profileData?.main?.id,
      currency: currency,
      items: purchaseData,
    };

    console.log(purchaseData);
    console.log("current url  "+ url );
    try {
      axiosWithAuth()
        .post(`${url}/payments/create-order`, data) 
        .then((response) => {
          setLoading(false);
          // setPaymentLoading(false);
          if (response?.data?.status === 200) {
            window.location.href = response?.data?.data?._links?.payment.href;
          }
        })
        .catch((error) => {
          setLoading(false);
          // setPaymentLoading(false);
        });
    } catch (e) {
      setLoading(false);
      // setPaymentLoading(false);
    }
  }



  const handleActiveCard = (cardNumber,plan_number) => {
    setActiveCard(cardNumber);
    setActiveActive(plan_number);
  }
  return (
    <>
      {loading && (<div className="loader"></div>)}
      <FeaturesCarousel />
      {!loading && <> 
      
        <div className={`  pricing-cards-area pricing-page-container`}>
        <div
          className={`pricing-card-1  ${activeCard === 1 && "active-card"}`}
          onClick={() => handleActiveCard(1, 12)}
        >
      
          <PricingCard
            title="BEST VALUE"
            months="12"
            price="AED 42 / mth"
            discount="SAVE 62%"
            total_price={pricing?.annual_price}
            currency={currency}
          />
        </div>
        <div
          className={`pricing-card-2 ${activeCard === 2 && "active-card"}`}
          onClick={() => handleActiveCard(2, 6)}
        >
          <PricingCard
            title="MOST POPULAR"
            months="6"
            price="AED 50 / mth"
            discount="SAVE 62%"
            total_price={pricing?.biannual_price}
            currency={currency}
          />
        </div>
        <div
          className={`pricing-card-3  ${activeCard === 3 && "active-card"}`}
          onClick={() => handleActiveCard(3, 1)}
        >
          <PricingCard
            title="BEST VALUE"
            months="1"
            price="AED 79 / mth"
            discount=""
            total_price={pricing?.monthly_price}
            currency={currency}
          />
        </div>
      </div>
      <div className="packages-button-area pricing-page-container">
        <button
          onClick={(e) => makePurchase(e)}
          className="pricing-cards-area__btn"
        >
     Notify Me!
        </button>
      </div>
      
      
      </>}

      {/* <div className='do-it-later-mobile-link'>
    <a href={`/`}>
                <span className='do-this-later-test'>
                 Do this later
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                    <path d="M11.8535 4.35355C12.0488 4.15829 12.0488 3.84171 11.8535 3.64644L8.67156 0.464464C8.4763 0.269202 8.15972 0.269202 7.96446 0.464465C7.7692 0.659727 7.7692 0.976309 7.96446 1.17157L10.7929 4L7.96446 6.82843C7.7692 7.02369 7.7692 7.34027 7.96446 7.53553C8.15972 7.73079 8.4763 7.73079 8.67157 7.53553L11.8535 4.35355ZM9.12344e-08 4.5L11.5 4.5L11.5 3.5L-9.12344e-08 3.5L9.12344e-08 4.5Z" fill="#B6B6B6"/>
                  </svg>                  
                </a>
    </div> */}
    </>
  );
}

export default FirstPricing