
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { startTransition } from 'react';

startTransition(() => {
i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3', 
    react: { 
      useSuspense: false 
    }
  });

});

  
export default i18n;
