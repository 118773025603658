import React,{useState,useCallback} from 'react'
import Cropper from 'react-easy-crop'

const ImageCropper = ({
  imgSrc,
  uploadImage,
  registrationCropper
}
) => { 
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)


  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  const showCroppedImage = useCallback(async () => {

    try {

      const croppedImage = await getCroppedImg(
        imgSrc,
        croppedAreaPixels,
      )
      uploadImage(croppedImage)
    } catch (e) {
      console.error(e)
      return false;
    }
    return true;
  }, [croppedAreaPixels])

  return (
    <>
     <div className="image-cropper-area">
     <Cropper
      initialAspectRatio={1}
      aspectRatio={1 / 1}
      image={imgSrc}
      crop={crop}
      zoom={zoom}
      aspect={1 / 1}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
      guides={true}
    />
</div>
<div className="controls">
        <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          className="zoom-range"
        />
      </div>
      {!registrationCropper ? (
        <button
                type="button"
                className="main-bt mt-20"
                id="crop"
                onClick={showCroppedImage}
              >
                Save
              </button>
      ) : (
        <button
        type="button"
        onClick={showCroppedImage}
        className="mt-3 nav-bt red float-end mb-2 back"
      >
        Crop
      </button> 
      )}

    </>
  )
}

export default ImageCropper

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  targetWidth = 600, // desired width
  targetHeight = 600, // desired height
  maxWidth = 2000, // maximum width
  maxHeight = 2000 // maximum height
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // Calculate the aspect ratio of the cropped area and the target canvas
  const cropAspectRatio = pixelCrop.width / pixelCrop.height;
  const canvasAspectRatio = targetWidth / targetHeight;

  // Adjust canvas size to maintain aspect ratio within max dimensions
  let finalWidth = targetWidth;
  let finalHeight = targetHeight;

  if (canvasAspectRatio > cropAspectRatio) {
    finalWidth = Math.min(targetHeight * cropAspectRatio, maxWidth);
    finalHeight = finalWidth / cropAspectRatio;
  } else {
    finalHeight = Math.min(targetWidth / cropAspectRatio, maxHeight);
    finalWidth = finalHeight * cropAspectRatio;
  }

  // Adjust dimensions if they exceed max values
  if (finalWidth > maxWidth) {
    finalWidth = maxWidth;
    finalHeight = finalWidth / cropAspectRatio;
  }
  if (finalHeight > maxHeight) {
    finalHeight = maxHeight;
    finalWidth = finalHeight * cropAspectRatio;
  }

  canvas.width = finalWidth;
  canvas.height = finalHeight;

  // Draw the cropped image to the canvas
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    finalWidth,
    finalHeight
  );

  // Return the canvas as a Base64 string
  return canvas.toDataURL('image/jpeg');
}

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })