import React, { useEffect, useState } from "react";
import { updatedBioAttributes } from "../../app/actions/Profile";
import { useTranslation } from 'react-i18next';
// import { useSelector, useDispatch } from "react-redux";

export default function ProfileBio({ dataValue, textChanged }) {
  // console.log(data?.data?.profile)

  const { t } = useTranslation();
  const [profilefilled, setprofilefilled] = useState(false)

  // const dispatch = useDispatch();
  // 
  const data = dataValue;
  //   console.log(data);

  const [bio, setBio] = useState();
  const [search, setSearch] = useState();

  const handleBio = (value) => {
    setBio(value);
  };



  useEffect(() => {
    if(data?.profile?.bio &&  data?.profile?.search_bio){

      if(profilefilled){
        return 
      }
      setBio(data?.profile?.bio)
      setSearch(data?.profile?.search_bio)
      setprofilefilled(true)
    }

  

  }, [data?.profile?.bio , data?.profile?.search_bio])
  

  const handleSearch = (value) => {
    setSearch(value);
    // formData.append("search_bio", value);
  };

  useEffect(() => {
    var data = {
      bio: bio,
      search_bio: search,
    };
    textChanged(data);
  }, [bio, search]);

  return (
    <>
      <div className="copy-wrap">
        <span className="heading" style={{ marginBottom: "10px!important" }}>{t(['about-me-label',''])}</span>
        <div
          className="setdata"
          id="profile-bio"
          dangerouslySetInnerHTML={{ __html: bio }}
        ></div>
        <div className="edit-field">
          <textarea
            name="bio"
            id="edit-bio"
            onChange={(e) => handleBio(e.target.value)}
            defaultValue={bio}
          >
      
          </textarea>
        </div>
      </div>
      <span className="divider mt-30 mb-30"></span>
      <div className="copy-wrap">
        <span className="heading" style={{ marginBottom: "10px!important" }} >{t("search-label")}</span>
        <div
          className="setdata"
          id="profile-search_bio"
          dangerouslySetInnerHTML={{ __html: search }}
        ></div>
        <div className="edit-field">
          <textarea
            name="search_bio"
            id="edit-search_bio"
            onChange={(e) => handleSearch(e.target.value)}
            defaultValue={search}
          >
  
          </textarea>
        </div>
      </div>
      <span className="divider mt-30 mb-30"></span>
    </>
  );
}
