

var alertTimeout = null;
export function showAlert(message, type = null){
    clearTimeout(alertTimeout);

    if(document.getElementById('globalAlert')){
        document.getElementById('globalAlert').classList.add('active');
        document.getElementById('globalAlert').innerHTML = message;
    
     alertTimeout = setTimeout(() => {
            if(document.querySelector('.global-alert'))
                document.querySelector('.global-alert').classList.remove('active');
        }, 5000);
    }

}

var alertTipTimeout = null;
export function showToolTip(message, type = null){
    clearTimeout(alertTipTimeout);

    document.getElementById('tooltipAlert').classList.add('active');
    document.getElementById('tooltipAlert').innerHTML = message;

    alertTipTimeout = setTimeout(() => {
        if(document.querySelector('.tooltip-alert'))
            document.querySelector('.tooltip-alert').classList.remove('active');
    }, 5000);
}