import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
//

export default function ProfileAttrributeView(data) {
  const { profileData } = useSelector((state) => state.profile);

  const { t } = useTranslation();
  return (
    <>
      <div className="user-attributes" id="user-attributes">
        <span className="attribute religion">
          <label>{t(["religion", ""])}</label>{" "}
          <div className="setdata" id="profile-religion">
            {data?.data?.religion?.name ? (
              <>
                {data?.data?.religion?.slug && data?.data?.religion?.name
                  ? t([data?.data?.religion?.slug, ""])
                  : data?.data?.religion?.name}
              </>
            ) : (
              <>
                <div className="none"> {t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
        </span>
        {profileData?.is_premium && (
        <span className="attribute caste">
          <label>{t(["community"])}</label>{" "}

          {profileData?.is_premium ? (<> 
          
            <div className="setdata" id="profile-caste">
            {data?.data?.caste?.name ? (
              <>{t([data?.data?.caste?.slug])}</>
            ) : (
              <>
                <div className="none"> {t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
          
          </>):(<>
            <div className="d-flex">
                <Link to="/premium" className="premium-only">
                  {t(["upgrade-label", ""])}
                </Link>
              </div>
          
          </>)}

        </span> )}
        {profileData?.is_premium && (
        <span className="attribute language">
          <label>{t(["language", ""])}</label>
          <div className="setdata" id="profile-language">

          {profileData?.is_premium ? (<>
          
            {data?.data?.language ? (
              <>
                {Object.entries(data?.data?.language).map(([id, type]) => (
                  <span key={"profile-language-" + id} className="tag">
                    {type?.slug ? t([type?.slug, ""]) : type?.name}
                  </span>
                ))}
                
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          
          </>):(<>
          
            <div className="d-flex">
                <Link to="/premium" className="premium-only">
                  {t(["upgrade-label", ""])}
                </Link>
              </div>
          
          </>)}

          </div>
        </span>  )}
        <span className="attribute height">
          <label>{t(["height", ""])}</label>
          <div className="setdata" id="profile-height">
            {data?.data?.height?.name ? (
              <> {data?.data?.height?.name}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
        </span>

        <span className="attribute career">
          <label>{t(["career", ""])}</label>
          
            <div className="setdata" id="profile-career">
              {data?.data?.career?.name ? (
                <>{t([data?.data?.career?.slug, ""])}</>
              ) : (
                <>
                  <div className="none">{t(["not-specified-label", ""])}</div>
                </>
              )}
            </div>

        </span>
        {profileData?.is_premium && (
        <span className="attribute education">
          <label>{t(["education", ""])}</label>
          
          {profileData?.is_premium ? (<>

            <div className="setdata" id="profile-education">
            {data?.data?.education?.name ? (
              <>{t([data?.data?.education?.slug, ""])}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>

            </>):(<>
              <div className="d-flex">
                <Link to="/premium" className="premium-only">
                  {t(["upgrade-label", ""])}
                </Link>
              </div>
            </>)}

        </span> )}
        {/* <span className="attribute citizenship">
          <label>{t(['citizenship',''])}</label>
          <div className="setdata" id="profile-citizenship">
            {data?.data?.citizenship?.name ? (
              <>{t([data?.data?.citizenship?.slug,''])}</>
            ) : (
              <>
                <div className="none">{t(['not-specified-label',''])}</div>
              </>
            )}
          </div>
        </span> */}
        {/* <span className="attribute relocate">
          <label>{t(['open-to-relocate',''])}</label>
          <div className="setdata" id="profile-relocate">
            {data?.data?.relocate?.name ? (
              <> {t([data?.data?.relocate?.slug,''])} </>
            ) : (
              <>
                <div className="none">{t(['not-specified-label',''])}</div>
              </>
            )}
          </div>
        </span> */}
        <span className="attribute family_type">
          <label>{t(["family-type", ""])}</label>
          <div className="setdata" id="profile-family_type">
            {data?.data?.family_type?.name ? (
              <>{t([data?.data?.family_type?.slug, ""])}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
        </span>
        <span className="attribute family_value">
          <label>{t(["family-values", ""])}</label>
          <div className="setdata" id="profile-family_value">
            {data?.data?.family_value?.name ? (
              <>{t([data?.data?.family_value?.slug, ""])}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
        </span>
        <span className="attribute marital_status">
          <label>{t(["marital-status", ""])}</label>
          <div className="setdata" id="profile-marital_status">
            {data?.data?.marital_status?.name ? (
              <>{t([data?.data?.marital_status?.slug, ""])}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
        </span>
        <span className="attribute diet">
          <label>{t(["diet", ""])}</label>
          <div className="setdata" id="profile-diet">
            {data?.data?.diet?.name ? (
              <>{t([data?.data?.diet?.slug, ""])}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
        </span>
        <span className="attribute children">
          <label>{t(["children", ""])}</label>
          <div className="setdata" id="profile-children">
            {data?.data?.children?.name ? (
              <>{t([data?.data?.children?.slug, ""])}</>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
        </span>
        {/* <span className="attribute school">
          <label>{t(['school',''])}</label>
          <div className="setdata" id="profile-school">
            {data?.data?.school?.name ? (
              <>{t([data?.data?.school?.slug,''])}</>
            ) : (
              <>
                <div className="none">{t(['not-specified-label',''])}</div>
              </>
            )}
          </div>
        </span> */}
      </div>
      <div className="user-tags" id="user-tags">
        <span className="heading">{t(["hobbies-label", ""])}</span>

        {profileData?.is_premium ? (<>
        
          <span className="tags">
          <div className="setdata tagwrap" id="profile-hobbies">
            {data?.data?.hobbies ? (
              <>
                {Object.entries(data?.data?.hobbies).map(([id, hobby]) => (
                  <span
                    data-slug={hobby.slug}
                    className="tag"
                    key={"hobby-" + id}
                    value={hobby.slug}
                  >
                    <div>
                      {hobby?.slug ? t([hobby?.slug, ""]) : hobby?.name}
                    </div>
                  </span>
                ))}
              </>
            ) : (
              <>
                <div className="none">{t(["not-specified-label", ""])}</div>
              </>
            )}
          </div>
        </span>

        </>) : (<>
        
          <div className="d-flex">
                <Link to="/premium" className="premium-only">
                  {t(["upgrade-label", ""])}
                </Link>
              </div>
        </>)}

      </div>
    </>
  );
}
