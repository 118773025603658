import axios from 'axios';
import { useSelector,useDispatch } from "react-redux";
import {
  toggleNotification,
  toggleSetting,
  initUserData,
  initTokenData,
} from "../../app/actions/Global";

import { LogOut } from '../utilities/functions';

const axiosWithAuth = () => {

  // const dispatch = useDispatch();
  const instance = axios.create();


  // Request interceptor
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('authtoken');
    const appversion = localStorage.getItem('app_version')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers.Accept ="*"
      // config.headers['Content-Type'] = 'application/json'
      // config.headers['Access-Control-Allow-Origin'] = "*"
    }


    // console.log(config.data)
    // console.log(appversion)

    if (config.data && appversion) {
      // console.log('config data');
      if (config.data instanceof FormData) {
        // Handle FormData
        //  console.log('if config data');
        config.data.delete('app_version');
        config.data.append('app_version', appversion); 
      } else {
      // console.log('esle config data');

        // Handle JSON object
        config.data.app_version = appversion;
      }
    }
    return config;
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if(error?.response?.data?.code===401){
        // LogOut()

        localStorage.removeItem("authtoken");    
          
        localStorage.removeItem('persist:root')
        window.location.href = '/login';
        localStorage.removeItem('floatingMessageAdd')

        // console.log(401)
//         window.location.href = '/login';
//             localStorage.removeItem("authtoken");    
//             localStorage.removeItem('persist:root')
        // LogOut()
        // localStorage.removeItem("authtoken")
        // dispatch(initTokenData([]));
        // dispatch(initUserData([]));
        //  window.location.reload();
        // console.log('log out')
      }
      else{
        return Promise.reject(error)
      }
    });

  //

  return instance;
};

export default axiosWithAuth;