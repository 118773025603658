import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import imagePlacholder from "../../assets/images/Main/user-placeholder.jpg"
import { Link } from "react-router-dom";

export default function Bottombar(){
    const { profileData } = useSelector((state) => state.profile);
    const { notificationsCounter} = useSelector((state) => state.notifications);

    const handleImageError = (e) => {
        // Replace the failed image with a placeholder image
        e.target.src = imagePlacholder; // Replace with the path to your placeholder image
       
      };

    const location = useLocation();

    const [url, setUrl]= useState('home')



      useEffect(() => {
        if(location.pathname === "/"){
            setUrl('home');
  
        }
        // if(location.pathname === "/premium"){
        //     setUrl('premium');
         
        // }

   
        if(location.pathname.includes('messages')){
            setUrl('messages');
            // console.log('message')
        
        }
        if(location.pathname === "/profile"){
            setUrl('profile');
      
        }
        if(location.pathname === "/blog"){
            setUrl('blog');
      
        }

        if(location.pathname === "/premium-plan"){
          setUrl('premium');
    
      }


        // console.log(url)
      }, [location])
      

    return(
        <>
        <div id="bottombar" style={{ paddingTop: '5px' }}>
        <Link to={'/'}  className={`${url==='home'?'active ':' '}  home`}>
            <span className="lable-bottom-bar">Home</span>
            </Link>
            <Link to={'/blog'}   className={`${url==='blog'?'active ':' '} blog`}><span className="lable-bottom-bar">Blog</span></Link>
           
             <Link to={'/premium-plan'} className={`${url==='premium'?'active ':' '} premium  test-${url}`}><span className="lable-bottom-bar">Premium</span></Link>
          

            {/* <Link
              to="/premium-plan/"
              className={`${
                url === "premium-plan" ? "active " : " "
              }  bottom_nav_premium   cus_premium  premium`} style={{textDecoration:"none"}} 
            >
             
              <span className="lable-bottom-bar"  >Premium</span>
            </Link> */}
          
           
            <Link to={'/messages'}  className={`${url==='messages'?'active ':' '} message`}>                  
            {notificationsCounter?.unread_messages > 0 ? (
                <>
                  <div className="count-bubble-mobile">
                    {notificationsCounter?.unread_messages}
                  </div>
                </>
              ) : (
                ""
              )}
              <span className="lable-bottom-bar">Messages</span>
              </Link>
            <Link to={'/profile'}   className={`${url==='profile'?'active ':''} ${profileData?.is_premium ? "premium" : ""} user user-photo user-bottom-bar`}>
            <img src={profileData?.photo? (profileData?.photo):(imagePlacholder)} alt={'logo'}
             onError={handleImageError}/>
            <span className="lable-bottom-bar">Profile</span>
            </Link>

          
           

</div>
        </>
    )
}