import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setfrozenPopUp } from "../../app/actions/Notifications";
import Cropper from "react-cropper";
import AxiosWithAuth from "../../app/auth/jwtMiddleware";
import { showAlert } from "../showAlerts";
import { getLanguageFromLocalStorage } from "../../app/utilities/localization";
import { getProfileData } from "../../app/actions/Profile";
import ImageCropper from "../ImageCropper";
import "./frozen.scss";

const url = process.env.REACT_APP_PUBLIC_URL;

export default function ProfileFrozen() {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [profilefrozen, setProfileFozen] = useState(false);
  const [profileMessagfrozen, setprofileMessagfrozen] = useState(false);
  const [profilefrozendata, setProfileFozenData] = useState();
  const { profileData } = useSelector((state) => state.profile);
  const { icCompletePopUp } = useSelector((state) => state.notifications);
  const [cropimage, setCropImage] = useState("");
  const [orignalImage, setOrignalImage] = useState("");
  const [cropper, setCropper] = useState();
  const { citiesData } = useSelector((state) => state.global);
  const [cropData, setCropData] = useState("#");
  const [loading, setLoading] = useState(false);
  const [isBrowser, setIsBrowser] = useState(true);

  const [imgSrc, setImgSrc] = useState("");

  const [status, setStatus] = useState("");

  const language = getLanguageFromLocalStorage() || "en";

  useEffect(() => {
    setProfileFozen(false);
    console.log()
    dispatch(setfrozenPopUp(false));
    if (location?.pathname !== "/profile") {
      if (profileData) {
        if (profileData?.profile?.is_complete) {
          // console.log(profileData);
          if (profileData?.is_frozen) {
            setProfileFozen(true);
            setProfileFozenData(profileData?.is_frozen);
            dispatch(setfrozenPopUp(true));
            setStatus(profileData?.is_frozen?.status);


          }
        } else {
          setProfileFozen(false);
          dispatch(setfrozenPopUp(false));
        }

        return;
      }
    } else {
      setProfileFozen(false);
      return;
      // console.log("profile");
    }

    return () => {
      dispatch(setfrozenPopUp(false));
    };

  }, [profileData, location]);

  //   console.log(icCompletePopUp)

  const toProfile = (e) => {
    e.preventDefault();
    dispatch(setfrozenPopUp(false));
    window.location.href = "/profile";
  };

  const handleCloseCropper = (e) => {
    e.preventDefault();
    document.getElementById("cropModalFrozen").classList.remove("active");
  };

  const cropImage = () => {
    var imagedata = "";

    if (typeof cropper !== "undefined") {
      // console.log(cropper.getCroppedCanvas().toDataURL());
      imagedata = cropper
        .getCroppedCanvas({
          width: 600,
          height: 600,
          // Limit max sizes
          maxWidth: 2000,
          maxHeight: 2000,
        })
        .toDataURL();
      setCropData(
        cropper
          .getCroppedCanvas({
            width: 600,
            height: 600,
            // Limit max sizes
            maxWidth: 2000,
            maxHeight: 2000,
          })
          .toDataURL()
      );
    }
    // console.log("This is the Croped Image", imagedata);
    if (imagedata !== "#" || imagedata !== "") {
      uploadImage(imagedata);
    }
  };

  function uploadImage(cropdata) {
    var image = {
      image: cropdata,
      original_photo : orignalImage,
      user_id: profileData?.main?.id,
    };
    setLoading(true);
    try {
      AxiosWithAuth()
        .post(`${url}/profiles/update-photo`, image)
        .then((data) => {
          // console.log(data);
          setLoading(false);
          if (data?.status === 200) {
            showAlert(data?.data?.message);
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);

            formData.append("language", language);

            dispatch(getProfileData(formData));
          } else {
            console.log("error");
          }

          //
          document.getElementById("cropModalFrozen").classList.remove("active");
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  function uploadImageDirect(e) {
    e.preventDefault();
    if (!e.target.files) {
      return false;
    }

    var fd = new FormData();
    fd.append("photo", e.target.files[0]);
    fd.append("user_id", profileData?.main?.id);

    setLoading(true);

    var headers = {
      contentType: false,
      processData: false,
    };

    try {
      AxiosWithAuth()
        .post(`${url}/profiles/update-photo-direct`, fd, headers)
        .then((data) => {
          // console.log(data);
          setLoading(false);

          if (data?.status === 200) {
            showAlert(data?.data?.message);
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);

            formData.append("language", language);

            dispatch(getProfileData(formData));
          } else {
            console.log("error");
          }
          document.getElementById("directPhoto").value();
          //
          document.getElementById("cropModal").classList.remove("active");
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  }

  const imageSelect = (e) => {
    // console.log(e.target.files);
    var files = e.target.files;

    var reader;
    var file;
    var url;
    if (
      navigator.userAgent.includes("FB_IAB") ||
        navigator.userAgent.includes("FB4A") ||
        navigator.userAgent.includes("FBAV") ||
        navigator.userAgent.includes("Instagram") ||
        navigator.userAgent.includes("instagram") ||
        navigator.userAgent.includes("Android") 
    ) {
      setIsBrowser(false);

    }
    var done = function (url) {
      //  image.src = url;
      setCropImage(url);
      // console.log(url);
      //   // $modal.modal('show');
      document.getElementById("cropModalFrozen").classList.add("active");
    };

    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result);
        setOrignalImage(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }

    if (files && files.length > 0) {
      file = files[0];

      if (URL) {
        done(URL.createObjectURL(file));
      } else if (FileReader) {
        reader = new FileReader();
        reader.onload = function (e) {
          done(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // console.log(status)
  return (
    <>
      {loading ? <div className="loader"></div> : <></>}
      <div
        id="frozen-popup"
        className={`popup-main report big has-icon ${
          profilefrozen && icCompletePopUp ? "active" : ""
        }`}
      >
        <div className="wrap text-center">
          <span className="heading">{profilefrozendata?.title} </span>

          <span className="subheading mt-30 mb-20">
            {/* {t(["profile-popup-details", ""])} */}
            <p>{profilefrozendata?.message} </p>
          </span>

          <div id="cropModalFrozen" className="popup-main"  style={{ display: profileData?.is_frozen?.type === "Other" ? 'none' : ''}} >
            <div className="wrapper">
              <button
                className="close"
                onClick={(e) => handleCloseCropper(e)}
              ></button>

              <div className="cropwrapper">
              {!isBrowser ? (
                <>
                <ImageCropper 
                  imgSrc ={imgSrc}
                  uploadImage ={uploadImage}
                  registrationCropper = {false}
                  />
                </>
              ) : (
                <>
                <Cropper
                  zoomable={false}
                  initialAspectRatio={1}
                  scaleX={1}
                  scaleY={1}
                  aspectRatio={1 / 1}
                  preview=".preview"
                  src={cropimage}
                  viewMode={3}
                  minCropBoxWidth={100}
                  checkOrientation={false}
                  guides={true}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                />
                <button
                  type="button"
                  className="main-bt mt-20"
                  id="crop"
                  onClick={cropImage}
                >
                  {t(["save", "Save"])}
                </button>
                </>
              )}
              </div>
            </div>
          </div>

          {/* <button
            type="button"
            className="main-bt mt-20"
            id="crop"
            onClick={cropImage}
          >
            {t(["save", ""])}
          </button> */}

          {status === "pending" ? (
            <>
              <div className={`add-photo profile-photo`} style={{ display: profileData?.is_frozen?.type === "Other" ? 'none' : ''}}>
                <div id="addPhoto">
                  <input
                    type="file"
                    name="photo"
                    className={`form-control profile-photo`}
                    accept="image/*"
                    id="profile-photo"
                    onChange={imageSelect}
                  />

                  <button className="">
                    + {t(["add-photo", "Add a Photo"])}
                  </button>
                </div>
              </div>

              <div id="directPhotoWrap" style={{ display: profileData?.is_frozen?.type === "Other" ? 'none' : ''}}>
                <input
                  type="file"
                  id="directPhoto"
                  accept=".jpg, .jpeg, .png"
                  max
                  onChange={uploadImageDirect}
                />
            
                  {language==="en"? (<>
                  
                    <p>
                   Having trouble cropping your image? <br />
                  <a href="#">
                    {" "}
                    Click here to upload your photo directly without manually
                    cropping.
                  </a>{" "}
                  <br />
                  <span>
                    (Your photo will be cropped and resized automatically.)
                  </span>
                </p>
                  
                  </>):(<>

                    <p>
                    क्या आपको अपनी छवि काटने में परेशानी हो रही है? <br />
                  <a href="#">
                    {" "}
                    अपनी फ़ोटो को मैन्युअल रूप से काटे बिना सीधे अपलोड करने के लिए यहां क्लिक करें।
                  </a>{" "}
                  <br />
                  <span>
                    (आपकी फ़ोटो स्वचालित रूप से क्रॉप और आकार बदल जाएगी।)
                  </span>
                </p>

                  </>)

                  }


              </div>
            </>
          ) : (
            <></>
          )}
          <div style={{ height: '250px', width: '100%', overflowY: 'auto' , display: profileData?.is_frozen?.type === "Other" ? 'block' : 'none' }} >

{profileData?.is_frozen?.feedback_image && (
  <img 
    src={`https://cdn.mohabbatein.com/${profileData.is_frozen.path}${profileData.is_frozen.feedback_image}`} 
    alt="Feedback" 
    className="feedback-image" 
    // style={{ height: '250px', width: '100%', objectFit: 'cover' }} 
  />
)}
</div>


          
          {/* <a href="/profile" onChange={toProfile}>
            <button className="main-bt mt-30">Go To Profile</button>
          </a> */}
        </div>
      </div>
    </>
  );
}
