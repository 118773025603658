import { createSlice } from "@reduxjs/toolkit";
import axiosAuth from "../auth/jwtMiddleware";

const url = process.env.REACT_APP_PUBLIC_URL;

export const settingsSlice = createSlice({
    name:'setting',
    initialState:{
        settingsData:[],
        isLoadingSettings :false
    },
    reducers:{
        startLoadingSettings: (state) =>{
            state.isLoadingSettings = true;
        },
        initializeSettings : (state, action) =>{
            state.isLoadingSettings = false;
            state.settingsData = action.payload;
        },
        clearSettingData :(state) =>{
            state.settingsData=[];
            state.isLoadingSettings =false;
        }
        
    }
});

export const {
    startLoadingSettings,
    initializeSettings,
    clearSettingData
} = settingsSlice.actions;
export default settingsSlice.reducer;


export const getSettings = (data) => async (dispatch) =>{
    dispatch(startLoadingSettings())

    try {
        await axiosAuth()
        .post(`${url}/account/get-settings`, data)
        .then(({data})=>{
            if (data.status === 200) {
                dispatch(initializeSettings(data?.data));
            }
        })
        .catch(error=>{
                // console.log(error)
        })
        
    } catch (error) {
        
    }
}

export const setClearSettingsData = (data) => (dispatch) =>{
    dispatch(clearSettingData())
}