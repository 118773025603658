import React, { useState, useEffect } from "react";
import { connect, Room, LocalVideoTrack as VideoRoom } from "twilio-video";
import axios from "axios";
import AxiosWithAuth from "../../app/auth/jwtMiddleware";
import { showAlert } from "../../components/showAlerts";

const url = process.env.REACT_APP_PUBLIC_URL;
const twilio = process.env.TWILIO_TOKEN_URL;

var userCallMinutesRemaining = 1800;

var roomNameInput = document.getElementById("room-name-input");

let localParticipant = document.getElementById("caller-video");
var localIdentity = document.getElementById("localIdentity");
var remoteParticipant = document.getElementById("participant-video");

const joinLeaveButton = document.getElementById("joinOrLeave");
let usernameInput = "";

let connected = false;
let room;

let currentCallId;

var localParticipantIsConnected = false;
var remoteParticipantIsConnected = false;

let videoMute = false;
let audioMute = false;

//
var incomingCallCount = 0;
var incomingCallTimer = null;

let chatid;

let callid;

// const [mainUser, setMainUser] = useState([])
export function sendVideoCallRequest(
  videoCallAvailable,
  user,
  currentUserTo,
  currentChatId,
  htmlElements
) {
  localIdentity = htmlElements?.localIdentity;
  localParticipant = htmlElements?.localParticipant;
  remoteParticipant = htmlElements?.remoteParticipant;
  // console.log(user);

  chatid = currentChatId;

  // console.log('SENDING CALL REQUEST');
  // console.log(videoCallAvailable);

  var data = {
    from: user?.main?.id,
    to: currentUserTo,
    chat_id: currentChatId,
  };

  if (videoCallAvailable) {
    try {
      AxiosWithAuth()
        .post(`${url}/videochats/send-request`, data)
        .then((response) => {
          if (response?.data?.data) {
            // console.log(response?.data?.data);
            startVideoCall()
            roomNameInput = response?.data?.data?.call_id;
            userCallMinutesRemaining = response?.data?.data?.call_remaining;
            connectOrDisconnect(roomNameInput);
          } else {
            document.getElementById("busy-popup").classList.add("active");
          }
        })
        .catch((error) => {
          showAlert(error?.response?.data?.data);
          // console.log(error?.response?.data?.data);
          // console.log(error?.response?.data?.data);
        });
    } catch (error) {
      // console.log(error);
    }
  } else {
    document.getElementById("non-premium-popup").classList.add("active");
  }
}

const connectOrDisconnect = async (callId) => {
  // console.log("conne");
  // console.log(connected);
  if (!connected) {
    // joinLeaveButton.disabled = true;
    // joinLeaveButton.innerHTML = "Connecting...";
    // console.log("connect");
    try {
      await StartRoom(callId);
    } catch (error) {
      // console.log(error);
    }
  } else {
    Disconnect();
  }
};

// endddddddd
const Disconnect = () => {

  const localTracks = room.localParticipant.tracks;
  localTracks.forEach(trackPublication => {
    const track = trackPublication.track;
    if (track) {
      track.stop();
    }
  });

  room.disconnect();
  // console.log("DISCONNECTED FROM FUNCT");
  connected = false;

  if (remoteParticipant.lastElementChild)
    remoteParticipant.lastElementChild.remove();
  // remoteIdentity.innerHTML = "";
  // localIdentity.innerHTML = "";
  const audioElement = localParticipant.querySelector("audio");
  const audioButton = document.getElementsByClassName("action mute");
  if (audioElement) {
    audioButton[0]?.remove();
  }
  const videoElement = localParticipant.querySelector("video");
  const videoButton = document.getElementsByClassName("action video");
  if (videoElement) {
    videoButton[0]?.remove();
  }


  while(localParticipant.firstChild){
          localParticipant.removeChild(localParticipant.firstChild);
      
        }
  // joinLeaveButton.innerHTML = "Join Video Call";
  // usernameInput.style.display = "inline-block";
  // roomNameInput.style.display = "inline-block";

  currentCallId = null;
  deactivateVideoCallMode();
  stopVideoCallTimer()
  // tranc
};

export function deactivateVideoCallMode() {
  document
    .querySelector("#midColumn .chat-wrap")
    .append(document.getElementById("chatbox"));
  // document.getElementById("main-content").classList.remove("videomode");
  document.getElementById("messages-page").classList.remove("videomode");
}

// enddddeeded

// start calling

const StartRoom = async (callId) => {
  activateVideoCallMode();
  currentCallId = callId;
  callid = callId;
  const roomName = callId;
  const identity = usernameInput;
  // console.log(currentCallId);
  // fetch an Access Token from the join-room route
  var data = {
    room: roomName,
  };


  // console.log(callId);
  const response = await urljoin(data);
  // console.log(response);
  if(!response)
     {
      showAlert(JSON.stringify(response))
      return
     }

       const  token  = await response?.data?.token;

  // render local participant
  // join the video room with the token
      // console.log("JOIN ROOM");
      room = await joinVideoRoom(roomName, token);

       if (room) {
      //   console.log(room);
      //  console.log("room");
      // console.log("HANDLE CONNECTION");
      handleConnectedParticipant(room.localParticipant);
      room.participants.forEach(handleConnectedParticipant);
      room.on("participantConnected", handleConnectedParticipant);
     }

  // render participants' video and audio tracks

  connected = true;
  //   addLocalVideo();

  //   console.log(localIdentity)
  // localIdentity.innerHTML = identity;
  // joinLeaveButton.innerHTML = "Leave Video Call";
  // joinLeaveButton.disabled = false;
  // usernameInput.style.display = "none";
  // roomNameInput.style.display = "none";
  // handle cleanup when a participant disconnects

  room.on("participantDisconnected", handleDisconnectedParticipant);
  // room.on("participantReconnecting", alert("RECONNECTION!"));

  // window.addEventListener("pagehide", () => {
  //   alert("RECONNECTION!!")
  //   console.log('pagehide');
  //   alert("PAGE HIDE!");
  //   // room.disconnect();
  // });
  //
  //
  //  window.addEventListener("beforeunload", () => {
  //   alert("UN LOADING!")
  //   console.log('kdjlsdjslkd')
  //    room.disconnect();
  //  });
};
//

const urljoin = async (data) => {
  try {
    return AxiosWithAuth()
      .post(`${url}/videochats/generate-token`, data)
      .then((response) => {
        return response.data;
      })
      .catch(error=>{
        console.log(error)
      });
  } catch (error) {
    console.log(error);
  }
};

//
function activateVideoCallMode() {
  console.log('videomode')
  shiftObject("rightColumn", "chatbox", "prepend");
  // document.getElementById("main-content").classList.add("videomode");
   document.getElementById("messages-page").classList.add("videomode");


  // 
}
//

function shiftObject(target, element, prepend = false) {
  if (document.getElementById(target) && document.getElementById(element)) {
    if (!prepend)
      document.getElementById(target).append(document.getElementById(element));
    else
      document.getElementById(target).prepend(document.getElementById(element));
  }
}
//
const joinVideoRoom = async (roomName, token) => {
  console.log("JOIN ROOM");
  console.log(roomName);
  console.log(token);
  // join the video room with the Access Token and the given room name
  const room = await connect(token, {
    room: roomName,
  });
  console.log(room);
  return room;
};
//
//
// const handleConnectedParticipant = (participant) => {
//   // console.log("HANDLE CONNTECT PARTICIPAT !!!!!!!!!!!!!!!!!!!!!");
//   // console.log(participant);

//   const localParticipant = room.localParticipant;
//   if (participant.identity === localParticipant.identity) {
//     addLocalUser(room.localParticipant);
//     console.log("LOCAL ADDED");
//     localParticipantIsConnected = true;
//     // startVideoCallTimer();
//   } else {
//     console.log("REMOTE partiscipant");

//     while(localParticipant.firstChild){
//       localParticipant.removeChild(localParticipant.firstChild);
  
//     }
//     const tracksDiv = document.createElement("div");
//     tracksDiv.setAttribute("id", participant.sid);
//     remoteParticipant.appendChild(tracksDiv);
//     // remoteIdentity.innerHTML = participant.identity;

//     participant.tracks.forEach((publication) => {
//       if (publication.isSubscribed) {
//         console.log(publication);
//         trackSubscribed(tracksDiv, publication.track);
//       }
//     });
//     participant.on("trackSubscribed", (track) =>
//       trackSubscribed(tracksDiv, track)
//     );

//     participant.on("trackUnsubscribed", trackUnsubscribed);
//     document.getElementById("videocallbox").classList.add("active");

//     remoteParticipantIsConnected = true;
//     startVideoCallTimer();
//   }
// };

// const handleConnectedParticipant = (participant) => {
//   const localParticipant = room.localParticipant;
//   if (participant.identity === localParticipant.identity) {
//     addLocalUser(room.localParticipant);
//     localParticipantIsConnected = true;
//   } else if (room.participants.size <= 2) {
//     // Check if there are already two participants in the room
//     const tracksDiv = document.createElement("div");
//     tracksDiv.setAttribute("id", participant.sid);
//     remoteParticipant.appendChild(tracksDiv);

//     participant.tracks.forEach((publication) => {
//       if (publication.isSubscribed) {
//         trackSubscribed(tracksDiv, publication.track);
//       }
//     });

//     participant.on("trackSubscribed", (track) =>
//       trackSubscribed(tracksDiv, track)
//     );

//     participant.on("trackUnsubscribed", trackUnsubscribed);

//     document.getElementById("videocallbox").classList.add("active");
//     remoteParticipantIsConnected = true;
//     startVideoCallTimer();
//   } else {
//     // If there are already two participants, handle accordingly
//     console.log("Cannot allow more than two participants in the room.");
//     // You can display a message to the user or take other actions here.
//   }
// };

let remoteParticipantEntered = false;

const handleConnectedParticipant = (participant) => {
  const localParticipant = room.localParticipant;
  if (participant.identity === localParticipant.identity) {
    addLocalUser(room.localParticipant);
    localParticipantIsConnected = true;
  } else {
    const tracksDiv = document.createElement("div");
    tracksDiv.setAttribute("id", participant.sid);
    remoteParticipant.appendChild(tracksDiv);

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        trackSubscribed(tracksDiv, publication.track);
      }
    });

    participant.on("trackSubscribed", (track) =>
      trackSubscribed(tracksDiv, track)
    );

    participant.on("trackUnsubscribed", trackUnsubscribed);

    document.getElementById("videocallbox").classList.add("active");
    remoteParticipantIsConnected = true;
    startVideoCallTimer();

    // Mark the remote participant as entered
    remoteParticipantEntered = true;
    // if (!remoteParticipantEntered) {

    // } else {
    //   // Prevent the participant from entering again
    //   console.log("Remote participant has already entered.");
    //   // You can display a message to the user or take other actions here.
    // }
  }
};




const addedTracks = [];

const addLocalUser = (participant) => {
  const participantSid = participant.sid;

  if (addedTracks.includes(participantSid)) {
    // Already added this participant, so skip adding tracks
    return;
  }

  addedTracks.push(participantSid);

  // Create a container for the participant's tracks
  const tracksDiv = document.createElement('div');
  tracksDiv.setAttribute('id', participantSid);

  // Add the tracks to the container
  participant.tracks.forEach((publication) => {
    if (publication.isSubscribed) {
      trackSubscribed(tracksDiv, publication.track);
    }
  });

  // Attach the container to the localParticipant element
  localParticipant.appendChild(tracksDiv);
  var videoIcon = document.createElement("button");

    videoIcon.className = "action video";
    // videoIcon.title = "Disable Video";
    // videoIcon.innerText = "Disable Video";
  
    var audioIcon = document.createElement("button");
    audioIcon.className = "action mute";
    // audioIcon.title = "Disable Audio";
    // audioIcon.innerText = "Disable Audio";
  
    participant.tracks.forEach((publication) => {
  
      const track = publication.track;
      if (track.kind === "video") {
        videoIcon.videoTrack = track;
      }
      if (track.kind === "audio") {
        audioIcon.audioTrack = track;
      }
      localParticipant.appendChild(track.attach());
    });
  
    videoIcon.onclick = function (event) {
      event.target.classList.toggle("active");
      if (videoMute === false) {
        room.localParticipant.videoTracks.forEach((publication) => {
          // console.log(publication)
          publication.track.disable();
          videoMute = true;
          // videoIcon.innerText = "Enable Video";
        });
      } else {
        room.localParticipant.videoTracks.forEach((publication) => {
          console.log("enable");
          publication.track.enable();
          videoMute = false;
          // videoIcon.innerText = "Disable Video";
        });
      }
    };
    audioIcon.onclick = function (event) {
      event.target.classList.toggle("active");
      if (audioMute === false) {
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
          audioMute = true;
          // audioIcon.innerText = "Enable Audio";
        });
      } else {
        room.localParticipant.audioTracks.forEach((publication) => {
          // console.log(publication)
          publication.track.enable();
          audioMute = false;
          // audioIcon.innerText = "Disable Audio";
        });
      }
    };
  
    document.querySelector("#videocallbox .buttons").appendChild(videoIcon);
    document.querySelector("#videocallbox .buttons").appendChild(audioIcon);
  
    participant.on("trackSubscribed", (track) => {
      trackSubscribed(tracksDiv, track);
    });
  
    participant.on("trackUnsubscribed", trackUnsubscribed);
  






};

// const addLocalUser = async (participant) => {
//   // if(!localParticipant){
//   while(localParticipant.firstChild){
//     localParticipant.removeChild(localParticipant.firstChild);

//   }
  

//   const tracksDiv = document.createElement("div");
//   tracksDiv.setAttribute("id", participant.sid);
//   // console.log(localParticipant);
//   // console.log("localParticipant");
//   localParticipant.appendChild(tracksDiv);
//   // video
//   var videoIcon = document.createElement("button");
//   videoIcon.className = "action video";
//   // videoIcon.title = "Disable Video";
//   // videoIcon.innerText = "Disable Video";

//   var audioIcon = document.createElement("button");
//   audioIcon.className = "action mute";
//   // audioIcon.title = "Disable Audio";
//   // audioIcon.innerText = "Disable Audio";

//   participant.tracks.forEach((publication) => {

//     const track = publication.track;
//     if (track.kind === "video") {
//       videoIcon.videoTrack = track;
//     }
//     if (track.kind === "audio") {
//       audioIcon.audioTrack = track;
//     }
//     localParticipant.appendChild(track.attach());
//   });

//   videoIcon.onclick = function (event) {
//     event.target.classList.toggle("active");
//     if (videoMute === false) {
//       room.localParticipant.videoTracks.forEach((publication) => {
//         // console.log(publication)
//         publication.track.disable();
//         videoMute = true;
//         // videoIcon.innerText = "Enable Video";
//       });
//     } else {
//       room.localParticipant.videoTracks.forEach((publication) => {
//         console.log("enable");
//         publication.track.enable();
//         videoMute = false;
//         // videoIcon.innerText = "Disable Video";
//       });
//     }
//   };
//   audioIcon.onclick = function (event) {
//     event.target.classList.toggle("active");
//     if (audioMute === false) {
//       room.localParticipant.audioTracks.forEach((publication) => {
//         publication.track.disable();
//         audioMute = true;
//         // audioIcon.innerText = "Enable Audio";
//       });
//     } else {
//       room.localParticipant.audioTracks.forEach((publication) => {
//         // console.log(publication)
//         publication.track.enable();
//         audioMute = false;
//         // audioIcon.innerText = "Disable Audio";
//       });
//     }
//   };

//   document.querySelector("#videocallbox .buttons").appendChild(videoIcon);
//   document.querySelector("#videocallbox .buttons").appendChild(audioIcon);

//   participant.on("trackSubscribed", (track) => {
//     trackSubscribed(tracksDiv, track);
//   });

//   participant.on("trackUnsubscribed", trackUnsubscribed);

// // }


// };

//

//
//
var callTimerSeconds = 0;
var callTimerMinute = 0;


let currentCallTimer;
let currentCallCountUp;

function startVideoCallTimer() {
  var callLimit = userCallMinutesRemaining;
  var initialLimit = callLimit;
  var timeLeftElement = document.getElementById("timeleft");
  
  if (localParticipantIsConnected && remoteParticipantIsConnected) {
    currentCallTimer = setInterval(() => {
      callLimit--;
      if (callLimit < 1) {
        stopVideoCallTimer();
        localParticipantIsConnected = false;
        remoteParticipantIsConnected = false;
        Disconnect();
      }
    }, 1000);

    callTimerSeconds = 0;
    callTimerMinute = 0;
    
    currentCallCountUp = setInterval(() => {
      callTimerSeconds++;

      if (callTimerSeconds > 59) {
        callTimerMinute++;
        callTimerSeconds = 0;
      }

      // timeLeftElement.innerHTML = callTimerMinute + ":" + (callTimerSeconds < 10 ? "0" + callTimerSeconds : callTimerSeconds);
    }, 1000);
  }
}

function stopVideoCallTimer() {
  clearInterval(currentCallTimer);
  clearInterval(currentCallCountUp);
  callTimerSeconds = 0;
  callTimerMinute = 0;
}
//
const trackSubscribed = (div, track) => {
  const trackElement = track.attach();
  div.appendChild(trackElement);
};
//
//
const trackUnsubscribed = (track) => {
  console.log("trackUnsubscribed");
  console.log(track);
  // endVideoCall();

  track.detach().forEach((element) => {
    element.remove();
  });

  if (currentCallId) Disconnect();
};

const handleDisconnectedParticipant = (participant) => {
  console.log("HANDLE DISCONECTED");
  // stop listening for this participant
  participant.removeAllListeners();

  var data = {
    call_id: callid,
    chat_id: chatid,
  };
  stopVideoCallTimer()
  // if()
  try {
    AxiosWithAuth()
      .post(`${url}/videochats/end-call`, data)
      .then((response) => {
        console.log(response);
        Disconnect();
       
        if (document.getElementById("callnotification"))
          document
            .getElementById("callnotification")
            .classList.remove("active");

        if (document.getElementById("videocallbox"))
          document.getElementById("videocallbox").classList.remove("active");
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
    console.log("e");
  }
};

//
//
//
//
// custom functions

export function showIncomingCall(caller, currentChatId) {
  // console.log(caller);
  // document.getElementById("incomingCall").innerHTML = "";
  document.getElementById("call-popup").classList.add("active");

  chatid = currentChatId;
  incomingCallCount = 0;

  incomingCallTimer = setInterval(() => {
    incomingCallCount++;
    if (incomingCallCount > 1000) {
      console.log(incomingCallCount);
      clearInterval(incomingCallTimer);
      incomingCallCount = 0;
      sendVideoCallUnanswered(caller, currentChatId);
      incomingCallTimer = null;
    }
  }, 1000);
}

export function validateCall (currentCallId , userdata , currentCallerId, chat_id){

  var data={
    call : currentCallId,
    user: userdata?.main?.id,
    caller: currentCallerId
  }

  console.log(data)
  try{
    AxiosWithAuth()
    .post(`${url}/videochats/get-request`,data)
    .then( (res)=>{
      console.log(res)
        if(res?.data?.data?.is_online){
          // console.log()
          showIncomingCall(currentCallId, chat_id)

          if(document.getElementById('callnotification'))
             document.getElementById('callnotification').classList.remove('active');
        }

// 
    })
    .catch((error)=>{
      console.log(error)
    })

  }
  catch(e){
    console.log(e)
  }

  // $.ajax({
  //     type: "POST",
  //     url: '{{ url("/") }}/api/v1/videochats/get-request',
  //     data: { call : currentCallId, user : authUser.id, caller : currentCallerId },
  //     success: function(response){
  //         if(response.success)
  //             showIncomingCall(response);

  //         if(document.getElementById('callnotification'))
  //             document.getElementById('callnotification').classList.remove('active');
  //     },
  //     statusCode: {
  //         401: function() {
  //             window.location.href = '{{url('login')}}'; //or what ever is your login URI
  //         }
  //     },
  //     complete : function (event,error){
  //         loadingMoreMessages = false;
  //     }
  // });
}


export  function validateGobalCall (currentCallId , userdata , currentCallerId, chat_id){

  var data={
    call : currentCallId,
    user: userdata?.main?.id,
    caller: currentCallerId
  }

  console.log(data)
  try{
    AxiosWithAuth()
    .post(`${url}/videochats/get-request`,data)
    .then( (res)=>{
      console.log(res)
        if(res?.data?.data?.is_online){
          document.getElementById("callnotification").classList.add("active");
        }

        return false;
// 
    })
    .catch((error)=>{
      return false;
      // 

    })

  }
  catch(e){
    return false;
    // 
  }


}




export const acceptVideoCall = (data, user, chatId, html) => {

  if (!localParticipant) {
    localParticipant = html?.localParticipant;
    return
  }
  if(!remoteParticipant){
    remoteParticipant = html?.remoteParticipant;
  }
  // localParticipant = html
  // Handle accepting the video call here
  // console.log('Accepted video call with callId: ', data?.data?.caller_id);

  var videodata = {
    call_id: data?.data?.call_id,
    user_id: user?.main?.id,
    chat_id: chatId,
  };

  clearInterval(incomingCallTimer);
  incomingCallCount = 0;
  currentCallId = data?.data?.caller_id;
  callid = data?.data?.caller_id;

  connectOrDisconnect(data?.data?.call_id);

  try {
    AxiosWithAuth()
      .post(`${url}/videochats/accept-call`, videodata)
      .then((response) => {
        console.log(response);
        // startVideoCall();
        if (document.getElementById("call-popup"))
          document.getElementById("call-popup").classList.remove("active");

        if (document.getElementById("callnotification"))
          document
            .getElementById("callnotification")
            .classList.remove("active");

        userCallMinutesRemaining = response.data.call_remaining;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
  }
};

export const acceptCallFromExternal = (callId,user, chatId, html) =>{
  console.log('here')
  console.log(localParticipant)
  // localParticipant = html?.localParticipant;
  if (!localParticipant) {
    localParticipant = html?.localParticipant;
    return
  }
  if(!remoteParticipant){
    remoteParticipant = html?.remoteParticipant;
  }
 
  // localParticipant = html
  // Handle accepting the video call here
  // console.log('Accepted video call with callId: ', data?.data?.caller_id);

  var videodata = {
    call_id: callId,
    user_id: user?.main?.id,
    chat_id: chatId,
  };

  clearInterval(incomingCallTimer);
  incomingCallCount = 0;
  currentCallId = callId;
  callid = callId;

  connectOrDisconnect(callId);

  try {
    AxiosWithAuth()
      .post(`${url}/videochats/accept-call`, videodata)
      .then((response) => {
        console.log(response);
        // startVideoCall();
        if (document.getElementById("call-popup"))
          document.getElementById("call-popup").classList.remove("active");

        if (document.getElementById("callnotification"))
          document
            .getElementById("callnotification")
            .classList.remove("active");

        userCallMinutesRemaining = response.data.call_remaining;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
  }
}

export const rejectVideoCall = (e, data, user, chatId) => {
  e.preventDefault();

  // notificationSound('ring',true);

  //
  var videoData = {
    call_id: data?.data?.call_id,
    user_id: user?.main?.id,
    chat_id: chatId,
  };

  clearInterval(incomingCallTimer);
  incomingCallCount = 0;
  // Handle rejecting the video call here
  console.log("Rejected video call with callId: ", data?.data?.caller_id);

  //
  try {
    AxiosWithAuth()
      .post(`${url}/videochats/decline-call`, videoData)
      .then((response) => {
        console.log(response);

        if (document.getElementById("call-popup"))
          document.getElementById("call-popup").classList.remove("active");

        if (document.getElementById("callnotification"))
          document
            .getElementById("callnotification")
            .classList.remove("active");
      })
      .catch((error) => {
        console.log(error);

        if (document.getElementById("call-popup"))
          document.getElementById("call-popup").classList.remove("active");

        if (document.getElementById("callnotification"))
          document
            .getElementById("callnotification")
            .classList.remove("active");
      });
  } catch (e) {}
};


export const rejectVideoCallExternal =(data, user, chatId) =>{
  var videoData = {
    call_id: data?.data?.call_id,
    user_id: user?.main?.id,
    chat_id: chatId,
  };

  clearInterval(incomingCallTimer);
  incomingCallCount = 0;
  // Handle rejecting the video call here
  console.log("Rejected video call with callId: ", data?.data?.caller_id);

  //
  try {
    AxiosWithAuth()
      .post(`${url}/videochats/decline-call`, videoData)
      .then((response) => {
        console.log(response);

        if (document.getElementById("call-popup"))
          document.getElementById("call-popup").classList.remove("active");

        if (document.getElementById("callnotification"))
          document
            .getElementById("callnotification")
            .classList.remove("active");
      })
      .catch((error) => {
        console.log(error);

        if (document.getElementById("call-popup"))
          document.getElementById("call-popup").classList.remove("active");

        if (document.getElementById("callnotification"))
          document
            .getElementById("callnotification")
            .classList.remove("active");
      });
  } catch (e) {}
}

export function sendVideoCallUnanswered(call_id, currentChatId) {
  chatid = currentChatId;
  var data = {
    call_id: call_id,
    chat_id: currentChatId,
  };

  try {
    AxiosWithAuth()
      .post(`${url}/videochats/call-unanswered`, data)
      .then((respond) => {
        console.log(respond);
        document.getElementById("call-popup").classList.remove("active");
      })
      .catch((error) => {
        document.getElementById("call-popup").classList.remove("active");
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
}

function startVideoCall() {
  console.log("STARING VIDEO CALL");

  activateVideoCallMode();
}

export const endVideoCall = (e, currentChatId)  =>{
  e.preventDefault();
  document.getElementById("call-popup").classList.remove("active");
  console.log("data");
  console.log(currentCallId);
  console.log(currentChatId);

  chatid = currentChatId;
  callid = currentCallId;
  if (currentCallId) {
    var data = {
      call_id: currentCallId,
      chat_id: currentChatId,
    };
    // console.log(data);

    try {
      AxiosWithAuth()
        .post(`${url}/videochats/end-call`, data)
        .then((response) => {
          console.log(response);
          Disconnect();
          if (document.getElementById("callnotification"))
            document
              .getElementById("callnotification")
              .classList.remove("active");
          if (document.getElementById("videocallbox"))
            document.getElementById("videocallbox").classList.remove("active");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      console.log("e");
    }

    console.log("VIDEO CALL ENDED!");
  }
}
