import { useTranslation } from 'react-i18next';

export default function ProfileBio(data) {
  // console.log(data?.data?.bio)
  const { t } = useTranslation();
  return (
    <>
      <div className="copy-wrap">
        <span className="heading" style={{ marginBottom: "10px!important" }}>{t(['about-me-label',''])}</span>
        <div
          className="setdata"
          id="profile-bio"
          dangerouslySetInnerHTML={{ __html: data?.data?.bio }}
        ></div>
      </div>
      <span className="divider mt-30 mb-30"></span>
      <div className="copy-wrap">
        <span className="heading" style={{ marginBottom: "10px!important" }}>{t(["search-label",''])}</span>
        <div
          className="setdata"
          id="profile-search_bio"
          dangerouslySetInnerHTML={{ __html: data?.data?.search_bio }}
        ></div>
      </div>
      <span className="divider mt-30 mb-30"></span>
    </>
  );
}
