import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import { setfrozenPopUp } from "../../app/actions/Notifications";
import Cropper from "react-cropper";
import AxiosWithAuth from "../../app/auth/jwtMiddleware";
import { showAlert } from "../showAlerts";
import { getLanguageFromLocalStorage } from "../../app/utilities/localization";
import { getProfileData } from "../../app/actions/Profile";
import ImageCropper from "../ImageCropper";
import "./success.scss";

const url = process.env.REACT_APP_PUBLIC_URL;

export default function PaymentSuccessPopup() {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [profilePayment, setProfilePayment] = useState(false);
  const [ icCompletePopUp , setCompletePopUp] = useState(false);
  const { profileData } = useSelector((state) => state.profile);
 
  // console.log("state.notifications  ");

 // console.log("profilefrozen "+setProfilePayment);
  //console.log("icCompletePopUp "+icCompletePopUp);

  const [loading, setLoading] = useState(false);
  const [isBrowser, setIsBrowser] = useState(true);


  const language = getLanguageFromLocalStorage() || "en";


  useEffect(() => {
    if (location.pathname === "/thankyou") {
      console.log("Setting profilePayment to true");
      setProfilePayment(true);
      setCompletePopUp(true);
    }
  }, [location.pathname]);



  const toProfile = (e) => {
    e.preventDefault();
    // dispatch(setfrozenPopUp(false));
    window.location.href = "/home";
  };

  const handleCloseCropper = (e) => {
    e.preventDefault();
    console.log("Setting popup close calling");
    document.getElementById("frozen-popup").classList.remove("active");
  };

  



  // console.log(status)
  return (
    <>
      {loading ? <div className="loader"></div> : <></>}
      <div
        id="frozen-popup"
        className={`popup-main report big has-icon ${
          profilePayment && icCompletePopUp ? "active" : ""
        }`}   style={{ zIndex: 99999 }}
      >
        <div className="wrap_new thankyou text-center">
          {/* <span className="heading">{profilefrozendata?.title} </span> */}

          <span className="subheading mt-30 mb-20">
            {/* {t(["profile-popup-details", ""])} */}
            <p>Congratulations on upgrading to our Premium Package! 🎉 You've taken a significant step towards enhancing your matrimonial journey.</p>
          </span>
    
          <div id="cropModalFrozen" className="popup-main">
            <div className="wrapper">
              <button
                className="close test_popu"
                onClick={(e) => handleCloseCropper(e)}
              ></button>

              <div className="cropwrapper">
              {!isBrowser ? (
                <>
            
                </>
              ) : (
                <>
           
              
                </>
              )}
              </div>
            </div>
          </div>

  

          {/* <a href="/home" onChange={toProfile}> */}
          <a href="/"> 
            <button className="main-bt mt-30">Go To Profile</button>
          </a>
        </div>
      </div>
    </>
  );
}
