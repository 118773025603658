import { useTranslation } from 'react-i18next';
import './authFooter.scss'; 
import LanguageSwitcher from '../../components/LanguageSwitcher';
import {
  getLanguageFromLocalStorage,

} from "../../app/utilities/localization";
export default function AuthFooter() {
    const { t } = useTranslation();
    const language = getLanguageFromLocalStorage() || "en";
  return (
    <>
        <footer>
          <div className="container">
            <div className="row reverse-mobile">
              <div className="col-lg-5 col-md-6">
                <div className="left">
                {language === "en" ? (
                  <div>© {(new Date().getFullYear())} Mohabbatein | All Rights Reserved</div>
                ) : (
                  <div>© {(new Date().getFullYear())}  मोहब्बतें | सर्वाधिकार सुरक्षित</div>
                ) }
                  {/* <div>Created By ARENA</div> */}
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="right">
                  <ul>
                    <li>
                      <a href="https://mohabbatein.com/privacy-policy/"> {t(["privacy-link","Privacy Policy"])}</a>
                    </li>
                    <li>
                      <a href="https://mohabbatein.com/terms/">{t(["terms-link","Terms and Conditions"])}</a>
                    </li>
                  </ul>
                     <LanguageSwitcher /> 
                </div>
              </div>
            </div>
          </div>
        </footer>
    </>
  );

}
