import React from 'react'

const PricingCard = ({title,months,price,discount,total_price,currency}) => {
  return (
    <>
        <div className={`pricing-card`}>
         
          <div className='pricing-card__header'>
          {title!=="" && (
          <h5 className={`${months==="1" && 'non-visiable'}`}>{title}</h5>
          )
        }
          </div>
     

            <div className='pricing-card__months_number'>
                    <h2>{months}</h2>
                </div>
                <div className='pricing-card__month'>
                    <p>{months==="1" ? "month" : "months"}</p>
                    </div>
                    {currency==="AED" && (
                       <div className='pricing-card__price'>
                       <p>{price}</p>
                       </div>
                    )
                    }
      
                        <div className='pricing-card__discount'>
                        <p>{discount}</p>
                        </div>
                        <div className='pricing-card__total'>
                        <p><span>{currency}</span>{total_price}</p>
                        </div>
        </div>    
    </>

  )
}

export default PricingCard