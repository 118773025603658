import React,{useState} from 'react'
import Slider from "react-slick";
//
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeatureSlide from './FeatureSlide';

import crownIcon from "../../assets/images/Main/new_pricing_crown_icon.svg";




const FeaturesCarousel = () => {
   
  const [activeSlide, setActiveSlide] = useState(0);

    const featuresSliderSettings = {
        dots: true,
        arrows: true,
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        afterChange: (current) => {
          setActiveSlide(current)
        },
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: false,
            }
          },
        ]
      };

  return (
    <>


    
    <div id="gradient-background" className={`gradiant-background-${activeSlide}`}>
   <div className='feature-slide_header'>
        <div className='feature-slide_header_title'>
            <div className='feature-slide_header_title_img'>
                <img src={crownIcon} alt='crown icon'/>
                </div>
                <div className='feature-slide_header_title_text'>
                    <h1>
                        <span>
                        Get &nbsp;
                        </span>
                        <span className='brand-color'>
                         Premium
                            </span>
    
                    </h1>
                    </div>
            </div>
            <div className='feature-slide_close-icon'>
                <a href='/'>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                    </svg>
                </span>                    
                </a>
            </div>

            <div id="doThisLater" className='feature-slide_link'>
                <a href='/'>
                <span>
                 Do this later
                </span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                    <path d="M12.3535 4.35355C12.5488 4.15829 12.5488 3.84171 12.3535 3.64644L9.17156 0.464464C8.9763 0.269202 8.65972 0.269202 8.46446 0.464465C8.2692 0.659727 8.2692 0.976309 8.46446 1.17157L11.2929 4L8.46446 6.82843C8.2692 7.02369 8.2692 7.34027 8.46446 7.53553C8.65972 7.73079 8.9763 7.73079 9.17157 7.53553L12.3535 4.35355ZM0.5 4.5L12 4.5L12 3.5L0.5 3.5L0.5 4.5Z" fill="black"/>
                    </svg>
                </span>                    
                </a>
            </div>
        </div>
          <div className='pricing-page-container'>
            <Slider {...featuresSliderSettings}>
          <div>
           <FeatureSlide 
           icon = {0}
           title = "Ghost Mode"
           text = "Only be shown to people you’ve liked"
           />
          </div>
          <div>
          <FeatureSlide 
                     icon = {1}
                     title = "Video Calling"
                     text = "Meet your match face-to-face"
          />
          </div>
          <div>
          <FeatureSlide 
                     icon = {2}
                     title = "See Who Liked You"
                     text = "Find your forever"
          />
          </div>
          <div>
          <FeatureSlide 
                     icon = {3}
                     title = "Meet Ups"
                     text = "Get invited to Mohabbatein’s in person meet ups!"
          />
          </div>
          <div>
          <FeatureSlide 
                     icon = {4}
                     title = "3x More Visibility"
                     text = "With more visibility have more people view your profile"
          />
          </div>
        </Slider>
        </div>
        </div>
    </>
  )
}

export default FeaturesCarousel