// src/store/popupSlice.js
import { createSlice } from '@reduxjs/toolkit';

const mainpopupSlice = createSlice({
  name: 'main_popup',
  initialState: {
    isPopupVisible: false,
  },
  reducers: {
    showPopup: (state) => {
      state.isPopupVisible = true;
    },
    hidePopup: (state) => {
      state.isPopupVisible = false;
    },
  },
});

export const { showPopup, hidePopup } = mainpopupSlice.actions;

export default mainpopupSlice.reducer;
