import React, { useCallback, useEffect, useState, useRef,forwardRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import "./multiRangeSlider.scss";
// import "../../pages/main/main.scss"

const MultiRangeSliderAge = forwardRef(({ min, max, onChange, title, apptext, data, onMouseUp, onTouchEnd},ref) => {

  const { t } = useTranslation();
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  const [minDisplayVal, setMinDisplayVal] = useState([]);
  const [maxDisplayVal, setMaxDisplayVal] = useState([]);
  const [mouseMovement, setmouseMovent] = useState(false)
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // function

  useEffect(() => {
    if (data?.length > 0) {
      setMinDisplayVal(data[0]);
      setMaxDisplayVal(data[data.length - 1]);
    }
  }, [data, maxVal, minVal]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    setMinDisplayVal(data[minVal]);
    // console.log('set widthdswds')
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    setMaxDisplayVal(data[maxVal]);
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  useEffect(()=>{
    onMouseUp({ min: minVal, max: maxVal })
  },[mouseMovement])

  useEffect(()=>{
    onTouchEnd({ min: minVal, max: maxVal })
  },[mouseMovement])
  

  const handleAgeFilterBlur = () =>{
    setmouseMovent(!mouseMovement)
  }

  useEffect(() => {
    if (ref) {
      ref.current = {
        resetValues
      };
    }
  }, [ref]);

  const resetValues = () => {
    setMinVal(min);
    setMaxVal(max);
  };



  return (
    <div id="MultiRangeSlider" className="filter-wrap min-max">
      <div className="double_range_slider_box">
        <div className="double_range_slider">
          <input
            type="range"
            min={min}
            max={max}
            value={minVal}
            ref={minValRef}
            onMouseUp={handleAgeFilterBlur}
            onTouchEnd={handleAgeFilterBlur}
            onChange={(event) => {
              const value = Math.min(+event.target.value, maxVal - 1);
              setMinVal(value);
              event.target.value = value.toString();
            }}
            className={classnames("thumb thumb--zindex-3 min", {
              "thumb--zindex-5": minVal > max - 100,
            })}
          />

          
          <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            ref={maxValRef}
            onMouseUp={handleAgeFilterBlur}
            onTouchEnd={handleAgeFilterBlur}
            onChange={(event) => {
              const value = Math.max(+event.target.value, minVal + 1);
              setMaxVal(value);
              event.target.value = value.toString();
            }}
            className="thumb thumb--zindex-4 max"
          />

          <div className="result">
            {title} <div className="minvalue"> {minDisplayVal?.name}</div> {t(["to-label"])}{" "}
            <div className="maxvalue">{maxDisplayVal?.name}</div>
          </div>

        </div>
      </div>
    </div>
  );
});

MultiRangeSliderAge.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,

  
};

export default MultiRangeSliderAge;
