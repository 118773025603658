import React, { useEffect } from 'react'
import { useLocation,useNavigate } from "react-router-dom";
import AuthFooter from "./AuthFooter";
import AuthHeader from "./AuthHeader";
import axios from "axios";
import "./Register.scss";
import { useTranslation } from "react-i18next";
import axiosWithAuth from "../../app/auth/jwtMiddleware";
import SuccessImage from "../../assets/images/success.png";
import {
  getLanguageFromLocalStorage,
  savePageTranslateLocalStorage,
  initializeI18n,

} from "../utilities/localization";

const url = process.env.REACT_APP_PUBLIC_URL;
const UnSubscribe = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const language = getLanguageFromLocalStorage() || "en";
    useEffect(() => {
      localStorage.removeItem("persist:root");
      const fetchData = async () => {
        await savePageTranslateLocalStorage("registration");
        initializeI18n();
      };
  
      fetchData();
    }, []);
      useEffect(()=>{
        // /get-user-token

        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');


        var data = {
          token: token,
        };
        try {
          axiosWithAuth()
            .post(`${url}/account/get-user-token`, data)
            .then((res) => {
              updateSetting(res?.data?.data);
              // if(type===)
            })
            .catch((error) => {
            //   console.log(error);
            });
        } catch (error) {
        //   console.log(error);
        }
       // updateSetting('159237d1-6267-4db7-8e3f-79665c8e4808');
      },[]);


      function updateSetting($user_id) {
        var data = {
          user: $user_id,
          setting: 'summary-emails',
          value: false,
        };
    
        try {
          axios
            .post(`${url}/account/update-setting`, data)
            .then((res) => {

             // window.location.href = "/";
              // if(type===)
            })
            .catch((error) => {
            //   console.log(error);
            });
        } catch (error) {
        //   console.log(error);
        }
      }
      function handleLogin(e) {
        e.preventDefault();
    
        window.location.href = "/login";
      }

  return (
    <div id="Register">
      <AuthHeader />
      <section id="main">
    <div
    id="registration-complete"
    className={"formwrap active"}
  >
    
    <div className="text-center">
    <img src={SuccessImage} alt="Mohabbatein Success" />
    <br />
      <h2 className="heading">
        {language==='en' ? ("Unsubscription Confirmed") : "सदस्यता समाप्ति की पुष्टि"}
      
      </h2>
     
      <br />
      <p>
      {language==='en' ?  (<div>
      Connecting you to a world of  <span className="red-text">genuine connections</span> and  <span className="red-text">endless happiness.</span>
      </div>) : (
        <div>
          आपको वास्तविक संबंधों और अनंत खुशियों की दुनिया से जोड़ रहा है।
        </div>
      )}
      </p>
      <br />
      <a href="/login">
        <button
          className="nav-bt red float-end"
          onClick={(e) => handleLogin(e)}
        >
          {t(["login-now", "Login Now"])}
        </button>
      </a>
    </div>
  </div>
  </section>
  <AuthFooter/>
</div>
  )
}

export default UnSubscribe