import { useTranslation } from 'react-i18next';
import './authHeader.scss'; 
import Logo from "../../assets/images/Mohabbatein Logo.svg"

export default function AuthHeader() {
    const { t } = useTranslation();

  return (
    <>
<header style={{zIndex:1}}>
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <a href='/'>
                    <img src={Logo} alt="Mohabbatein Logo"/>

                    </a>
                </div>
            </div>
        </div>
    </header>

    </>
  );

}
