import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cropper from "react-cropper";
import AxiosWithAuth from "../../app/auth/jwtMiddleware";
import { showAlert } from "../showAlerts";
import { getProfileData } from "../../app/actions/Profile";
import { getLanguageFromLocalStorage } from "../../app/utilities/localization";
import { setCompletePopUp } from "../../app/actions/Notifications";
import "./subscription.scss";
//
const url = process.env.REACT_APP_PUBLIC_URL;

export default function ProfileSubscription() {
  const location = useLocation();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [profilecomplete, setProfile] = useState(true);

  const [cropData, setCropData] = useState("#");
  const { profileData } = useSelector((state) => state.profile);
  const [cropper, setCropper] = useState();
  const [loading, setLoading] = useState(false);
  const [cropimage, setCropImage] = useState("");

  const language = getLanguageFromLocalStorage() || "en";

  useEffect(() => {
    if (location?.pathname !== "/profile") {
      // console.log(location);

      // console.log(profileData)
      //   console.log(profileData?.profile?.is_complete);
      if (profileData?.profile) {
        // console.log(profileData?.profile)
        setTimeout(() => {
          dispatch(setCompletePopUp(profileData?.profile?.is_complete));
          setProfile(profileData?.profile?.is_complete);
        }, 10);
      }
    } else {
      setProfile(true);
      dispatch(setCompletePopUp(false));
      // console.log("profile");
    }


  }, [profileData, location]);

  // /

  const handleCloseCropper = (e) => {
    e.preventDefault();
    document.getElementById("cropModalProfilePopUp").classList.remove("active");
  };

  const cropImage = () => {
    var imagedata = "";

    if (typeof cropper !== "undefined") {
      // console.log(cropper.getCroppedCanvas().toDataURL());
      imagedata = cropper
        .getCroppedCanvas({
          width: 600,
          height: 600,
          // Limit max sizes
          maxWidth: 2000,
          maxHeight: 2000,
        })
        .toDataURL();
      setCropData(
        cropper
          .getCroppedCanvas({
            width: 600,
            height: 600,
            // Limit max sizes
            maxWidth: 2000,
            maxHeight: 2000,
          })
          .toDataURL()
      );
    }
    // console.log("This is the Croped Image", imagedata);
    if (imagedata !== "#" || imagedata !== "") {
      uploadImage(imagedata);
    }
  };

  function uploadImage(cropdata) {
    var image = {
      image: cropdata,
      user_id: profileData?.main?.id,
    };
    setLoading(true);
    try {
      AxiosWithAuth()
        .post(`${url}/profiles/update-photo`, image)
        .then((data) => {
          // console.log(data);
          setLoading(false);
          if (data?.status === 200) {
            showAlert(data?.data?.message);
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);

            formData.append("language", language);

            // dispatch(getProfileData(formData));
            window.location.href = "/profile/";
          } else {
            console.log("error");
          }

          //
          document
            .getElementById("cropModalProfilePopUp")
            .classList.remove("active");
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const imageSelect = (e) => {
    // console.log(e.target.files);
    var files = e.target.files;

    var reader;
    var file;
    var url;

    var done = function (url) {
      //  image.src = url;
      setCropImage(url);
      // console.log(url);
      //   // $modal.modal('show');
      document.getElementById("cropModalProfilePopUp").classList.add("active");
    };

    //

    if (files && files.length > 0) {
      file = files[0];

      if (URL) {
        done(URL.createObjectURL(file));
      } else if (FileReader) {
        reader = new FileReader();
        reader.onload = function (e) {
          done(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  function uploadImageDirect(e) {
    e.preventDefault();
    if (!e.target.files) {
      return false;
    }

    var fd = new FormData();
    fd.append("photo", e.target.files[0]);
    fd.append("user_id", profileData?.main?.id);

    setLoading(true);

    var headers = {
      contentType: false,
      processData: false,
    };

    try {
      AxiosWithAuth()
        .post(`${url}/profiles/update-photo-direct`, fd, headers)
        .then((data) => {
          // console.log(data);
          setLoading(false);

          if (data?.status === 200) {
            showAlert(data?.data?.message);
            var formData = new FormData();
            formData.append("slug", profileData?.profile?.slug);

            formData.append("language", language);

            dispatch(getProfileData(formData));
          } else {
            console.log("error");
          }
          document.getElementById("directPhoto").value();
          //
          document.getElementById("cropModal").classList.remove("active");
        })
        .catch((error) => {
          setLoading(false);
          if (error?.code === "ERR_NETWORK") {
            showAlert(error?.message);
          }
          if (error?.response?.data?.status === 422) {
            showAlert(error?.response?.data?.data);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <>
      <div
        id="profile-popup"
        className={`popup-main sparkle has-icon ${
          profilecomplete ? "" : "active"
        }`}
      >
        <div className="wrap text-center">
          <span className="heading">{t(["almost-there-message", ""])}</span>

          <div className="progress-wrap"></div>

          <div id="cropModalProfilePopUp" className="popup-main">
            <div className="wrapper">
              <button
                className="close"
                onClick={(e) => handleCloseCropper(e)}
              ></button>

              <div className="cropwrapper">
                <Cropper
                  zoomable={false}
                  initialAspectRatio={1}
                  scaleX={1}
                  scaleY={1}
                  aspectRatio={1 / 1}
                  preview=".preview"
                  src={cropimage}
                  viewMode={3}
                  minCropBoxWidth={100}
                  checkOrientation={false}
                  guides={true}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                />
                <button
                  type="button"
                  className="main-bt mt-20"
                  id="crop"
                  onClick={cropImage}
                >
                  {t(["save", "Save"])}
                </button>
              </div>
            </div>
          </div>

          <span className="subheading mt-30 mb-20">
            {t(["profile-popup-details", ""])}
          </span>

          <div className={`add-photo profile-photo`}>
            <div id="addPhoto">
              <input
                type="file"
                name="photo"
                className={`form-control profile-photo`}
                accept="image/*"
                id="profile-photo"
                onChange={imageSelect}
              />

              <button className="">+ {t(["add-photo", "Add a Photo"])}</button>
            </div>
          </div>

          <div id="directPhotoWrap">
            <input
              type="file"
              id="directPhoto"
              accept=".jpg, .jpeg, .png"
              max
              onChange={uploadImageDirect}
            />

            {language === "en" ? (
              <>
                <p>
                  Having trouble cropping your image? <br />
                  <a href="#">
                    {" "}
                    Click here to upload your photo directly without manually
                    cropping.
                  </a>{" "}
                  <br />
                  <span>
                    (Your photo will be cropped and resized automatically.)
                  </span>
                </p>
              </>
            ) : (
              <>
                <p>
                  क्या आपको अपनी छवि काटने में परेशानी हो रही है? <br />
                  <a href="#">
                    {" "}
                    अपनी फ़ोटो को मैन्युअल रूप से काटे बिना सीधे अपलोड करने के
                    लिए यहां क्लिक करें।
                  </a>{" "}
                  <br />
                  <span>
                    (आपकी फ़ोटो स्वचालित रूप से क्रॉप और आकार बदल जाएगी।)
                  </span>
                </p>
              </>
            )}
          </div>

          {/* 
          <a href="/profile">
            <button className="main-bt mt-30">{t(["complete-profile-button", ""])}</button>
          </a> */}
        </div>
      </div>
    </>
  );
}
