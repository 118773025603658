import { createSlice } from "@reduxjs/toolkit";
import axiosAuth from "../auth/jwtMiddleware";
import { getLanguageFromLocalStorage } from "../utilities/localization"

const url = process.env.REACT_APP_PUBLIC_URL;

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileData: null,
    profileObject:null,
    isProfileLoading: false,
    isEditDataLoading :false,
    editData: [],
    profileattributesComplete: false,
    profilebiosComplete: false,
    profileAttributes: [],
    profileCheck:false,
  },
  reducers: {
    startProfileLoading: (state) => {
   
      state.isProfileLoading = true;
    },
    initializeProfile: (state, action) => {
      state.isProfileLoading = false;
      state.profileData = action.payload;
      state.profileObject = action.payload
    },
    initializeProfileObject: (state, action)=>{
      state.isProfileLoading = false;
      state.profileObject = action.payload;
      state.profileData = action.payload;
    },
    errorOnGettingProfile: (state) => {
      state.isProfileLoading = false;
    },
    editdataLoading: (state) => {
      state.isEditDataLoading = true;
    },
    initializeEditData: (state, action) => {
      state.editData = action.payload;
      state.isEditDataLoading =false;
    },
    editdataError:(state)=>{
      state.isEditDataLoading = false;
    },
    profileAttributeComplete: (state, action) => {
      state.profileattributesComplete = action.payload;
    },
    profileBioComplete: (state, action) => {
      state.profilebiosComplete = action.payload;
    },
    setProfileAttributee: (state, action) => {
      state.profileAttributes = action.payload;
    },
    setProfileCheck: (state, action) =>{
      state.profileCheck = action.payload;
    },
    clearProfileData:(state) =>{
      state.profileData= [];
      state.profileObject =[]
      state.isProfileLoading= false;
      state.editData= [];
      state.profileattributesComplete= false;
      state.profilebiosComplete= false;
      state.profileAttributes= [];
      state.profileCheck=false;
      state.isEditDataLoading = false;
    } 
  },
});
export const {
  startProfileLoading,
  initializeProfile,
  errorOnGettingProfile,
  initializeEditData,
  profileAttributeComplete,
  profileBioComplete,
  setProfileAttributee,
  setProfileCheck,
  clearProfileData,
  initializeProfileObject,
  editdataLoading,
  editdataError
} = profileSlice.actions;
export default profileSlice.reducer;

export const getProfileData = (data) => async (dispatch) => {
  // console.log(data)
  // console.log('data')
  
  dispatch(startProfileLoading()); 
  
  try {
    await axiosAuth()
      .post(`${url}/users/get-self-profile`, data )
      .then(({ data }) => {
        if (data.status === 200) {

          // console.log("get-self-profile "+JSON.stringify(data?.data, null, 2));
          dispatch(initializeProfile(data?.data));
        }
        // console.log(data);
      }).catch(error=>{
        // console.log(error)
      });
  } catch (error) {
    dispatch(errorOnGettingProfile());
    return;
  }
};

export const getEditData = () => async ( dispatch) => {

  dispatch(editdataLoading());
  const language = await getLanguageFromLocalStorage() || "en"; 
  try {
    await axiosAuth()
      .get(`${url}/profiles/get-edit-data`,{
        params: { language }, 
      })
      .then((data) => {
        if (data?.status === 200) {
          dispatch(initializeEditData(data?.data));
        }
      })
      .catch((error) => {
        dispatch(editdataError())
      });
  } catch (error) {
    dispatch(editdataError())

    return;
  }
};

export const updatedProfileAttributes = (data) => async (dispatch) => {
  dispatch(profileAttributeComplete(data));
};

export const updatedBioAttributes = (data) => async (dispatch) => {
  dispatch(profileBioComplete(data));
};

export const setProfileAttributes = (data) => async (dispatch) => {
  dispatch(setProfileAttributee(data));
};

export const updateProfileCheck = (data) => async (dispatch) =>{
dispatch(setProfileCheck(data))
}


export const setClearProfile = () => (dispatch) =>{
  dispatch(clearProfileData())
}

export const initUserObject =(data) => (dispatch) => {
  dispatch(initializeProfileObject(data))
}