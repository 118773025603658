function GlobalAlerts(data) {


    return(
        <div className="global-alert slide-in-blurred-bottom" id="globalAlert">Messages</div>
    )
}
export default GlobalAlerts;

export function GlobalToolTip(data) {

   return(
        <div className="tooltip-alert slide-in-blurred-bottom" id="tooltipAlert"></div>
    )
}
