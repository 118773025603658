import imagePlacholder from "../../assets/images/Main/user-placeholder.jpg"
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { capitalizeWords } from "../../app/utilities/functions"


export default function UserDetails(data) {
 const { t } = useTranslation();
  const handleImageError = (e) => {
    // Replace the failed image with a placeholder image
    e.target.src = imagePlacholder; // Replace with the path to your placeholder image
   
  };


    var chat= data?.data
    // console.log(chat)


    return(
        <>
          <div className={`photo ${chat?.to_profile?.is_premium ? "premium" : " "}`}>
              {chat?.to_profile ? (
                <>
                  <img
                    id="currentChatUserImage"
                    src={chat?.to_profile?.photo}
                    alt=""
                    onError={handleImageError}
                  />
                </>
              ) : (
                <>
                  <img
                    id="currentChatUserImage"
                    src={imagePlacholder}
                    alt="Mohabbetein"
                    onError={handleImageError}
                  />
                </>
              )}
            </div>
            <div className="details">
              <div className="main">
                <div className="name" id="rightUserName">
                  {chat?.to_profile ? (
                    <>
                      {chat?.to_profile?.first_name && typeof chat?.to_profile?.first_name !== "undefined" && <span>{capitalizeWords(chat?.to_profile?.first_name)}</span>}
                      {" "}
                      {chat?.to_profile?.last_name && typeof chat?.to_profile?.last_name !== "undefined" && <span>{capitalizeWords(chat?.to_profile?.last_name)}</span>}
                    </>
                  ) : (
                    <></>
                  )}
                  <span className="age" id="rightUserAge">
                    {chat?.to_profile ? <> {" "}
                    {/* {chat?.to_profile?.age} */}
                    </> : <></>}
                  </span>
                </div>
                <div className="sub" id="rightUserHeight">
                  {chat?.to_profile ? <>{chat?.to_profile?.height}</> : <></>}
                </div>
                <div className="sub" id="rightUserCareer">
                  {chat?.to_profile ? <>{chat?.to_profile?.career}</> : <></>}
                </div>
              </div>
              <div className="user-attributes" id="user-attributes">
                <span className="attribute location">
                  {chat?.to_profile ? (
                    <>
                      <label>{chat?.to_profile?.location}</label>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <span className="attribute religion">
                  {chat?.to_profile ? (
                    <>
                      <label>{chat?.to_profile?.religion}</label>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <span className="attribute caste">
                  {chat?.to_profile ? (
                    <>
                      <label>{chat?.to_profile?.caste}</label>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <span className="attribute language">
                  {chat?.to_profile?.language ? (
                    
                    <>
                      {chat?.to_profile?.language?.length > 0 ? (
                    //    console.log('jhj'),
                        <>
                          {Object.entries(chat?.to_profile?.language).map(([key, value]) => (
                            <>
                            {/* <label key ={key}>{value+ " "}</label> */}
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <span className="attribute education">
                  {chat?.to_profile ? (
                    <>
                      <label>{chat?.to_profile?.education}</label>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div className="divider"></div>
              <div className="bio" id="rightUserBio">
                {chat?.to_profile ? <>{chat?.to_profile?.bio}</> : <></>}
              </div>
              {chat?.to_profile ? (
                    <>
                                  <Link to={"/user/"+chat?.to_profile?.slug} className="view-bt" id="rightUserLink">
                {t(['view-profile',''])}
              </Link>
                
                    </>
                  ) : (
                    <></>
                  )}

            </div>

        
        </>
    )

}