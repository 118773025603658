import React, { useState,useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import placeholderImage from "../../assets/images/Main/user-placeholder.jpg";
import axiosAuth from "../../app/auth/jwtMiddleware";
import { useSelector, useDispatch } from "react-redux";
import { showAlert } from "../../components/showAlerts";

const url = process.env.REACT_APP_PUBLIC_URL;

export default function RecentlyLiked({data ,onImageLoad}) {
  // console.log(data?.data);
  const slidercurr = useRef();
  const { profileData } = useSelector((state) => state.profile);
  const [isImageLoaded, setIsImageLoaded] = useState(false);




  const handleImageLoad = () => {
    // console.log('image')
    // Check if all images have loaded
    setIsImageLoaded(true);
    onImageLoad()
  };


  function requestMessage(e,userdid) {
    e.preventDefault();
    // console.log('send');
    // console.log(viewdata);

    var data = {
     to:userdid,
      from: profileData?.main?.id,
    };
    try {
      axiosAuth()
        .post(`${url}/chats/send-request`, data)
        .then((res) => {
          // console.log(res)
          if (res?.data?.data) {
            // console.log(res.data?.data?.id)
            // consol/
             window.location.href = "/messages?u=" + res.data?.data?.id;
          }
        })
        .catch((error) => {
          showAlert(error?.response?.data?.data)
          // console.log(error?.response?.data?.data);
        });
    } catch (error) {
      // console.log(error)
    }
  }
  const handleImageError = (e) => {
    // Replace the failed image with a placeholder image
    e.target.src = placeholderImage; // Replace with the path to your placeholder image
  };


  var slidersettings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots:false,
    // arrows: false,
    // nextArrow: <a href="#" className="arrow-next" id="recent-users-carousel-next"></a>,
    // nextArrow: <NextButton />,
    responsive: [
        {
        breakpoint: 1320,
            settings: {
                slidesToShow: 3,
            }
        },
        {
        breakpoint: 767,
            settings: {
                slidesToShow: 4,
            }
        },
        {
        breakpoint: 520,
            settings: {
                slidesToShow: 4,
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
    // afterChange:(next) => handleHeadeHeader(next)
  };

  
  const handleNext = (e) => {
    e.preventDefault();
    // slidercurr?.current.slickNext();
  };



  // console.log(data)
  if (data !== null) {
    
    return (
      <>
        <div className={`recent-users-carousel ${isImageLoaded ? "" : "hidden"}`}>
        {/* <Slider
            {...slidersettings}
            ref={(slider) => (slidercurr.current = slider)}
            className="recent-users-carousel"
        > */}
      
          {Object.entries(data)
          .slice(0, 4)
          .map(
            ([key, value]) => (
              // console.log(value),
              // console.log(value),
              (
       
                <div key={key} className="user">
                  <div
                    className={`user-photo ${
                      value.is_premium ? "premium" : ""
                    }`}
                  >
                    {value.name && (
                      <a href={`/user/${value.slug}`}
                      
                      onClick={(e)=>requestMessage(e, value?.user_id)}>
                        <img
                          src={value.photo}
                          alt={`User ${value.slug} ${key}`}
                          onLoad={handleImageLoad}
                          onError={handleImageError}
                        />
                      </a>
                    )}
                  </div>
                  {value.name && <span>{value.name}</span>}
                </div>
              )
            )
          )}
 
        </div>

                 {/* </Slider> */}

        {/* <a href="#" className="arrow-next" id="recent-users-carousel-next" onClick={(e)=>handleNext(e)}></a> */}
      </>
    );
  } else {
    return (
      <div className="likedUserCarousel" style={{ display: "none" }}></div>
    );
  }
}


