import React, { useState, useEffect,useRef } from "react";
import { useTranslation } from 'react-i18next';

const AutocompleteSelect = ({ options, onSelect ,placeholder,disabled=false}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false); 

  const inputRef = useRef(null);
  const ulRef = useRef(null);

  // console.log(options)

  // Update filtered options when the input value changes
  useEffect(() => {
   
    const filtered = options?.filter((option) => {
      // console.log(option)
      // console.log(inputValue);
      return option?.name.toLowerCase().includes(inputValue.toLowerCase())
      
    });

    // console.log(filtered)
    setFilteredOptions(filtered);
  }, [inputValue, options]);

  // Handle input change
  const handleInputChange = (e) => {
    e.preventDefault();
    // console.log('hetre')
    setInputValue(e.target.value);
    // setIsOpen(true);

  };

  // Handle option selection

  const handleOptionSelect = (e, option) => {
    e.preventDefault();

    // console.log(option)
    // console.log('selected')
    // setSelectedOption(option?.name);
   
    onSelect(option);
    setInputValue('');
    // setIsOpen(false);


    // if (inputRef.current && ulRef.current) {
    //   const selectRect = inputRef.current.getBoundingClientRect();
    //   const ulRect = ulRef.current.getBoundingClientRect();

    //   const position = {
    //     top: selectRect.bottom + window.scrollY,
    //     left: selectRect.left + window.scrollX,
    //   };

    //   console.log('UL Position:', position);
    // }
  };

  // Toggle the dropdown
  const toggleDropdown = (e) => {
     e.preventDefault()
    // console.log(isOpen)
    setTimeout(() => {
        setIsOpen(true)
    }, 300);

 
  };


  const closeHandler = (e) =>{
      e.preventDefault()
      setTimeout(() => {
        setIsOpen(false)
    }, 300);

  }

  return (
    <div className="autoComplete_wrapper filter-wrap full">
      {/* <input autoFocus /> */}
      <input
      ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        // onClick={handleInputChange}
        onFocus={toggleDropdown}
        onBlur={toggleDropdown}
        placeholder={placeholder}
        disabled={disabled}
      />
      {isOpen && (
        <ul 
        ref={ulRef}
        className="autocomplete-options" style={{position:'relative'}}>
            {filteredOptions?.length>0?
            
            <>
              <a href="#" className="close-button-autocomplete" onClick={closeHandler}>X</a>
                   {filteredOptions.map((option, index) => (
                    <li
                      className="autoComplete_list_1"
                      key={index}
                      onClick={(e) => handleOptionSelect(e, option)}
                      // onSelect={(e) => handleOptionSelect(e, option)}
                    >
                      {t([option?.slug,option?.name])}
                    </li>
                  ))}
                </>:<>
               {t(['no-results-for',''])} "{inputValue}"
                </>

            }

        </ul>
      )}
    </div>
  );
};

export default AutocompleteSelect;
